import React from 'react';
import { GiftIcon } from '@heroicons/react/outline';
import { LineItem } from '@Types/cart/LineItem';
import Price from 'components/commercetools-ui/price';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';
import { CustomImage } from 'lib/customImage';

interface Props {
  lineItem: LineItem;
  goToProductPage: (_url: string) => void;
  editItemQuantity: (lineItemId: string, newQuantity: number) => void;
  removeItem: (lineItemId: string) => void;
  isGift?: boolean;
}

const Item = ({ lineItem, goToProductPage, editItemQuantity, removeItem, isGift }: Props) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const amountLimit = 10;

  return (
    <div className="flex justify-between pb-10 md:flex-row">
      <div className="relative mr-7 h-[150px] min-w-[150px] rounded-[4px] border border-neutral-200">
        {/* <Image
          src={lineItem.variant.images[0]}
          alt={lineItem.name}
          className="max-h-[200px] rounded-[2px] border border-neutral-200 object-contain md:w-36"
          onClick={() => goToProductPage(lineItem._url)}
        /> */}
        <CustomImage imgSrc={lineItem.variant.images[0]} height={150} width={150} />
      </div>
      <div className="flex w-full flex-col justify-between">
        <div className="w-full items-start justify-between sm:flex">
          <div className="mb-4 w-full">
            <div className="flex w-full items-start justify-between gap-3">
              <h3
                className="mb-4 block cursor-pointer text-lg font-semibold text-black hover:text-primary sm:text-xl"
                onClick={() => goToProductPage(lineItem._url)}
              >
                {lineItem.name}
              </h3>
              <div className="flex items-end gap-2 md:hidden">
                <button
                  type="button"
                  className="rounded-[4px] border border-[#E7E7E7] p-2 text-sm font-medium text-[#808080] transition hover:text-white"
                  onClick={() => goToProductPage(lineItem._url)}
                >
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.3863 2.84381C12.6863 2.10006 11.9425 1.35631 11.1988 0.634439C11.0457 0.481314 10.8707 0.393814 10.6738 0.393814C10.4769 0.393814 10.28 0.459439 10.1488 0.612564L1.90191 8.79381C1.77066 8.92506 1.68316 9.07819 1.61753 9.23131L0.41441 12.9063C0.348785 13.0813 0.392535 13.2563 0.480035 13.3876C0.58941 13.5188 0.742535 13.6063 0.93941 13.6063H1.02691L4.76753 12.3594C4.94253 12.2938 5.09566 12.2063 5.20503 12.0751L13.4082 3.89381C13.5394 3.76256 13.6269 3.56569 13.6269 3.36881C13.6269 3.17194 13.5394 2.99694 13.3863 2.84381ZM4.50503 11.3969C4.48316 11.4188 4.46128 11.4188 4.43941 11.4407L1.61753 12.3813L2.55816 9.55944C2.55816 9.53756 2.58003 9.51569 2.60191 9.49381L8.61754 3.50006L10.5207 5.40319L4.50503 11.3969ZM11.1988 4.70319L9.29566 2.80006L10.63 1.46569C11.2644 2.07819 11.8988 2.73444 12.5113 3.36881L11.1988 4.70319Z"
                      fill="#808080"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  className="rounded-[4px] border border-[#E7E7E7] p-2 text-sm font-medium text-[#808080] transition hover:text-white"
                  onClick={() => removeItem(lineItem.lineItemId)}
                >
                  <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.69609 1.925H7.01172V1.55312C7.01172 0.896872 6.48672 0.371872 5.83047 0.371872H4.14609C3.48984 0.371872 2.96484 0.896872 2.96484 1.55312V1.925H1.28047C0.646094 1.925 0.121094 2.45 0.121094 3.08437V3.74062C0.121094 4.22187 0.405469 4.61562 0.821094 4.79062L1.17109 12.3594C1.21484 13.0812 1.78359 13.6281 2.50547 13.6281H7.42734C8.14922 13.6281 8.73984 13.0594 8.76172 12.3594L9.15547 4.76875C9.57109 4.59375 9.85547 4.17812 9.85547 3.71875V3.0625C9.85547 2.45 9.33047 1.925 8.69609 1.925ZM3.97109 1.55312C3.97109 1.44375 4.05859 1.35625 4.16797 1.35625H5.85234C5.96172 1.35625 6.04922 1.44375 6.04922 1.55312V1.925H3.99297V1.55312H3.97109ZM1.12734 3.08437C1.12734 2.99687 1.19297 2.90937 1.30234 2.90937H8.69609C8.78359 2.90937 8.87109 2.975 8.87109 3.08437V3.74062C8.87109 3.82812 8.80547 3.91562 8.69609 3.91562H1.30234C1.21484 3.91562 1.12734 3.85 1.12734 3.74062V3.08437ZM7.44922 12.6437H2.54922C2.35234 12.6437 2.19922 12.4906 2.19922 12.3156L1.84922 4.9H8.17109L7.82109 12.3156C7.79922 12.4906 7.64609 12.6437 7.44922 12.6437Z"
                      fill="#808080"
                    />
                  </svg>
                </button>
              </div>
            </div>

            {isGift && (
              <div className="mb-4 inline-flex text-secondary">
                <GiftIcon className="h-5 w-5" aria-hidden="true" />
              </div>
            )}

            <div className="mb-4 flex flex-col gap-2">
              {lineItem.variant.attributes?.color && (
                <p className="text-base font-medium text-[#808080]">
                  <span>{formatProductMessage({ id: 'color', defaultMessage: 'Color' })}: </span>
                  <span className="capitalize">{lineItem.variant.attributes.color.label}</span>
                </p>
              )}
              {lineItem.variant.attributes?.commonSize &&
                lineItem.variant.attributes.commonSize.key.toLowerCase() !== 'onesize' && (
                  <p className="text-base font-medium text-[#808080]">
                    <span>{formatProductMessage({ id: 'size', defaultMessage: 'Size' })}: </span>
                    <span className={typeof lineItem.variant.attributes.commonSize.key == 'string' && 'capitalize'}>
                      {/* {lineItem.variant.attributes.size} */}
                      {lineItem.variant.attributes.commonSize.key.toUpperCase()}
                    </span>
                  </p>
                )}
            </div>
          </div>
          <div className="hidden space-x-3 md:flex md:items-center">
            <button
              type="button"
              className="rounded-[4px] border border-[#E7E7E7] px-4 py-1 text-sm font-medium text-[#808080] transition hover:border-primary hover:bg-primary hover:text-white"
              onClick={() => goToProductPage(lineItem._url)}
            >
              {formatMessage({ id: 'edit', defaultMessage: 'Edit' })}
            </button>
            <button
              type="button"
              className="rounded-[4px] border border-[#E7E7E7] px-4 py-1 text-sm font-medium text-[#808080] transition hover:border-primary hover:bg-primary hover:text-white"
              onClick={() => removeItem(lineItem.lineItemId)}
            >
              {formatMessage({ id: 'remove', defaultMessage: 'Remove' })}
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-xl font-semibold text-black">
            {/* {lineItem.discountedPrice ? (
              <div className="flex items-center">
                <Price price={lineItem.discountedPrice} className="text-lg font-semibold text-gray-900" />
                <Price
                  price={lineItem?.price}
                  className="pl-6 text-sm font-medium text-[#808080] line-through decoration-[#808080] decoration-1"
                />
              </div>
            ) : (
              <Price price={lineItem?.price} className=" text-lg font-semibold text-gray-900" />
            )} */}
            {lineItem?.discounts.length > 0 ? (
              <div className="flex items-center">
                <Price
                  price={{
                    centAmount: lineItem?.price.centAmount - lineItem?.discounts[0].discountedAmount.centAmount,
                    currencyCode: lineItem?.price.currencyCode,
                    fractionDigits: lineItem?.price.fractionDigits,
                  }}
                  className="text-lg font-semibold text-gray-900"
                />
                <Price
                  price={lineItem?.price}
                  className="pl-6 text-sm font-medium text-[#808080] line-through decoration-[#808080] decoration-1"
                />
              </div>
            ) : (
              <Price price={lineItem?.price} className=" text-lg font-semibold text-gray-900" />
            )}
          </span>

          <div className="inline-flex items-center rounded border border-[#e7e7e7] text-base font-medium text-black">
            <button
              aria-label="Reduce"
              type="button"
              className={`cursor-pointer select-none py-1 px-2 ${
                lineItem.count <= 1 ? 'cursor-not-allowed' : 'cursor-pointer'
              } outline-none disabled:opacity-50`}
              disabled={lineItem.count <= 1}
              onClick={() => editItemQuantity(lineItem.lineItemId, lineItem.count - 1)}
            >
              -
            </button>
            <span className="py-1 px-2"> {lineItem.count}</span>
            <button
              type="button"
              aria-label="Increment"
              className={`cursor-pointer select-none py-1 px-2 ${
                !lineItem.variant.isOnStock || lineItem.count == amountLimit ? 'cursor-not-allowed' : 'cursor-pointer'
              } outline-none disabled:opacity-50`}
              disabled={!lineItem.variant.isOnStock || lineItem.count == amountLimit}
              onClick={() => editItemQuantity(lineItem.lineItemId, lineItem.count + 1)}
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
