import React from 'react';
import parse from 'html-react-parser';
import Image from 'frontastic/lib/image';
import { Media } from '../CZ11Cta/CZ11Cta';
import { strapiConfig } from 'helpers/strapiConfig';
import { ReferenceLink } from 'helpers/reference';
import useTransformImageSrc from 'helpers/hooks/useTransformImageSrc';

export type BannerProps = {
  title: string;
  description: any;
  isCentered?: boolean;
  image?: Media | undefined;
  buttonText?: string;
  buttonUrl?: string;
  targentBlank?: boolean;
  isFirstElement?: boolean;
};

export default function Banner({
  title,
  description,
  image,
  isCentered = false,
  buttonText,
  buttonUrl,
  targentBlank,
  isFirstElement = false,
}: BannerProps) {
  const bannerImage = useTransformImageSrc(`${strapiConfig.url}${image?.data?.attributes?.url}`);

  return (
    <section
      className={`relative rounded bg-[#102B33] bg-cover bg-no-repeat py-20 px-4 sm:px-10 lg:px-4 ${
        isFirstElement ? 'mb-10 mt-0' : 'my-10'
      }`}
      style={{
        backgroundImage: `linear-gradient(rgba(16, 43, 51, 0.2),rgba(16, 43, 51, 0.2)) , url(${bannerImage})`,
      }}
    >
      <div className="container mx-auto">
        <div
          className={`flex flex-col justify-center gap-2 sm:gap-4 ${
            isCentered ? 'items-center text-center' : 'items-start text-left'
          }`}
        >
          {title && <h1 className="text-heading-3 font-bold !leading-tight text-white sm:text-heading-1">{title}</h1>}

          {description && (
            <p className={`text-base font-medium text-white ${isCentered && 'max-w-[526px] text-center'}`}>
              {parse(description)}
            </p>
          )}
          {buttonUrl && buttonText && (
            <ReferenceLink
              target={{
                link: buttonUrl,
                type: 'link',
                openInNewWindow: targentBlank,
              }}
              className="mt-4 cursor-pointer rounded bg-primary py-2 px-4 text-white hover:bg-[#33B8A1] disabled:cursor-auto disabled:bg-[#EDEDED] disabled:text-[#808080]"
            >
              <span className="text-white">{buttonText}</span>
            </ReferenceLink>
          )}
        </div>
      </div>
    </section>
  );
}
