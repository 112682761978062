import React, { FC } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mobile, tablet } from 'helpers/utils/screensizes';
import { ProductProps, ProductListItem } from '.';

export const ProductList: FC<ProductProps> = ({ products, className = '', locale }) => {
  const filteredProducts = products.filter((product) => product.variants.length > 0);
  const { formatMessage } = useFormat({ name: 'product' });
  const [isTablet] = useMediaQuery(tablet);
  const [isMobile] = useMediaQuery(mobile);

  const paddingX = '3';
  const paddingY = '4';

  return (
    <>
      {filteredProducts.length > 0 ? (
        <table className={'w-full table-auto ' + className}>
          <thead>
            <tr className={'pb-' + paddingY}>
              <th colSpan={2} className={'pr-' + paddingX + ' pb-' + paddingY + ' pt text-left'}>
                {formatMessage({ id: 'product', defaultMessage: 'Product' })}
              </th>
              <th className={'px-' + paddingX + ' pb-' + paddingY + ' text-left'}>
                {formatMessage({ id: 'price', defaultMessage: 'Price' })}
              </th>
              {isTablet && (
                <>
                  <th className={'px-' + paddingX + ' pb-' + paddingY + ' text-left'}>
                    {formatMessage({ id: 'colors', defaultMessage: 'Colors' })}
                  </th>
                  <th className={'px-' + paddingX + ' pb-' + paddingY + ' text-left'}>
                    {formatMessage({ id: 'sizes', defaultMessage: 'Sizes' })}
                  </th>
                  <th className={'px-' + paddingX + ' pb-' + paddingY + ' text-left'}>
                    {formatMessage({ id: 'amount', defaultMessage: 'Amount' })}
                  </th>
                  <th></th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product) => (
              <ProductListItem
                key={product.id}
                locale={locale}
                {...product}
                paddingX={paddingX}
                paddingY={paddingY}
                isTablet={isTablet}
                isMobile={isMobile}
              />
            ))}
          </tbody>
        </table>
      ) : null}
    </>
  );
};
