import { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Product } from '@Types/product/Product';
import { Review } from '@Types/product/Review';
import { Rating } from 'components/common/Rating';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import { addReview } from 'frontastic/actions/products';
import { Collapse } from '../product-filter';

export type ReviewSectionProps = {
  reviews: Review[];
  productId: string;
  reviewMessage?: Record<string, string>;
  hasReviewed: boolean;
  hasBought: boolean;
  moderateReviews: boolean;
  reviewStats?: Product['reviewStats'];
};

export function ReviewSection({
  reviews,
  productId,
  hasReviewed,
  reviewMessage,
  moderateReviews,
  hasBought,
  reviewStats,
}: ReviewSectionProps) {
  const [_reviews, setReviews] = useState<Review[]>(reviews);
  const [reviewText, setReviewText] = useState('');
  const [selectedRating, setSelectedRating] = useState<number | null>(null);
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { loggedIn } = useAccount();
  const router = useRouter();
  const language = router.locale?.split?.('-')?.[0] ?? 'en';
  const [showReviews, setShowReviews] = useState(false);

  const addReviewHandler = async () => {
    const review = {
      productId: productId,
      rating: selectedRating,
      text: reviewText,
    };

    setHasSubmitted(true);
    const response = await addReview(review);

    if (!moderateReviews) {
      setReviews([review, ...reviews]);
    }

    console.log(response);
  };

  console.log(reviewStats);
  return (
    <div>
      <div className="flex flex-col items-center justify-center bg-[#f6f9ff] p-4 gap-3">
        {reviewStats?.average && (
          <span className="flex items-center gap-5">
            <Rating value={reviewStats.average} />
            {Math.floor(reviewStats.average * 2) / 2} {formatProductMessage({ id: 'outOfFive' })}
            {/* Translation for "out of 5" */}
          </span>
        )}
        <span className="text-gray-600">
          {reviewStats?.count
            ? `${reviewStats.count} ${formatProductMessage({ id: 'customerRatings' })}`
            : formatProductMessage({ id: 'noCustomerRatings' })}
          {/* Translation for customer ratings */}
        </span>

        <button className="text-primary underline underline-offset-2" onClick={() => setShowReviews(!showReviews)}>
          {formatProductMessage({ id: 'showReviews' })} {/* Translation for "Show Reviews" */}
        </button>
        {/* map actual reviews */}
        {showReviews && (
          <div className="flex flex-col self-stretch">
            {_reviews?.map((review, i) => (
              <div className="" key={i}>
                <Rating value={review.rating} />
                <p>{review.text}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* input for new review */}
      <div className="mb-4 mt-4 bg-[#f6f9ff] p-4">
        {!hasSubmitted && loggedIn && hasBought && !hasReviewed ? (
          <>
            <div className="pb-4">
              <label>{reviewMessage[language]}</label>
              <Rating value={selectedRating} setValue={setSelectedRating} />
            </div>
            <textarea
              placeholder={formatProductMessage({
                id: 'reviewInputPlaceholder',
              })}
              value={reviewText}
              onChange={(e) => setReviewText(e.target.value)}
              className="w-full rounded border border-[#e7e7e7] bg-transparent px-4 py-2"
            />
            <button
              className="flex w-full items-center justify-center rounded bg-primary py-3 px-10 text-center text-base font-bold text-white hover:bg-primary/90 disabled:bg-gray-400 disabled:text-gray-50 mt-4"
              onClick={addReviewHandler}
              disabled={hasSubmitted}
            >
              {formatProductMessage({ id: 'submitReview' })}
            </button>
          </>
        ) : hasSubmitted ? (
          <>
            <p>
              {formatProductMessage({ id: 'thankYouForSubmittingReview' })}
              {moderateReviews && ' ' + formatProductMessage({ id: 'reviewPendingApproval' })}
            </p>
          </>
        ) : !loggedIn ? (
          <>
            <Link href={!router.asPath.includes('current') ? `/login?current${router.asPath}` : router.asPath}>
              <span className="font-medium text-primary underline hover:text-[#33B8A1]">
                {formatProductMessage({ id: 'loginToLeaveReview' })}
              </span>
            </Link>
          </>
        ) : hasReviewed ? (
          <>
            <p>{formatProductMessage({ id: 'reviewAlreadySubmitted' })}</p>
          </>
        ) : !hasBought ? (
          <>
            <p>{formatProductMessage({ id: 'reviewsForPurchasersOnly' })}</p>
          </>
        ) : null}
      </div>
    </div>
  );
}
