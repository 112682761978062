import { Cz15ArticleFragment } from 'generated/strapiTypes';
import CZ15Article from './CZ15Article';
import { tablet } from 'helpers/utils/screensizes';
import { useWindowSize } from 'usehooks-ts';
import { useAccount } from 'frontastic';

export const CZ15ArticleAdapter = (props: Cz15ArticleFragment) => {
  const { width } = useWindowSize();
  const { account } = useAccount();

  if (props?.customerGroups?.length > 0 && !props?.customerGroups?.includes(account?.customerGroup?.id)) return null;

  return (
    <CZ15Article
      title={props.title ?? ''}
      subtitle={props.subtitle ?? ''}
      text={props.text ?? ''}
      imageSrc={
        width > tablet
          ? props?.image?.data?.attributes?.url ?? ''
          : props?.imageMobile?.data?.attributes?.url ?? props?.image?.data?.attributes?.url ?? ''
      }
    />
  );
};
