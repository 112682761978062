import React, { useState, ReactNode } from 'react';
import { LineItem } from '@Types/wishlist/LineItem';
import { useTranslation } from 'react-i18next';
import { GridIcon } from 'components/icons/grid-icon';
import { ListIcon } from 'components/icons/list-icon';
import { useFormat } from 'helpers/hooks/useFormat';
import { FullPageWidthWrapper } from '../full-bleed';
import WishlistCard from './wishlistCard';
import WishlistRow, { WishlistMobileItem } from './wishlistRow';

export interface Props {
  items?: LineItem[];
  removeLineItems: (lineItem: LineItem) => void;
}

const List: React.FC<Props> = ({ items, removeLineItems }) => {
  const [viewMode, setViewMode] = useState<'cards' | 'table'>('table');
  const { t } = useTranslation();
  const { formatMessage: formatWishlistMessage } = useFormat({ name: 'wishlist' });

  return (
    <FullPageWidthWrapper>
      <section className="bg-white pt-20 pb-12">
        <div className="container mx-auto max-w-[1280px] px-4 sm:px-10 lg:px-4">
          <div className="flex-between flex items-start">
            <div className="w-full">
              <div className="mb-10 text-left">
                <h3 className="mb-4 text-heading-3 font-bold text-[#242424]">
                  {formatWishlistMessage({ id: 'myWishlist', defaultMessage: 'My Wishlist' })}
                </h3>
                <p className="text-base font-medium text-[#808080]">
                  {t('wishlist:wishlist.itemsNumber', { itemsNumber: items.length })}
                </p>
              </div>
            </div>
            {/* <div className="flex gap-4">
              <button type="button" onClick={() => setViewMode('cards')}>
                <GridIcon />
              </button>
              <button type="button" onClick={() => setViewMode('table')}>
                <ListIcon />
              </button>
            </div> */}
          </div>
          {/* {viewMode === 'cards' ? (
            <div className="flex flex-wrap justify-center gap-12 md:justify-between md:gap-6">
              {items.map((item) => (
                <WishlistCard key={item.lineItemId} item={item} removeLineItems={removeLineItems} />
              ))}
            </div>
          ) : ( */}
          <div className="flex w-full flex-wrap justify-start gap-12 md:gap-6">
            <WishlistTable>
              {items.map((item) => (
                <WishlistRow key={item.lineItemId} item={item} removeLineItems={removeLineItems} />
              ))}
            </WishlistTable>
            <div className="w-full md:hidden">
              {items.map((item) => (
                <WishlistMobileItem item={item} removeLineItems={removeLineItems} key={item.lineItemId} />
              ))}
            </div>
          </div>
          {/* )} */}
        </div>
      </section>
    </FullPageWidthWrapper>
  );
};

export default List;

const WishlistTable = ({ children }: { children: ReactNode }) => {
  const { formatMessage: formatWishlistMessage } = useFormat({ name: 'wishlist' });

  return (
    <div className="hidden w-full md:block">
      <div className="no-scrollbar max-w-full overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="text-left">
              <th className={`min-w-[300px] py-4 text-sm font-medium text-[#242424] last:xl:pr-11`}>
                {formatWishlistMessage({ id: 'wishlist.product', defaultMessage: 'Product' })}
              </th>
              <th className={`min-w-[180px] py-4 px-4 text-sm font-medium text-[#242424] first:xl:pl-11 last:xl:pr-11`}>
                {formatWishlistMessage({ id: 'wishlist.unitPrice', defaultMessage: 'Unit Price' })}
              </th>
              <th className={`min-w-[140px] py-4 px-4 text-sm font-medium text-[#242424] first:xl:pl-11 last:xl:pr-11`}>
                {formatWishlistMessage({ id: 'wishlist.stockStatus', defaultMessage: 'Stock Status' })}
              </th>
              <th className={`min-w-[150px] py-4 px-4 text-sm font-medium text-[#242424] first:xl:pl-11 last:xl:pr-11`}>
                {formatWishlistMessage({ id: 'wishlist.action', defaultMessage: 'Action' })}
              </th>
              <th
                className={`min-w-[140px] py-4 px-4 text-sm font-medium text-[#242424] first:xl:pl-11 last:xl:pr-11`}
              ></th>
            </tr>
          </thead>

          <tbody>{children}</tbody>
        </table>
      </div>
    </div>
  );
};
