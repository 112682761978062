import { S4ProductListFragment } from 'generated/strapiTypes';
import React from 'react';
import ProductList from './productList';
import { useAccount } from 'frontastic';

export const ProductListAdapter = (props: S4ProductListFragment) => {
  const { account } = useAccount();
  const products: string[] = [];

  props?.Products?.forEach((item) => products.push(item?.product));

  if (props?.customerGroups?.length > 0 && !props?.customerGroups?.includes(account?.customerGroup?.id)) return null;

  return <ProductList category={props?.category ?? ''} products={products} />;
};
