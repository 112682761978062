import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type IAnonymous = {
  anonymousId: string | null;
  setAnonymousId: (newAnonymousId: string) => void;
};

export const useAnonymousStore = create(
  persist<IAnonymous>(
    (set) => ({
      anonymousId: null,
      setAnonymousId: (newAnonymousId: string) => set({ anonymousId: newAnonymousId }),
    }),
    {
      name: 'AnonymousStorage',
    },
  ),
);
