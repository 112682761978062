import React from 'react';
import parse from 'html-react-parser';
import { useRouter } from 'next/router';
import { ReferenceLink } from 'helpers/reference';
import Image from 'frontastic/lib/image';
import { strapiConfig } from 'helpers/strapiConfig';

export type CZ11CtaProps = {
  ctaItems: CtaItem[];
};

export type Media = {
  data: {
    attributes: {
      url: string;
      width?: number;
      height?: number;
    };
  };
};

export type CtaItem = {
  icon: Media;
  title: string;
  subtitle?: string;
  text?: string;
  buttonText?: string;
  buttonUrl?: string;
  iconAlt?: string;
};

const CZ11Cta = ({ ctaItems }: CZ11CtaProps) => {
  return (
    <section className="w-full bg-[#F6F9FF]">
      <div className="container mx-auto max-w-[1280px] py-20 px-0 font-urbanist md:px-10 lg:px-4">
        <div className="flex w-full basis-0 flex-col justify-start gap-12 bg-[#F6F9FF] md:flex-row">
          {ctaItems.map((item, idx) => (
            <CheckoutCta
              key={idx}
              title={item.title}
              subtitle={item.subtitle}
              text={item.text}
              buttonText={item.buttonText}
              buttonUrl={item.buttonUrl}
              iconSrc={item.icon.data.attributes.url}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CZ11Cta;

const CheckoutCta = ({
  title,
  subtitle,
  text,
  buttonText,
  buttonUrl,
  iconSrc,
}: {
  title: string;
  subtitle: string;
  text: string;
  buttonText: string;
  buttonUrl: string;
  iconSrc?: string;
}) => {
  const router = useRouter();
  return (
    <div className="relative flex w-full justify-start gap-2 rounded bg-white py-6 px-8">
      {iconSrc && (
        <div className="absolute top-[-34px] left-1/2 mb-6 h-[68px] -translate-x-1/2 transform">
          <Image
            src={`${strapiConfig.url}${iconSrc}`}
            layout="fill"
            className="h-full w-full object-cover"
            alt="Article Image"
          />
        </div>
      )}
      <div className="mt-6 flex w-full flex-col items-start justify-between">
        <div className="flex flex-col items-start gap-4">
          <div className="flex flex-col items-start gap-2">
            <h2 className="text-lg font-bold text-primary">{subtitle}</h2>
            <h1 className="text-4xl font-semibold text-[#242424]">{title}</h1>
          </div>
          <p className="text-lg font-medium text-[#808080]">{text}</p>
        </div>

        {buttonUrl !== '' && buttonText !== '' && (
          <ReferenceLink
            target={{
              link: buttonUrl,
              target: buttonUrl,
              type: 'link',
              openInNewWindow: false,
            }}
            className="disabled: my-4 cursor-pointer rounded bg-primary py-2 px-4 text-white hover:bg-[#33B8A1] disabled:cursor-auto disabled:bg-[#EDEDED] disabled:text-[#808080]"
          >
            <span className="text-white">{buttonText}</span>
          </ReferenceLink>
        )}
      </div>
    </div>
  );
};
