import React from 'react';

export const useSecretFeature = (phrase: string) => {
  const [keysPressed, setKeysPressed] = React.useState<string>('');

  const downHandler = ({ key }: KeyboardEvent) => {
    setKeysPressed((keysPressed) => {
      if (keysPressed.endsWith(phrase)) {
        return keysPressed;
      }
      return `${keysPressed}${key}`.slice(-phrase.length);
    });
  };

  React.useEffect(() => {
    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, []);

  return keysPressed.endsWith(phrase);
};
