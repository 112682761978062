import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Product } from '@Types/product/Product';
import { Facet } from '@Types/result/Facet';
import Breadcrumb from 'components/commercetools-ui/breadcrumb';
import Filters from 'components/commercetools-ui/filters';
import FilterIcon from 'components/icons/filter';
import CloseIcon from 'components/icons/icon-x';
import { useFormat } from 'helpers/hooks/useFormat';
import { updateURLParams } from 'helpers/utils/updateURLParams';
import { useAccount } from 'frontastic';
import { productCategories } from 'frontastic/actions/productCategories';
import List from './list';

export interface Props {
  products: Product[];
  previousCursor: string;
  nextCursor: string;
  category: string;
  facets: Facet[];
  totalProducts: number;
  showNavigation?: boolean;
}

export const CustomerGroupMapper: Record<string, string> = {
  '01f93f0d-8c7f-4ba4-8fac-d0572dfab531': 'diamond',
  'ed801dda-7623-4f0a-8ecc-aaebd36aa199': 'b2b',
  '53e927e1-cece-42ac-a43a-ad854c250349': 'gold',
  '6d10c6e0-2b4e-4c46-bd33-c5655ef10121': 'platinum',
  '09fe4826-29ac-4530-8ee6-f6386b674499': 'silver',
};

export default function ProductList({
  products,
  totalProducts,
  previousCursor,
  nextCursor,
  category,
  facets,
  showNavigation,
}: Props) {
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [previousPageURL, setPreviousPageURL] = useState<string>('/');
  const [nextPageURL, setNextPageURL] = useState<string>('/');
  const [allCategories, setAllCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const router = useRouter();
  const { loggedIn, account } = useAccount();

  //i18n messages
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const activeButtonClassName =
    'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50';

  const disabledButtonClassName = 'pointer-events-none rounded bg-gray-500 py-2 px-4 font-bold text-white opacity-50';

  const toggleFiltering = () => {
    setIsFiltering(!isFiltering);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const res = await productCategories();
      setAllCategories(res.items);
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const categories = allCategories.map((cat, idx) => ({
      name: cat.name,
      slug: cat.slug,
      depth: cat.depth,
      loggedInOnly: cat.loggedInOnly,
      customerGroupTarget: cat.customerGroupTarget,
    }));
    setCategories(categories);
  }, [allCategories]);

  useEffect(() => {
    const allParams = Object.keys(router.query).map((objKey) => ({
      key: objKey,
      value: router.query[objKey] as string,
    }));
    if (previousCursor) {
      setPreviousPageURL(
        updateURLParams([...allParams.filter((item) => item.key !== 'slug'), { key: 'cursor', value: previousCursor }]),
      );
    }

    if (nextCursor) {
      setNextPageURL(
        updateURLParams([...allParams.filter((item) => item.key !== 'slug'), { key: 'cursor', value: nextCursor }]),
      );
    }
  }, [previousCursor, nextCursor, router.asPath]);

  const selectedCategory = useMemo<string>(
    () => router.asPath.split('?')[0].substring(1).split('-')[0],
    [router.asPath],
  );

  const relevantCategories =
    loggedIn && account?.customerGroup
      ? categories.filter(
          (c) =>
            c.customerGroupTarget === CustomerGroupMapper[account?.customerGroup.id] ||
            c.customerGroupTarget === undefined,
        )
      : categories.filter((c) => c.customerGroupTarget === undefined);

  const navItems = useMemo(() => {
    const items = [];
    relevantCategories
      .filter((element) => (!loggedIn ? !element.loggedInOnly : element))
      .map((category, idx) => {
        if (category?.slug?.startsWith(selectedCategory)) {
          items.push({
            name: category.name,
            slug: category.slug,
            depth: category.depth,
            loggedInOnly: category.loggedInOnly,
            customerGroupTarget: category.customerGroupTarget,
          });
        }
      });
    return items;
  }, [selectedCategory, relevantCategories, loggedIn]);

  useEffect(() => {
    if (
      (!loggedIn &&
        relevantCategories.filter((element) => element.slug === router.asPath.split('/')[1] && element.loggedInOnly)
          .length > 0) ||
      (!loggedIn &&
        allCategories.filter(
          (element) => element.slug === router.asPath.split('/')[1] && element.customerGroupTarget !== undefined,
        ).length > 0)
    ) {
      router.push('/');
    }
  }, [loggedIn, router.asPath]);

  const routerInfo = useMemo(() => {
    return router.asPath.split('?')[0].substring(1);
  }, [router.asPath]);

  const showCharacters = useCallback((str) => {
    const mapChar = {
      U2D: '-',
      U20: ' ',
      U26: ' & ',
    };
    return str.replace(/(?:U2D|U20|U26)/gi, (matched) => mapChar[matched]);
  }, []);

  return (
    <div className="w-full px-1 sm:px-3 lg:px-6" style={{ position: 'relative' }}>
      {!routerInfo.includes('search') && (
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            gap: '1rem',
            backgroundColor: 'black',
            flexWrap: 'wrap',
            padding: '1rem 0.4rem',
            color: '#e9e9e9',
          }}
        >
          {navItems
            .filter((nav) => nav.depth === 1)
            .map((item, idx) => (
              <NextLink href={item.slug} key={item.slug}>
                {item.name}
              </NextLink>
            ))}
          {routerInfo.split('-').length >= 2 && (
            <div
              className="gap-6 text-sm md:gap-4 md:text-xs"
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                // gap: '1rem',
                backgroundColor: 'black',
                // fontSize: '11px',
                flexWrap: 'wrap',
                padding: '1rem 1rem',
                color: '#c8df52',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {navItems
                .filter((nav) => nav.depth === 2 && nav.slug.startsWith(routerInfo.split('-').slice(0, 2).join('-')))
                .map((item, idx) => (
                  <NextLink href={item.slug} key={item.slug}>
                    {item.name}
                  </NextLink>
                ))}
            </div>
          )}
        </div>
      )}

      <div style={{ marginTop: '4rem' }}>
        <Breadcrumb Separator={'>'}>
          {router.asPath
            .substring(1)
            .split('?')[0]
            .split('-')
            .map((item, idx) => {
              return (
                <NextLink
                  href={router.asPath
                    .substring(1)
                    .split('?')[0]
                    .split('-')
                    .slice(0, idx + 1)
                    .join('-')}
                  key={`${item}-${idx}`}
                >
                  {showCharacters(item)}
                </NextLink>
              );
            })}
        </Breadcrumb>
      </div>

      <div className="mt-8 gap-16 lg:grid lg:grid-cols-3">
        {isFiltering ? (
          <button onClick={toggleFiltering} className="w-full py-2">
            <div className="flex justify-between">
              <h6 className="text-base font-bold text-neutral-700 dark:text-light-100">
                {formatProductMessage({ id: 'sortAndFilter', defaultMessage: 'Sort & Filter' })}
              </h6>
              <CloseIcon className="h-6 w-5 fill-neutral-700 dark:fill-light-100" />
            </div>
          </button>
        ) : (
          <button onClick={toggleFiltering} className="flex w-full justify-between py-2">
            <div className="flex gap-1">
              <FilterIcon className="h-6 w-5 fill-neutral-700 dark:fill-light-100" />
              <h6 className="text-base font-bold text-neutral-700 dark:text-light-100">
                {formatProductMessage({ id: 'sortAndFilter', defaultMessage: 'Sort & Filter' })}
              </h6>
            </div>
            <h6 className="col-span-2 block text-right dark:text-light-100 lg:hidden">
              {`${products?.length} ${formatProductMessage({ id: 'items', defaultMessage: 'Items' })} ${totalProducts}`}
            </h6>
          </button>
        )}
        <h6 className="col-span-2 hidden text-right dark:text-light-100 lg:block">
          {`
          ${formatMessage({
            id: 'page',
            defaultMessage: 'Page',
          })}
          ${router.query.cursor ? Math.floor(parseInt((router.query.cursor as string).split(':')[1], 10) / 24 + 1) : 1}
          ${formatMessage({
            id: 'page.of',
            defaultMessage: 'of',
          })}
          ${Math.ceil(totalProducts / 24)}`}
        </h6>
      </div>

      {isFiltering ? (
        <div className="mt-2 grid gap-16 lg:grid-cols-3">
          <div className="">
            <Filters facets={facets} products={products} />
          </div>
          <div className="lg:col-span-2">
            {products.length > 0 ? (
              <List products={products} filtering={isFiltering} />
            ) : (
              <p>{formatProductMessage({ id: 'noProductsFound', defaultMessage: 'No products found.' })}</p>
            )}
          </div>
        </div>
      ) : (
        <List products={products} />
      )}

      <nav
        className="flex items-center justify-between border-t border-gray-200 py-3 px-4 sm:px-6"
        aria-label="Pagination"
      >
        <div className="flex flex-1 justify-between gap-x-1.5 sm:justify-end">
          <NextLink href={previousPageURL}>
            <a className={previousCursor ? activeButtonClassName : disabledButtonClassName}>
              {formatMessage({ id: 'prev', defaultMessage: 'Previous' })}
            </a>
          </NextLink>
          <NextLink href={nextPageURL}>
            <a className={nextCursor ? activeButtonClassName : disabledButtonClassName}>
              {formatMessage({ id: 'next', defaultMessage: 'Next' })}
            </a>
          </NextLink>
        </div>
      </nav>
    </div>
  );
}
