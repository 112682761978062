import React from 'react';
import { UpsellProducts } from 'features/typesense/components';
import { useCart } from 'frontastic';

const UpsellProductsTastic = ({ data }) => {
  const { data: cartList } = useCart();

  if (cartList?.lineItems && cartList.lineItems.length > 0) {
    return (
      <UpsellProducts
        title={data?.title}
        product_results={[
          {
            type: 'relation',
            related_products: cartList.lineItems.map((lineItem) => ({
              sku: lineItem.variant.sku,
              productId: lineItem.productId,
              gender: lineItem.variant.attributes?.['gender']?.key,
            })),
          },
        ]}
      />
    );
  }

  return null;
};

export default UpsellProductsTastic;
