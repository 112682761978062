import { Cz12TextBlockFragment } from 'generated/strapiTypes';
import CZ12TextBlock from './CZ12TextBlock';
import { useAccount } from 'frontastic';

export const CZ12TextBlockAdapter = (props: Cz12TextBlockFragment) => {
  const { account } = useAccount();

  if (props?.customerGroups?.length > 0 && !props?.customerGroups?.includes(account?.customerGroup?.id)) return null;
  return <CZ12TextBlock title={props.title ?? ''} subtitle={props.subtitle ?? ''} text={props.text ?? ''} />;
};
