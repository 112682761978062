import React, { FC, useCallback, useEffect, useState } from 'react';
import NextLink from 'next/link';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Order } from '@Types/cart/Order';
import Accordion from 'components/commercetools-ui/accordion';
import Price from 'components/commercetools-ui/price';
import Spinner from 'components/commercetools-ui/spinner';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet, mobile } from 'helpers/utils/screensizes';
import { useAccount, useCart } from 'frontastic';
// import { EasyInvoiceSample } from '../invoice/EasyInvoiceSample';
import { DeliveryNote } from '../invoice/DeliveryNote';
import InvoiceDocument from '../invoice/InvoiceDocument';
import { OrderConfirmation } from '../invoice/OrderConfirmation';
import { CustomImage } from 'lib/customImage';
import { useRouter } from 'next/router';
import { GiftIcon } from '@heroicons/react/outline';
import { Money } from '@Types/product/Money';

export interface Props {
  orders?: Order[];
}

const lineItemsStatesMapper: Record<string, string> = {
  '897e63b2-fff0-4ed9-9141-6898beeffd70': 'in.processing',
  '4045df7d-178a-417b-ad20-2dd66a5abb17': 'Picking',
  'd76528df-9e8c-4ee5-8eb3-cc9a2812cd42': 'Ready to Ship',
  'a90542c0-ec43-490b-8025-c222570292f6': 'In Replenishment',
  '20aeb396-8145-49c9-935d-deec5fa430e8': 'Lost',
  '96ad09ed-94f1-47ad-9d82-a96b3b5f78fc': 'Closed',
  'c0025a42-1897-4dad-b6ea-3c5124846b4a': 'Return Approved',
  '50e581d8-a73f-4b23-ad50-dff10cf5838d': 'Canceled',
  '0e1f33d8-ded2-40fe-ae32-118fcf6d0612': 'Returned',
  '58227914-90d6-417f-9513-3c87692fab05': 'Shipped',
  '9b3b7d0b-0c5d-4ac1-8b4e-2b3d8f9f56d2': 'Return Not Approved',
  'e18ead48-8d41-4ced-97e2-86f1291f4eb0': 'Loss Not Approved',
};

const OrdersHistory: FC<Props> = ({ orders }) => {
  const [accountOrdersState, setAccountOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isTabletSize] = useMediaQuery(tablet);
  const [isMobileSize] = useMediaQuery(mobile);
  const [cartDiscount, setCartDiscount] = useState<any>();
  //account data
  const { orderHistory, getCartDiscount } = useCart();
  const { account } = useAccount();

  useEffect(() => {
    if (orderHistory) {
      orderHistory().then((data) => {
        setAccountOrders(data);
        setLoading(false);
      });
    } else {
      setAccountOrders(orders);
      setLoading(false);
    }
  }, [orders, orderHistory]);
  //18in messages
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { formatMessage: formatOrderMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });

  const router = useRouter();

  const orderStateTranslation: Record<string, string> = {
    Open: formatCommonMessage({
      id: 'order.state.open',
      defaultMessage: 'Open',
    }),
    Confirmed: formatCommonMessage({
      id: 'order.state.confirmed',
      defaultMessage: 'Confirmed',
    }),
    Complete: formatCommonMessage({
      id: 'order.state.complete',
      defaultMessage: 'Complete',
    }),
    Cancelled: formatCommonMessage({
      id: 'order.state.cancelled',
      defaultMessage: 'Cancelled',
    }),
  };

  const formatPaymentMethod = (paymentMethod?: string) => {
    return paymentMethod?.toLowerCase() === 'paypal'
      ? 'Paypal'
      : paymentMethod?.toLowerCase() === 'sofort'
      ? 'Sofort'
      : formatCheckoutMessage({
          id: 'payment.method.card',
          defaultMessage: 'Credit/Debit Card',
        });
  };
  return (
    <div>
      <div className="mt-10">
        <div className="space-y-1">
          <h3 className="my-6 text-lg font-bold text-[#242424]">
            {formatAccountMessage({ id: 'my.orders.history', defaultMessage: 'My order history' })}
          </h3>
          <p className="max-w-2xl text-base font-medium text-gray-500">
            {formatAccountMessage({
              id: 'orders.desc',
              defaultMessage: 'Check the status of recent orders, manage returns, and download invoices.',
            })}
          </p>
        </div>
        {loading ? (
          <div className="flex items-stretch justify-center py-10 px-12">
            <Spinner color="#33B8A1" />
          </div>
        ) : accountOrdersState && accountOrdersState.length ? (
          <section aria-labelledby="recent-heading" className="mt-16">
            <h2 id="recent-heading" className="sr-only">
              {formatCommonMessage({
                id: 'recent.orders',
                defaultMessage: 'Recent orders',
              })}
            </h2>
            <ul className="space-y-4 sm:space-y-16">
              {accountOrdersState?.map((order) => {
                const createdAt = new Date(order?.createdAt);

                return (
                  <li className="border-t border-gray-200 sm:border" key={order.orderId}>
                    <div className="flex flex-col sm:bg-gray-50">
                      <div className="inline sm:px-4 sm:py-6">
                        <dt className="mr-2 inline text-sm font-medium text-gray-900">
                          {formatAccountMessage({
                            id: 'orders.number',
                            defaultMessage: 'Order number',
                          })}
                          :
                        </dt>
                        <dd className="inline text-sm text-gray-700 sm:mt-1">{order.orderNumber}</dd>
                        <time
                          className="hidden text-sm text-gray-500 sm:mt-1 sm:block"
                          dateTime={order.createdAt.toString()}
                        >
                          {formatCheckoutMessage({
                            id: 'timePlaced',
                            defaultMessage: 'Time placed',
                          })}
                          : {createdAt.toLocaleDateString(router.locale, { hour: '2-digit', minute: '2-digit' })}
                        </time>
                      </div>
                      <dl className="flex flex-col gap-4 px-4 sm:grid sm:grid-cols-4  sm:border-b sm:bg-gray-50 sm:py-6 lg:space-x-8">
                        <div className="text-sm sm:pt-0">
                          <div className="inline sm:hidden">
                            <dt className="mr-2 inline font-medium text-gray-900">
                              {formatAccountMessage({
                                id: 'date',
                                defaultMessage: 'Date',
                              })}
                              :
                            </dt>
                            <time className="mr-6 inline text-sm text-gray-700" dateTime={order.createdAt.toString()}>
                              {createdAt.toLocaleDateString(router.locale, { hour: '2-digit', minute: '2-digit' })}
                            </time>
                          </div>
                          <dt className="mr-2 inline font-medium text-gray-900 sm:block">
                            <span>
                              {formatAccountMessage({
                                id: 'orders.total.amount',
                                defaultMessage: 'Total',
                              })}
                            </span>
                            <span className="sm:hidden">:</span>
                          </dt>

                          <dd className="flex flex-col gap-1 sm:mt-1 ">
                            <Price
                              price={order.sum}
                              currency={order.sum.currencyCode}
                              className="inline text-gray-700"
                            />
                          </dd>
                        </div>

                        <div className="text-sm sm:pt-0">
                          <dt className="fmr-2 mr-2 inline font-medium text-gray-900 sm:block">
                            <span>
                              {formatAccountMessage({
                                id: 'orders.status',
                                defaultMessage: 'Status',
                              })}
                            </span>
                            <span className="sm:hidden">:</span>
                          </dt>
                          <dd className="inline text-gray-700 sm:mt-1 sm:block ">
                            {orderStateTranslation[order.orderState]}
                          </dd>
                        </div>
                        <div className="text-sm sm:pt-0">
                          <dt className="fmr-2 mr-2 inline font-medium text-gray-900 sm:block">
                            <span>
                              {formatCheckoutMessage({ id: 'shippingMethod', defaultMessage: 'Shipping Method' })}
                            </span>
                            <span className="sm:hidden">:</span>
                          </dt>
                          <dd className="inline text-gray-700 sm:mt-1 sm:block ">
                            {order.shippingInfo.shippingMethodName}
                          </dd>
                        </div>
                        <div className="text-sm sm:pt-0">
                          <dt className="fmr-2 mr-2 inline font-medium text-gray-900 sm:block">
                            <span>
                              {formatCheckoutMessage({ id: 'paymentMethod', defaultMessage: 'Payment Method' })}
                            </span>
                            <span className="sm:hidden">:</span>
                          </dt>
                          <dd className="inline text-gray-700 sm:mt-1 sm:block ">
                            {formatPaymentMethod(
                              order?.paymentInfo?.payments[order?.paymentInfo?.payments.length - 1].obj
                                .paymentMethodInfo.method,
                            )}
                          </dd>
                        </div>
                      </dl>
                    </div>
                    <div className="mt-2 w-full text-gray-500">
                      <caption className="sr-only">
                        {formatProductMessage({
                          id: 'products',
                          defaultMessage: 'Products',
                        })}
                      </caption>
                      <Accordion
                        closedSectionTitle=""
                        openSectionTitle={formatOrderMessage({ id: 'order.summary', defaultMessage: 'Order summary' })}
                        iconColor={isTabletSize ? 'text-gray-500' : 'text-primary'}
                      >
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'flex-end',
                            marginTop: '1rem',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            gap: '0.4rem',
                          }}
                        >
                          {order?.shippingInfo?.deliveries?.length > 0 && (
                            <p className="text-gray-800">
                              {formatAccountMessage({
                                id: 'track.parcels',
                                defaultMessage: 'Track your parcels',
                              })}
                            </p>
                          )}
                          {order?.shippingInfo?.deliveries?.map((delivery) =>
                            delivery.parcels.map((parcel, idx) => (
                              <a
                                href={`https://www.dhl.de/en/privatkunden/dhl-sendungsverfolgung.html?piececode=${parcel.trackingData.trackingId}`}
                                target="_blank"
                                rel="noreferrer"
                                className="text-primary"
                                key={idx}
                              >
                                {parcel.trackingData.trackingId}
                              </a>
                            )),
                          )}
                          {order.orderNumber && (
                            <PDFDownloadLink
                              document={<OrderConfirmation order={order} customerId={account?.customerNumber ?? ''} />}
                              fileName="order-confirmation.pdf"
                            >
                              {({ blob, url, loading, error }) =>
                                loading
                                  ? formatAccountMessage({
                                      id: 'loading.document',
                                      defaultMessage: 'Loading document...',
                                    })
                                  : formatAccountMessage({
                                      id: 'confirmation.download',
                                      defaultMessage: 'Download order confirmation',
                                    })
                              }
                            </PDFDownloadLink>
                          )}
                          {order.invoiceNumber && (
                            <div
                              style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'flex-end',
                                marginBottom: '1rem',
                              }}
                            >
                              <PDFDownloadLink
                                document={<InvoiceDocument order={order} customerId={account?.customerNumber ?? ''} />}
                                fileName="invoice.pdf"
                              >
                                {({ blob, url, loading, error }) =>
                                  loading
                                    ? formatAccountMessage({
                                        id: 'loading.document',
                                        defaultMessage: 'Loading document...',
                                      })
                                    : formatAccountMessage({
                                        id: 'invoice.download',
                                        defaultMessage: 'Download your invoice',
                                      })
                                }
                              </PDFDownloadLink>
                            </div>
                          )}
                          {order.isDelivered &&
                            order?.shippingInfo?.deliveries.map((delivery, idx) => (
                              <div
                                style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}
                                key={`delivery-${idx}`}
                              >
                                <PDFDownloadLink
                                  document={
                                    <DeliveryNote
                                      order={order}
                                      delivery={delivery}
                                      customerId={account.customerNumber}
                                    />
                                  }
                                  fileName="delivery-note.pdf"
                                >
                                  {({ blob, url, loading, error }) =>
                                    loading
                                      ? formatAccountMessage({
                                          id: 'loading.document',
                                          defaultMessage: 'Loading document...',
                                        })
                                      : `${formatAccountMessage({
                                          id: 'deliveryNote.download',
                                          defaultMessage: 'Download delivery note',
                                        })} ${idx + 1}`
                                  }
                                </PDFDownloadLink>
                              </div>
                            ))}
                        </div>
                        <div className="flex flex-wrap gap-6 text-sm">
                          {order.lineItems.map((product) => (
                            <div className={`flex flex-col gap-4 ${product.isGift}`} key={product.lineItemId}>
                              {order.lineItemsState[0]?.state[0]?.stateId && (
                                <div className="flex gap-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 50 50"
                                    fill="#2A5967"
                                  >
                                    <path d="M 1 3 L 1 15 L 3 15 L 3 48 L 47 48 L 47 15 L 49 15 L 49 3 Z M 3 5 L 47 5 L 47 13 L 3 13 Z M 5 15 L 45 15 L 45 46 L 5 46 Z M 17.5 19 C 15.578125 19 14 20.578125 14 22.5 C 14 24.421875 15.578125 26 17.5 26 L 32.5 26 C 34.421875 26 36 24.421875 36 22.5 C 36 20.578125 34.421875 19 32.5 19 Z M 17.5 21 L 32.5 21 C 33.339844 21 34 21.660156 34 22.5 C 34 23.339844 33.339844 24 32.5 24 L 17.5 24 C 16.660156 24 16 23.339844 16 22.5 C 16 21.660156 16.660156 21 17.5 21 Z"></path>
                                  </svg>
                                  <div>
                                    {order.lineItemsState
                                      .find((item) => item.id === product.productId)
                                      ?.state.map((s, idx) => {
                                        return (
                                          <p className="text-sm text-primary" key={idx}>{`${s.quantity}x ${
                                            // lineItemsStatesMapper[s.stateId]
                                            formatAccountMessage({
                                              id: lineItemsStatesMapper[s.stateId],
                                              defaultMessage: '',
                                            })
                                          }`}</p>
                                        );
                                      })}
                                  </div>
                                </div>
                              )}
                              <div className="shrink-1 w-24 text-center sm:w-40">
                                <NextLink href={product._url || ''}>
                                  <a className="text-primary">
                                    <span className="sr-only"> {product.name}</span>
                                    <div className="relative w-24 rounded-[4px] border-[1px] border-neutral-200 sm:w-40 ">
                                      {/* <Image
                                      src={product.variant.images[0]}
                                      alt={product.name}
                                      className="h-full w-full rounded object-cover"
                                    /> */}
                                      <CustomImage imgSrc={product.variant.images[0]} width={160} height={210} />
                                    </div>
                                    <div className="mt-2 text-base font-semibold text-black">{product.name}</div>

                                    {product?.isGift ? (
                                      <div className="mt-2 flex items-center justify-center gap-2">
                                        <Price
                                          price={product.price}
                                          currency={product.price.currencyCode}
                                          className="w-fittext-sm  text-gray-500 line-through"
                                        />
                                        <div className="text-secondary">
                                          <GiftIcon className="flex h-5 w-5" aria-hidden="true" />
                                        </div>
                                      </div>
                                    ) : product?.discounts.find(
                                        (discount) => discount.discountedAmount.centAmount === product.price.centAmount,
                                      ) ? (
                                      <div className="flex items-center justify-center gap-2">
                                        <Price
                                          price={product.price}
                                          currency={product.price.currencyCode}
                                          className={`mt-2 w-fit text-sm text-gray-500 ${
                                            product.price.centAmount * product.count !== product.totalPrice.centAmount
                                              ? 'line-through'
                                              : ''
                                          }`}
                                        />
                                        <div className="mt-2 inline-flex text-secondary">
                                          {formatOrderMessage({ id: 'item.free', defaultMessage: 'Free' })}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="mt-2 flex items-center justify-center gap-2">
                                        <Price
                                          price={product.price}
                                          currency={product.price.currencyCode}
                                          className={`w-fit text-sm text-gray-500 ${
                                            product.price.centAmount * product.count !== product.totalPrice.centAmount
                                              ? 'line-through'
                                              : ''
                                          }`}
                                        />
                                        {product.price.centAmount * product.count !== product.totalPrice.centAmount && (
                                          <Price
                                            price={{
                                              currencyCode: product.totalPrice.currencyCode,
                                              fractionDigits: product.totalPrice.fractionDigits,
                                              centAmount: product.totalPrice.centAmount / product.count,
                                            }}
                                            currency={product.totalPrice.currencyCode}
                                            className="w-fit text-sm text-gray-500"
                                          />
                                        )}
                                      </div>
                                    )}
                                    <div className="mt-2 text-xs text-gray-500">
                                      {formatProductMessage({
                                        id: 'size',
                                        defaultMessage: 'Size',
                                      })}
                                      : {product.variant.attributes.commonSize.key.toUpperCase()}
                                    </div>
                                  </a>
                                </NextLink>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-wrap gap-10">
                          <div className="mt-8 flex gap-2 text-sm text-[#242424]">
                            <p>
                              {`${formatOrderMessage({ id: 'shipping.estimate', defaultMessage: 'Shipping costs' })}: `}
                            </p>
                            <div className="flex text-secondary">
                              <Price
                                price={order.shippingInfo.price}
                                currency={order.shippingInfo.price.currencyCode}
                                className="inline w-fit text-gray-500"
                              />
                            </div>
                          </div>

                          {order.discountedAmount && (
                            <div className="mt-8 flex gap-2 text-sm text-[#242424]">
                              <p>
                                {formatOrderMessage({
                                  id: 'order.total.discount',
                                  defaultMessage: 'Discount on order total:',
                                })}
                              </p>
                              <div className="flex text-secondary">
                                <p>-</p>
                                <Price
                                  price={order.discountedAmount}
                                  currency={order.discountedAmount.currencyCode}
                                  className="inline w-fit"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </Accordion>
                    </div>
                  </li>
                );
              })}
            </ul>
          </section>
        ) : (
          <p className="mt-10 max-w-2xl text-sm text-gray-500">
            {formatAccountMessage({
              id: 'orders.no.orders',
              defaultMessage: 'You have not placed any orders yet! ',
            })}
          </p>
        )}
      </div>
    </div>
  );
};

export default OrdersHistory;
