import React from 'react';
import { useRouter } from 'next/router';
import { useQuery, gql } from '@apollo/client';
import { ContactForm } from '../contact-form';
import { FullPageWidthWrapper } from '../full-bleed';
import { sections } from '../../../graphql/strapi/sections';
import { ComponentMapper } from './ComponentMapper';
import { useAccount } from 'frontastic';

const StrapiContent = ({ name }: { name: string }) => {
  const router = useRouter();
  const { account } = useAccount();

  const GET_PAGES = gql`
  
    query Page($name: String) {
      pages(filters: { name: { eq: $name } }, locale: "${router.locale}") {
        data {
          id
          attributes {
            __typename
            locale
            name
            body {
              __typename
              ...S1Banner
              ...S4ProductList
              ...S3SingleProduct
              ...S55Form
              ...Cz12TextBlock
              ...Cz10Accordion
              ...Cz15Article
              ...Cz11Cta
              ...Cz13ImageWithText
              ...CzAboutUs
              ...Cz16Features
              ...Cz14ImageGallery
            }
          }
        }
      }
    }
    ${sections}
  `;

  const { loading, error, data } = useQuery(GET_PAGES, {
    variables: { name },
  });

  if (loading) return <></>;

  if (error) return <p>Error: {error.message}</p>;

  return (
    <FullPageWidthWrapper>
      {data?.pages?.data[0]?.attributes?.body.map((component) => {
        if (component.__typename === 'ComponentSectionsS55Form') {
          return (
            <ContactForm
              formName={component.formName}
              submitText={component.submitText}
              fields={component.fields}
              id={component.id}
              pageId={data.pages.data[0].id}
              key={`${component.__typename}-${component.id}`}
            />
          );
        } else {
          return <ComponentMapper key={`${component.__typename}-${component.id}`} component={component} />;
        }
      })}
    </FullPageWidthWrapper>
  );
};

export default StrapiContent;
