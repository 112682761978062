import { Order } from '@Types/cart/Order';
import { CustomCheckbox } from 'components/tailgrids-ui/adyen-checkout/panels/address';
import { FullPageWidthWrapper } from 'components/tailgrids-ui/full-bleed';
import { useAccount } from 'frontastic';
import { PartialAddress } from 'frontastic/actions/account';
import { getThankYouOrder } from 'frontastic/actions/cart';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
import { useFormat } from 'helpers/hooks/useFormat';
import { useQueryOrder } from 'helpers/hooks/useQueryOrder';
import { ReferenceLink } from 'helpers/reference';
import { useAnonymousStore } from 'helpers/stores/anonymousStore';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

type Props = {
  image: MediaItemWithMods;
  title: string;
  subtitle?: string;
  includeNewsletter?: boolean;
  newsletterLabel?: string;
  buttonText: string;
};

export const ThankYouRegister = ({ image, title, subtitle, includeNewsletter, newsletterLabel, buttonText }: Props) => {
  const [subscribe, setSubscribe] = useState<boolean>(true);
  const { anonymousId } = useAnonymousStore();
  const router = useRouter();
  const [order, setOrder] = useState<Partial<Order>>();
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatSuccessMessage } = useFormat({ name: 'success' });

  //account actions
  const { register, loggedIn } = useAccount();

  //register data
  const [data, setData] = useState({
    firstName: order?.billingAddress?.firstName ?? '',
    lastName: order?.billingAddress?.lastName ?? '',
    email: order?.email ?? '',
    password: '',
    confirmPassword: '',
  });

  //error
  const [error, setError] = useState('');

  //success
  const [success, setSuccess] = useState('');

  //processing...
  const [loading, setLoading] = useState(false);

  //account creation error
  const [creationError, setCreationError] = useState<boolean>(false);

  //handle text input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, email: data.email, [e.target.name]: e.target.value });
  };

  //data validation
  const validate = () => {
    //validation schema
    const passwordsMatch = data.password === data.confirmPassword;

    //UI error messages
    if (!passwordsMatch) {
      setCreationError(false);
      setError(formatErrorMessage({ id: 'password.noMatch', defaultMessage: "Passwords don't match" }));
    }
    //return a boolean representing the data validity
    return passwordsMatch;
  };

  //form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //validate data
    if (!validate()) return;
    //processing starts
    setLoading(true);
    //try registering the user with given credentials
    try {
      const response = await register({
        email: data.email,
        password: data.password,
        firstName: data.firstName ?? '',
        lastName: data.lastName ?? '',
        billingAddress: order.billingAddress as PartialAddress,
        shippingAddress: order.billingAddress as PartialAddress,
      });

      if (!response.accountId) {
        setCreationError(true);
        setError(
          // formatErrorMessage({ id: 'account.create.fail', defaultMessage: "Sorry. We couldn't create your account.." }),
          formatErrorMessage({
            id: 'accountNotCreated',
            defaultMessage: 'Your account could not be created, maybe your email is already registered? ',
          }),
        );
        setSuccess('');
      } else {
        setCreationError(false);
        setError('');
        setSuccess(
          formatSuccessMessage({
            id: 'account.created',
            defaultMessage: 'A verification email was sent to {email} ✓',
            values: { email: data.email },
          }),
        );
      }
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
      setSuccess('');
    }
    //processing ends
    setLoading(false);
  };

  useEffect(() => {
    const getOrder = async () => {
      const order = await getThankYouOrder(router?.query?.orderId as string, anonymousId);
      setOrder(order);
    };
    getOrder();
  }, [loggedIn]);

  if (loggedIn || !order?.orderId) return <></>;

  return (
    <FullPageWidthWrapper>
      <section className="my-12 mx-auto flex max-w-[1280px] flex-row flex-wrap gap-8 px-4 sm:px-10 lg:px-4">
        <div className="hidden max-h-[512px] w-full rounded lg:block lg:w-[368px]">
          <Image
            src={image.media.file}
            layout="fill"
            className="h-full w-full rounded object-cover"
            alt="Register Image"
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex grow flex-col gap-6 rounded bg-[#F6F9FF] p-8 lg:max-w-[848px]">
            <h1 className="w-full text-2xl font-semibold text-[#242424]">{title}</h1>
            <h2 className="w-full text-base font-medium text-[#242424]">{subtitle}</h2>
            {success && <p className="text-sm text-[#34D399]">{success}</p>}
            {error && (
              <div className="justiy-start flex flex-col items-start gap-2">
                <p className="text-sm text-[#F87171]">{error}</p>
                {creationError && (
                  <p className="text-center text-sm text-[#808080]">
                    <ReferenceLink
                      target={{
                        link: '/login',
                        target: '/login',
                        openInNewWindow: false,
                        type: 'link',
                      }}
                      className="font-medium text-primary underline hover:text-[#33B8A1]"
                    >
                      {formatAccountMessage({ id: 'account.login.here', defaultMessage: 'Login here' })}
                    </ReferenceLink>
                  </p>
                )}
              </div>
            )}
            <div className="flex w-full flex-col gap-6">
              <p className="text-base font-medium text-[#242424]">
                {formatAccountMessage({ id: 'password', defaultMessage: 'Password' })}
              </p>
              <div className="w-full">
                <input
                  className="w-full appearance-none rounded border border-gray-300 bg-[#F6F9FF] p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary disabled:bg-[#EDEDED]"
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  onChange={handleChange}
                  placeholder={formatAccountMessage({ id: 'password', defaultMessage: 'Password' })}
                  required
                />
              </div>

              <div className="w-full">
                <input
                  className=" w-full appearance-none rounded border border-gray-300 bg-[#F6F9FF] p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary disabled:bg-[#EDEDED]"
                  id="confirm-password"
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                  onChange={handleChange}
                  placeholder={formatAccountMessage({ id: 'password.confirm', defaultMessage: 'Confirm Password' })}
                  required
                />
              </div>
            </div>
            {includeNewsletter && (
              <CustomCheckbox
                labelTitle={newsletterLabel}
                id="isDefaultBillingAddress"
                checked={subscribe}
                onChange={() => setSubscribe(!subscribe)}
              />
            )}
            <button
              type="submit"
              className="inline-flex w-fit items-center justify-center rounded bg-primary py-[10px] px-7 text-center text-base font-semibold text-white hover:bg-opacity-90"
            >
              {buttonText}
            </button>
          </div>
        </form>
      </section>
    </FullPageWidthWrapper>
  );
};

export default ThankYouRegister;
