import React from 'react';

type Props = {
  className?: string;
};

const LinkedinIcon: React.FC<Props> = ({ className }: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_399_3173)">
      <path
        d="M21.9371 0.375H2.09961C1.16211 0.375 0.412109 1.125 0.412109 2.0625V21.9375C0.412109 22.8375 1.16211 23.625 2.09961 23.625H21.8621C22.7996 23.625 23.5496 22.875 23.5496 21.9375V2.025C23.6246 1.125 22.8746 0.375 21.9371 0.375ZM7.27461 20.1375H3.86211V9.075H7.27461V20.1375ZM5.54961 7.5375C4.42461 7.5375 3.56211 6.6375 3.56211 5.55C3.56211 4.4625 4.46211 3.5625 5.54961 3.5625C6.63711 3.5625 7.53711 4.4625 7.53711 5.55C7.53711 6.6375 6.71211 7.5375 5.54961 7.5375ZM20.2121 20.1375H16.7996V14.775C16.7996 13.5 16.7621 11.8125 14.9996 11.8125C13.1996 11.8125 12.9371 13.2375 12.9371 14.6625V20.1375H9.52461V9.075H12.8621V10.6125H12.8996C13.3871 9.7125 14.4746 8.8125 16.1621 8.8125C19.6496 8.8125 20.2871 11.0625 20.2871 14.1375V20.1375H20.2121Z"
        fill="#808080"
      />
    </g>
    <defs>
      <clipPath id="clip0_399_3173">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LinkedinIcon;
