import Typography from 'components/commercetools-ui/typography';
import Image from 'next/image';
import React from 'react';
import { MediaItemWithMods, MediaItem } from 'frontastic/lib/image/types';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useFormat } from 'helpers/hooks/useFormat';

export interface CtaTasticProps {
  data: {
    ctaHeadline: string;
    fgImage: MediaItem;
    reference: string;
  };
}

export default function CtaTastic({ data }) {
  const { formatMessage } = useFormat({ name: 'common' });
  return (
    <div className="flex w-full flex-wrap items-center justify-center bg-zinc-200 py-20 px-4 sm:gap-4 md:gap-12">
      <Link href={data.reference.link}>
        <Image
          src={data.fgImage.media.file}
          width={400}
          height={400}
          // layout="fill"
          objectFit="contain"
          unoptimized={true}
          style={{
            cursor: 'pointer',
            padding: '2rem',
          }}
        />
      </Link>

      <div className="flex flex-col items-center justify-center gap-8">
        <h3
          className={`w-full whitespace-pre-line px-10 text-center text-3xl font-extrabold tracking-tight sm:px-10 md:w-60 md:text-left md:text-3xl lg:text-4xl`}
        >
          <Typography>{data.ctaHeadline ?? 'follow fashion week'}</Typography>
        </h3>
        <Link href={data.reference.link}>
          <button
            type="button"
            style={{ backgroundColor: '#647c90', padding: '10px', color: '#fff', borderRadius: '5px' }}
          >
            {formatMessage({ id: 'shopNow', defaultMessage: 'Shop Now' })}
          </button>
        </Link>
      </div>
    </div>
  );
}
