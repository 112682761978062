import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { Money } from '@Types/product/Money';
import Typography from 'components/commercetools-ui/typography';
import { CurrencyHelpers } from 'helpers/currencyHelpers';

export interface PriceProps {
  price: Money;
  className?: string;
  currency?: string;
}

const countryToCurrency: Record<string, string> = {
  DE: 'EUR',
  US: 'USD',
  GB: 'GBP',
  FR: 'EUR',
};

const Price: React.FC<PriceProps> = ({ price: price, className, currency }) => {
  const router = useRouter();
  const userCountry = useMemo(() => {
    return router.locale.split('-')[1];
  }, [router.locale]);

  return (
    <div className={className || 'mt-1 text-sm font-medium text-gray-900 dark:text-light-100'}>
      {/* <Typography>{CurrencyHelpers.formatForCurrency(price)}</Typography> */}
      <Typography>
        {CurrencyHelpers.formatForCurrency(
          { ...price, currencyCode: currency ? currency : countryToCurrency[userCountry] },
          router.locale,
        )}
      </Typography>
    </div>
  );
};

export default Price;
