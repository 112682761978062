import { Cloudinary } from '@cloudinary/url-gen';
import { AdvancedImage, lazyload, responsive } from '@cloudinary/react';
import { trim } from '@cloudinary/url-gen/actions/reshape';
import { pad } from '@cloudinary/url-gen/actions/resize';

const cld = new Cloudinary({
  cloud: {
    cloudName: process.env.NEXT_PUBLIC_ZYRES_CLOUDINARY_CLOUD_NAME,
  },
});

export const CustomImage = ({
  imgSrc,
  width,
  height,
  transformation = true,
}: {
  imgSrc: string;
  width: number;
  height: number;
  transformation?: boolean;
}) => {
  const file = imgSrc;
  let image = cld.image(file);
  image = transformation
    ? image.setDeliveryType('fetch').reshape(trim()).resize(pad().width(width).height(height))
    : image.setDeliveryType('fetch');

  return <AdvancedImage cldImg={image} plugins={[responsive(), lazyload()]} />;
};
