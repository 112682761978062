import React, { FC } from 'react';
import { DataTestId } from '@Types/playwright';
import { useToggleRefinement } from 'react-instantsearch';
import { UseToggleRefinementProps } from 'react-instantsearch-core/dist/es/connectors/useToggleRefinement';
import { SingleCheckBox } from 'components/tailgrids-ui/products/product-filter';
import { TYPESENSE_LABEL_PREFIX } from 'features/typesense/utils';

type ToggleFilterProps = UseToggleRefinementProps & { label?: string; className?: string } & DataTestId;

export const ToggleFilter: FC<ToggleFilterProps> = (props) => {
  const { label, className, ...rest } = props;
  const { refine, value, canRefine } = useToggleRefinement(rest);
  return (
    <SingleCheckBox
      filterLabel={TYPESENSE_LABEL_PREFIX + rest.attribute}
      label={label}
      selected={value.isRefined}
      onChange={(lbl) => {
        if (lbl && canRefine) {
          refine({ isRefined: value.isRefined });
        }
      }}
      color=""
      className={className}
      data-testid={props['data-testid']}
    />
  );
};
