import { useEffect, useState } from 'react';
import { Cart } from '@Types/cart/Cart';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import Item from './item';

interface Props {
  readonly cart: Cart;
  readonly editItemQuantity: (lineItemId: string, newQuantity: number) => void;
  readonly goToProductPage: (_url: string) => void;
  readonly removeItem: (lineItemId: string) => void;
}

const CustomerGroupMapper: Record<string, string> = {
  '01f93f0d-8c7f-4ba4-8fac-d0572dfab531': 'diamond',
  'ed801dda-7623-4f0a-8ecc-aaebd36aa199': 'b2b',
  '53e927e1-cece-42ac-a43a-ad854c250349': 'gold',
  '6d10c6e0-2b4e-4c46-bd33-c5655ef10121': 'platinum',
  '09fe4826-29ac-4530-8ee6-f6386b674499': 'silver',
};

const ItemList = ({ cart, editItemQuantity, goToProductPage, removeItem }: Props) => {
  //i18n messages
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { loggedIn, account } = useAccount();
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    const removeSpecial = async () => {
      if (loggedIn) {
        // setCartItems(cart?.lineItems);
        if (
          account?.customerGroup?.id &&
          cart?.lineItems !== undefined &&
          cart?.lineItems?.filter(
            (item) =>
              item.variant.attributes.customerGroupTarget !== undefined &&
              item.variant.attributes.customerGroupTarget.key !== CustomerGroupMapper[account?.customerGroup.id],
          ).length > 0
        ) {
          const specialProducts = cart.lineItems.filter(
            (item) =>
              item.variant.attributes.customerGroupTarget !== undefined &&
              item.variant.attributes.customerGroupTarget !== CustomerGroupMapper[account?.customerGroup.id],
          );
          for (const product of specialProducts) {
            await removeItem(product.lineItemId);
          }
        } else {
          setCartItems(cart?.lineItems);
        }
      } else {
        if (
          cart?.lineItems !== undefined &&
          cart?.lineItems?.filter((item) => item.variant.attributes.loggedInOnly).length > 0
        ) {
          const loggedInProducts = cart.lineItems.filter((item) => item.variant.attributes.loggedInOnly);
          for (const product of loggedInProducts) {
            await removeItem(product.lineItemId);
          }
        }
        if (
          cart?.lineItems !== undefined &&
          cart?.lineItems?.filter((item) => item.variant.attributes.customerGroupTarget !== undefined).length > 0
        ) {
          const specialProducts = cart.lineItems.filter(
            (item) => item.variant.attributes.customerGroupTarget !== undefined,
          );
          for (const product of specialProducts) {
            await removeItem(product.lineItemId);
          }
        }
      }
    };
    removeSpecial();
  }, [loggedIn]);

  return (
    <section aria-labelledby="cart-heading" className="mb-5 md:mb-0 lg:col-span-7">
      <h2 id="cart-heading" className="sr-only">
        {formatCartMessage({ id: 'cart.shopping.items', defaultMessage: 'Items in your shopping cart' })}
      </h2>

      <ul className="grid md:gap-5" role="list">
        {cart.lineItems.map((lineItem, i) => (
          <li key={i} className="mb-10 border-b border-[#E7E7E7] last:border-none">
            <Item
              // lineItem={loggedIn ? lineItem : lineItem.variant.attributes.loggedInOnly}
              lineItem={lineItem}
              // loggedIn ? products : products.filter((product) => !product.variants[0].attributes.loggedInOnly
              editItemQuantity={editItemQuantity}
              goToProductPage={goToProductPage}
              removeItem={removeItem}
              isGift={lineItem.isGift}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ItemList;
