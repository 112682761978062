import { CzAboutUsFragment } from 'generated/strapiTypes';
import { AboutUsItem, CZ17AboutUs } from './CZ17AboutUs';
import { useWindowSize } from 'usehooks-ts';
import { tablet } from 'helpers/utils/screensizes';
import { useAccount } from 'frontastic';

export const CZ17AboutUsAdapter = (props: CzAboutUsFragment) => {
  const { width } = useWindowSize();
  const { account } = useAccount();

  const aboutUsItems: AboutUsItem[] = [];
  props?.AboutItem?.forEach((item) => {
    aboutUsItems.push({
      title: item?.title ?? '',
      subtitle: item?.subtitle ?? '',
      imageAlt: item?.imageAlt ?? '',
      imageTitle: item?.imageTitle ?? '',
      image: {
        data: {
          attributes: {
            url:
              width > tablet
                ? item?.image?.data[0]?.attributes?.url ?? ''
                : item?.imageMobile?.data[0]?.attributes?.url ?? item?.image?.data[0]?.attributes?.url ?? '',
          },
        },
      },

      targetBlank: item?.targetBlank ?? false,
      linkUrl: item?.linkUrl ?? '',
    });
  });

  if (props?.customerGroups?.length > 0 && !props?.customerGroups?.includes(account?.customerGroup?.id)) return null;

  return (
    <CZ17AboutUs
      title={props?.title ?? ''}
      subtitle={props?.subtitle ?? ''}
      text={props?.text ?? ''}
      aboutUsList={aboutUsItems}
    />
  );
};
