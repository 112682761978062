import { Cz16FeaturesFragment } from 'generated/strapiTypes';
import CZ16Features, { FeaturesItem } from './CZ16Features';
import { useAccount } from 'frontastic';

export const CZ16FeaturesAdapter = (props: Cz16FeaturesFragment) => {
  const { account } = useAccount();

  const featuresList: FeaturesItem[] = [];
  props?.FeatItem?.forEach((item) => {
    featuresList.push({
      title: item?.title ?? '',
      text: item?.text ?? '',
      imageAlt: item?.imageAlt ?? '',
      imageTitle: item?.imageTitle ?? '',
      icon: {
        data: {
          attributes: {
            url: item?.icon?.data?.attributes?.url ?? '',
          },
        },
      },
      targetBlank: item?.targetBlank ?? false,
      linkUrl: item?.linkUrl ?? '',
    });
  });

  if (props?.customerGroups?.length > 0 && !props?.customerGroups?.includes(account?.customerGroup?.id)) return null;

  return <CZ16Features title={props.title ?? ''} featuresList={featuresList} />;
};
