import { Cz11CtaFragment } from 'generated/strapiTypes';
import CZ11Cta, { CtaItem } from './CZ11Cta';
import { useAccount } from 'frontastic';

export const CZ11CtaAdapter = (props: Cz11CtaFragment) => {
  const { account } = useAccount();
  const ctaItems: CtaItem[] = [];
  props?.ctaItem?.forEach((item) => {
    ctaItems.push({
      title: item?.title ?? '',
      subtitle: item?.subtitle ?? '',
      text: item?.text ?? '',
      buttonText: item?.buttonText ?? '',
      buttonUrl: item?.buttonUrl ?? '',
      icon: {
        data: {
          attributes: {
            url: item?.icon?.data?.attributes?.url ?? '',
          },
        },
      },
      iconAlt: item?.iconAlt ?? '',
    });
  });

  if (props?.customerGroups?.length > 0 && !props?.customerGroups?.includes(account?.customerGroup?.id)) return null;

  return <CZ11Cta ctaItems={ctaItems} />;
};
