import React, { FC } from 'react';
import { DataTestId } from '@Types/playwright';
import { useClearRefinements, UseClearRefinementsProps } from 'react-instantsearch';
import { TYPESENSE_LABEL_PREFIX } from 'features/typesense/utils';
import { useFormat } from 'helpers/hooks/useFormat';

type ResetFiltersButtonProps = UseClearRefinementsProps & DataTestId;

export const ResetFiltersButton: FC<ResetFiltersButtonProps> = (props) => {
  const { refine, canRefine } = useClearRefinements(props);
  const { formatMessage } = useFormat({ name: 'product' });

  return (
    <button
      className={
        'inline-flex w-full select-none flex-col items-center justify-center rounded border px-8 py-3 ' +
        (canRefine ? 'cursor-pointer border-cyan-900' : 'cursor-not-allowed border-neutral-300')
      }
      onClick={() => {
        if (canRefine) {
          refine();
        }
      }}
      data-testid={props['data-testid']}
    >
      <div className={'text-center text-sm font-bold ' + (canRefine ? 'text-cyan-900' : 'text-neutral-400')}>
        {formatMessage({ id: TYPESENSE_LABEL_PREFIX + 'reset', defaultMessage: 'Reset filter' })}
      </div>
    </button>
  );
};
