import React from 'react';

type Props = {
  className?: string;
};

const FacebookIcon: React.FC<Props> = ({ className }: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_399_3172)">
      <path
        d="M22.3125 0.375H1.6875C0.9375 0.375 0.375 0.975 0.375 1.6875V22.3125C0.375 23.0625 0.975 23.625 1.6875 23.625H12.7875V14.5875H9.7875V11.1H12.7875V8.5125C12.7875 5.5125 14.5875 3.8625 17.2875 3.8625C18.1875 3.8625 19.0875 3.9 19.9875 4.0125V7.125H18.1875C16.7625 7.125 16.4625 7.8 16.4625 8.8125V11.025H19.875L19.3875 14.55H16.3875V23.475H22.3125C23.0625 23.475 23.625 22.9125 23.625 22.1625V1.6875C23.5875 0.9375 22.9875 0.375 22.3125 0.375Z"
        fill="#808080"
      />
    </g>
    <defs>
      <clipPath id="clip0_399_3172">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FacebookIcon;
