import React from 'react';
// import Footer from 'components/commercetools-ui/footer';
// import Highlights from 'components/commercetools-ui/footer/highlights';
import Highlights from 'components/tailgrids-ui/footer/highlights';
import Footer from 'components/tailgrids-ui/footer';
import { FullPageWidthWrapper } from 'components/tailgrids-ui/full-bleed';

const FooterTastic = ({ data }) => {
  const column = {
    header: data.headerCol1,
    links: data.linksCol1,
    icon: data.iconCol1,
  };

  return (
    <FullPageWidthWrapper>
      <div className="fixed-screen-width lg:relative-width">
        <Footer
          column={column}
          categories={data.categories.dataSource}
          copyright={data.copyright}
          copyrightLinks={data.copyrightLinks}
        />
      </div>
    </FullPageWidthWrapper>
  );
};

export default FooterTastic;
