import { LogLevelNames } from 'loglevel';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { ConfigurationOptions, NodeConfiguration } from 'typesense/lib/Typesense/Configuration';
import { publicConfig } from 'features/typesense/utils/config_public';

const node01: NodeConfiguration = {
  host: publicConfig.typesenseHost,
  port: publicConfig.typesensePort,
  protocol: publicConfig.typesenseProtocol,
};

export const assembleTypesenseServerConfig = (): ConfigurationOptions => {
  return {
    apiKey: publicConfig.typesenseSearchOnlyKey,
    nodes: [node01],
    numRetries: 8,
    connectionTimeoutSeconds: 6,
    cacheSearchResultsForSeconds: 2 * 60,
    sendApiKeyAsQueryParam: false,
    logLevel: publicConfig.typesenseLogLevel as LogLevelNames,
  };
};

export type QueryBy = {
  [key: string]: number;
};

type TypesenseConfigOptions = {
  queryBy?: QueryBy;
  exclude_fields?: string;
  include_fields?: string;
  use_cache?: boolean;
  exhaustive_search?: boolean;
  vector_query?: string;
  per_page?: number;
};

export const getTypesenseConfig = (options: TypesenseConfigOptions) => {
  const { queryBy, ...restOptions } = options;
  const queryBys: QueryBy = {
    'names.value': 4,
    'slugs.value': 2,
    keywords: 3,
    'attributes.gender': 2,
    'attributes.size': 2,
    'attributes.style': 2,
    'attributes.color': 2,
    'attributes.designer': 2,
    'descriptions.value': 2,
    product_id: 1,
    sku: 2,
    ...queryBy,
  };
  return {
    query_by: Object.keys(queryBys).join(','),
    query_by_weights: Object.values(queryBys).join(','),
    exhaustive_search: true,
    group_by: 'product_id',
    group_limit: 99,
    use_cache: process.env.NODE_ENV !== 'development',
    exclude_fields: 'version,categories,published,keywords,embedding',
    ...restOptions,
  };
};

const TYPESENSE_SERVER_CONFIG = assembleTypesenseServerConfig();
export const getTypesenseInstantSearchAdapter = (options?: TypesenseConfigOptions) => {
  return new TypesenseInstantSearchAdapter({
    server: TYPESENSE_SERVER_CONFIG,
    additionalSearchParameters: getTypesenseConfig(options ?? {}),
  });
};
