import React from 'react';
import Highlights from 'components/tailgrids-ui/footer/highlights';
import { FullPageWidthWrapper } from 'components/tailgrids-ui/full-bleed';

const HighlightsTastic = ({ data }) => {
  return (
    <FullPageWidthWrapper>
      <div className="fixed-screen-width lg:relative-width">
        <Highlights {...data} />
      </div>
    </FullPageWidthWrapper>
  );
};

export default HighlightsTastic;
