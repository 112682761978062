import { S55FormFragment } from 'generated/strapiTypes';
import { ContactForm, Field } from '.';
import { useAccount } from 'frontastic';

export const ContactFormAdapter = (props: S55FormFragment & { pageId: string }) => {
  const { account } = useAccount();

  const formFields: Field[] = [];
  props?.fields?.forEach((item) => {
    formFields.push({
      displayName: item?.displayName ?? '',
      placeholder: item?.placeholder ?? '',
      required: item?.required ?? false,
      inputType: item?.inputType ?? 'text',
    });
  });

  if (props?.customerGroups?.length > 0 && !props?.customerGroups?.includes(account?.customerGroup?.id)) return null;

  return (
    <ContactForm
      formName={props?.formName ?? ''}
      id={props?.id ?? ''}
      pageId={props?.pageId ?? ''}
      submitText={props?.submitText ?? ''}
      fields={formFields}
    />
  );
};
