import { useState, useEffect, FormEvent } from 'react';
import { useRouter } from 'next/router';
import { Cart as CartType } from '@Types/cart/Cart';
import EmptyCart from 'components/commercetools-ui/cart/emptyCart';
import Spinner from 'components/commercetools-ui/spinner';
import OrderSummary from 'components/tailgrids-ui/cart/orderSummary';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference } from 'helpers/reference';
import { NextFrontasticImage } from 'frontastic/lib/image';
import ItemList from './itemList';
import { FullPageWidthWrapper } from '../full-bleed';

export interface Props {
  pageTitle?: string;
  emptyStateImage?: NextFrontasticImage;
  emptyStateTitle?: string;
  emptyStateSubtitle?: string;
  emptyStateCTALabel?: string;
  emptyStateCTALink?: Reference;
  cart: CartType;
  editItemQuantity: (lineItemId: string, newQuantity: number) => Promise<void>;
  removeItem: (lineItemId: string) => void;
}

const Cart = ({
  cart,
  editItemQuantity,
  removeItem,
  pageTitle,
  emptyStateImage,
  emptyStateTitle,
  emptyStateSubtitle,
  emptyStateCTALabel,
  emptyStateCTALink,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(true);

  //i18n messages
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  const router = useRouter();

  const onCheckout = (e: FormEvent) => {
    e.preventDefault();
    router.push('/checkout');
  };

  const goToProductPage = (_url: string) => router.push(_url);

  useEffect(() => {
    if (cart?.lineItems) {
      setLoading(false);
    }
  }, [cart]);

  if (loading) {
    return (
      <div className="flex items-stretch justify-center py-20 px-12">
        <Spinner color="#2A5967" />
      </div>
    );
  }

  if ((!loading && !cart?.lineItems) || cart.lineItems.length < 1) {
    return (
      <EmptyCart
        pageTitle={pageTitle}
        image={emptyStateImage}
        title={emptyStateTitle}
        subtitle={emptyStateSubtitle}
        ctaLabel={emptyStateCTALabel}
        ctaLink={emptyStateCTALink}
      />
    );
  }

  return (
    <FullPageWidthWrapper>
      <main className="container mx-auto max-w-[1280px] px-4 pt-20 sm:px-10 lg:px-4">
        <h3 className="mb-12 text-heading-3 font-bold text-black">
          {formatCartMessage({ id: 'myCart', defaultMessage: 'My Shopping Cart' })}
        </h3>
        {loading ? (
          <div className="flex items-stretch justify-center py-10 px-12">
            <Spinner />
          </div>
        ) : (
          <form className="lg:grid lg:grid-cols-11 lg:items-start lg:gap-x-10">
            <ItemList
              cart={cart}
              editItemQuantity={editItemQuantity}
              goToProductPage={goToProductPage}
              removeItem={(lineItemId: string) => removeItem(lineItemId)}
            />
            <OrderSummary cart={cart} onSubmit={onCheckout} showDiscountsForm={false} />
          </form>
        )}
      </main>
    </FullPageWidthWrapper>
  );
};

export default Cart;
