import React from 'react';
import NextLink from 'next/link';
import { HomeIcon } from '@heroicons/react/solid';

export type BreadcrumbProps = {
  Separator?: React.ReactNode;
  SearchIcon?: boolean;
  className?: string;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ children, Separator, SearchIcon = true, className = '' }) => {
  return (
    <nav className={'flex ' + className} aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        {SearchIcon && (
          <li>
            <div>
              <NextLink href="/">
                <a className="text-gray-400 hover:text-gray-500">
                  <HomeIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
                  <span className="sr-only">Home</span>
                </a>
              </NextLink>
            </div>
          </li>
        )}
        {React.Children.map(children, (Child, index) => {
          if (index < React.Children.count(children) - 1) {
            return (
              <li>
                <b className="text-sm font-medium capitalize dark:text-light-100">{Child}</b>
                <span className="ml-3 dark:text-light-100">{Separator}</span>
              </li>
            );
          }
          return (
            <li>
              <span className="text-sm font-medium capitalize text-gray-400 dark:text-accent-400">{Child}</span>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
