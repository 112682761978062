import { TypesenseLocalizedString } from '@Types/typesense';

export const mapLocalizedString = (localizedStrings: TypesenseLocalizedString[], currentLocale: string): string => {
  const backupLanguage = 'de';
  const language = currentLocale.split('-')[0];
  return (
    localizedStrings?.find((item) => language === item?.locale)?.value ??
    localizedStrings?.find((item) => backupLanguage === item?.locale)?.value ??
    ''
  );
};
