import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

function useQueryParamState<T>(key: string, defaultValue: T) {
  const router = useRouter();
  const [state, setState] = useState<T>(() => {
    const queryValue = router.query[key];

    if (typeof defaultValue === 'number' && queryValue) {
      return Number(queryValue) as unknown as T;
    } else if (typeof defaultValue === 'boolean' && queryValue) {
      return (queryValue === 'true') as unknown as T;
    }

    return (queryValue || defaultValue) as unknown as T;
  });

  useEffect(() => {
    const newQuery = { ...router.query, [key]: String(state) };
    delete newQuery.path;

    router.push({ pathname: router.query.path as string, query: newQuery });
  }, [state, key]);

  return [state, setState] as [T, React.Dispatch<React.SetStateAction<T>>];
}

export default useQueryParamState;
