import { Cz14ImageGalleryFragment } from 'generated/strapiTypes';
import CZ14ImageGallery, { ImageGalleryItem } from '.';
import { useWindowSize } from 'usehooks-ts';
import { tablet } from 'helpers/utils/screensizes';
import { useAccount } from 'frontastic';

export const CZ14ImageGalleryAdapter = (props: Cz14ImageGalleryFragment) => {
  const { width } = useWindowSize();
  const { account } = useAccount();

  const galleryItems: ImageGalleryItem[] = [];

  props?.galleryItem?.forEach((item) => {
    galleryItems.push({
      text: item?.text ?? '',
      imageAlt: item?.imageAlt ?? '',
      imageTitle: item?.imageTitle ?? '',
      image: {
        data: {
          attributes: {
            url:
              width > tablet
                ? item?.image?.data?.attributes?.url ?? ''
                : item?.imageMobile?.data?.attributes?.url ?? item?.image?.data?.attributes?.url ?? '',
          },
        },
      },
      linkUrl: item?.linkUrl ?? '',
      linkTargetBlank: item?.linkTargetBlank ?? false,
    });
  });

  if (props?.customerGroups?.length > 0 && !props?.customerGroups?.includes(account?.customerGroup?.id)) return null;

  return <CZ14ImageGallery title={props?.title ?? ''} imageGalleryList={galleryItems} />;
};
