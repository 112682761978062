import React from 'react';
import { Reference, ReferenceLink } from 'helpers/reference';
import { FullPageWidthWrapper } from '../full-bleed';

export type BannerProps = {
  title: string;
  subtitle: string;
  description: string;
  isCentered: boolean;
  buttonText: string;
  reference: Reference;
};

export default function Banner({ title, subtitle, description, isCentered, buttonText, reference }: BannerProps) {
  return (
    <FullPageWidthWrapper>
      <section className="bg-[#102B33] py-20">
        <div className="container flex max-w-[1280px] flex-col gap-6 px-4 sm:gap-8 sm:px-10 lg:px-4">
          <div
            className={`flex flex-col justify-center gap-2 sm:gap-4 ${
              isCentered ? 'items-center text-center' : 'items-start text-left'
            }`}
          >
            <h4 className="text-base font-medium text-white sm:text-heading-4">{subtitle}</h4>
            <h1 className="text-heading-3 font-bold !leading-tight text-white sm:text-heading-1">{title}</h1>
            <p className={`text-sm font-medium text-white sm:text-base ${isCentered && 'max-w-[526px] text-center'}`}>
              {description}
            </p>
          </div>
          <ReferenceLink
            target={reference}
            className="inline-flex w-fit items-center justify-center rounded bg-secondary py-3 px-8 text-center text-base font-semibold text-white hover:bg-opacity-90"
          >
            {buttonText}
          </ReferenceLink>
        </div>
      </section>
    </FullPageWidthWrapper>
  );
}
