import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { Order } from '@Types/cart/Order';

type Props = {
  order: Order;
  customerId?: string;
};

const styles = StyleSheet.create({
  table: {
    // display: 'table',
    width: '90%',
    margin: '0 auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    tablelayout: 'fixed',
    borderCollapse: 'collapse',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    // width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    // wordBreak: 'break-word',
  },
  tableCell: {
    // margin: 'auto',
    marginTop: 5,
    fontSize: 10,
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    overflowWrap: 'break-all',
    whiteSpace: 'normal',
  },
  addressContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: 10,
    marginBottom: 20,
    marginTop: 20,
  },
  rightElement: {
    marginRight: 30,
  },
  leftElement: {
    marginLeft: 30,
  },
});

const INIT_NUMBER = 271;

export function hash(uuid) {
  const x = uuid.split('-').reduce((a, b) => a ^ Number.parseInt(b, 16), INIT_NUMBER);
  return arguments.length === 1 ? x : x;
}

export function customOrderNumber(uuid) {
  return `ZY${hash(uuid)}`;
}

// export function customCustomerNumber(uuid) {
//   return `RC${hash(uuid)}`;
// }

function InvoiceDocument({ order, customerId }: Props) {
  return (
    <Document>
      <Page>
        <View style={styles.addressContainer}>
          <View style={styles.leftElement}>
            <View>
              <Text style={{ fontSize: 20 }}>Invoice</Text>
            </View>
            <View style={{ marginTop: 20 }}>
              <Text>Sunrise Ecommerce GmbH</Text>
              <Text>Boxhagener Str.</Text>
              <Text>20149 Hamburg</Text>
            </View>
          </View>
          <View style={[styles.rightElement, { border: '2px solid gray', padding: 5 }]}>
            <Text>Order Date: {order.createdAt}</Text>
            <Text>Order Number: {order.orderNumber}</Text>
            {customerId && <Text>Customer Number: {customerId}</Text>}
            <Text>Invoice Number: {order.invoiceNumber}</Text>
          </View>
        </View>
        <View style={styles.addressContainer}>
          <View style={styles.leftElement}>
            <Text style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 5, color: 'gray' }}>Shipping Address</Text>
            <Text>
              {order.shippingAddress.firstName} {order.shippingAddress.lastName}
            </Text>
            <Text>{order.shippingAddress.streetName}</Text>
            <Text>{order.shippingAddress.postalCode}</Text>
            <Text>{order.shippingAddress.city}</Text>
            <Text>{order.shippingAddress.country}</Text>
          </View>
          <View style={styles.rightElement}>
            <Text style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 5, color: 'gray' }}>Billing Address</Text>
            <Text>
              {order.billingAddress.firstName} {order.billingAddress.lastName}
            </Text>
            <Text>{order.billingAddress.streetName}</Text>
            <Text>{order.billingAddress.postalCode}</Text>
            <Text>{order.billingAddress.city}</Text>
            <Text>{order.billingAddress.country}</Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: '40%' }]}>
              <Text style={styles.tableCell}>Product #</Text>
            </View>
            <View style={[styles.tableCol, { width: '20%' }]}>
              <Text style={styles.tableCell}>Description</Text>
            </View>
            <View style={[styles.tableCol, { width: '10%' }]}>
              <Text style={styles.tableCell}>Quantity</Text>
            </View>
            <View style={[styles.tableCol, { width: '15%' }]}>
              <Text style={styles.tableCell}>Unit Price</Text>
            </View>
            <View style={[styles.tableCol, { width: '15%' }]}>
              <Text style={styles.tableCell}>Total {order?.discountCodes.length > 0 ? '(discount applied)' : ''}</Text>
            </View>
          </View>
          {order.lineItems.map((lineItem) => (
            <View style={styles.tableRow} key={lineItem.lineItemId}>
              <View style={[styles.tableCol, { width: '40%' }]}>
                <Text style={styles.tableCell}>{lineItem.variant.sku}</Text>
              </View>
              <View style={[styles.tableCol, { width: '20%' }]}>
                <Text style={styles.tableCell}>{lineItem.name} </Text>
              </View>
              <View style={[styles.tableCol, { width: '10%' }]}>
                <Text style={styles.tableCell}>{lineItem.count}</Text>
              </View>
              <View style={[styles.tableCol, { width: '15%' }]}>
                <Text style={styles.tableCell}>
                  {lineItem.price.centAmount / 100} {lineItem.price.currencyCode}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: '15%' }]}>
                <Text style={styles.tableCell}>
                  {lineItem.totalPrice.centAmount / 100} {lineItem.price.currencyCode}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20, marginRight: 30 }}
        >
          <View>
            <Text style={{ fontSize: 10 }}>
              Order Subtotal: {order.taxedPrice.totalNet.centAmount / 100} {order.taxedPrice.totalNet.currencyCode}
            </Text>
            <Text style={{ fontSize: 10 }}>
              Tax {order.taxedPrice.taxPortions[0].name}: {order.taxedPrice.taxPortions[0].amount.centAmount / 100}{' '}
              {order.taxedPrice.taxPortions[0].amount.currencyCode}
            </Text>
            <Text style={{ fontSize: 10, fontWeight: 800 }}>
              Shipping cost - {order.shippingInfo.shippingMethodName}: {order.shippingInfo.price.centAmount / 100}{' '}
              {order.shippingInfo.price.currencyCode}
            </Text>
            <Text
              style={{ fontSize: 10, fontWeight: 800, marginTop: 10, paddingTop: 10, borderTop: '1px solid black' }}
            >
              Order Gross Total: {order.sum.centAmount / 100} {order.sum.currencyCode}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default InvoiceDocument;
