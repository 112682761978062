import AdyenCheckout from 'components/tailgrids-ui/adyen-checkout';
import React, { useEffect, useState } from 'react';
// import AdyenCheckout from 'components/commercetools-ui/adyen-checkout';

const CheckoutTastic = ({ data }) => {
  const [ctOptions, setCtOptions] = useState([]);

  const commercetoolsCheckout = (data) => {
    const commercetoolsCheckout: string[] = [];

    if (data.commercetoolsCheckout_de_DE) {
      commercetoolsCheckout?.push('de-DE');
    }
    if (data.commercetoolsCheckout_en_DE) {
      commercetoolsCheckout?.push('en-DE');
    }
    if (data.commercetoolsCheckout_fr_FR) {
      commercetoolsCheckout?.push('fr-FR');
    }
    if (data.commercetoolsCheckout_en_GB) {
      commercetoolsCheckout?.push('en-GB');
    }
    return commercetoolsCheckout;
  };

  useEffect(() => {
    setCtOptions(commercetoolsCheckout(data));
  }, []);

  return (
    <AdyenCheckout
      termsLink={data.termsLink}
      cancellationLink={data.cancellationLink}
      privacyLink={data.privacyLink}
      commercetoolsCheckout={ctOptions}
    />
  );
};

export default CheckoutTastic;
