import { fetchApiHub } from 'frontastic/lib/fetch-api-hub';

export const productCategories = async () => {
  // const res = await fetchApiHub('/action/productCategories/test', { method: 'POST' }, payload);
  const res = await fetchApiHub('/action/cat/test');
  console.log('res', res);
  return res;
};

export const localizedProductCategories = async (slug: string, locale: string) => {
  const res = await fetchApiHub('/action/cat/localizedCategory', { method: 'POST' }, { slug, locale });
  // console.log('res2', res);
  return res;
};
