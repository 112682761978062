import { useState, Fragment, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import { Address } from '@Types/account/Address';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { ProjectSettings } from '@Types/ProjectSettings';
import { CustomCheckbox, regionToCurrency, regionToZone } from 'components/tailgrids-ui/adyen-checkout/panels/address';
import { countryToCurrency } from 'helpers/countryCurrencyMapper';
import { useFormat } from 'helpers/hooks/useFormat';
import useI18n from 'helpers/hooks/useI18n';
import { getTaxedCountries } from 'helpers/utils/getTaxedCountries';
import { useAccount, useCart, useDarkMode } from 'frontastic';

export interface CreateAddressProps {
  open?: boolean;
  onClose?: () => void;
  addressId?: string;
}

const CreateAddress: React.FC<CreateAddressProps> = ({ open, onClose }) => {
  //Darkmode
  const { mode, theme } = useDarkMode();

  //i18n messages
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatCheckMessage } = useFormat({ name: 'checkout' });

  //account data
  const { addAddress, account } = useAccount();

  //I18n info
  const { country } = useI18n();

  //new address data
  const [data, setData] = useState({
    country,
    isDefaultBillingAddress: account.addresses.length == 0,
    isDefaultShippingAddress: account.addresses.length == 0,
  } as Address);
  const [availableCountryOptions, setAvailableCountryOptions] = useState<string[]>(null);
  const [projectSettings, setProjectSettings] = useState<ProjectSettings>(null);
  const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>(null);
  const [countryMapper, setCountryMapper] = useState<string | undefined>();
  const { getProjectSettings, getShippingMethods } = useCart();
  const router = useRouter();

  useEffect(() => {
    getShippingMethods().then((data) => {
      setShippingMethods(data);
    });

    getProjectSettings().then((data) => {
      setProjectSettings(data);
    });
  }, []);

  useEffect(() => {
    if (projectSettings) {
      const totalCountries = getTaxedCountries(shippingMethods, projectSettings?.countries);

      setAvailableCountryOptions(totalCountries);
      if (
        shippingMethods &&
        shippingMethods?.find((method) => method.name === `Standard ${regionToZone[router.locale.split('-')[1]]}`)
          ?.billingCountry
      ) {
        setCountryMapper(
          JSON.parse(
            shippingMethods?.find((method) => method.name === `Standard ${regionToZone[router.locale.split('-')[1]]}`)
              .billingCountry,
          ),
        );
      }
    }
  }, [shippingMethods, projectSettings]);

  //input change handler
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  //checkbox change handler
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.id]: e.target.checked });
  };

  useEffect(() => {
    if (data.country !== 'DE') {
      const { packstationNumber, ...dataWithoutPackstation } = data;
      setData(dataWithoutPackstation);
    }
  }, [data.country]);

  //submission handler
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    try {
      addAddress({ ...data, email: account.email });
    } catch (err) {
    } finally {
      onClose();
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className={`${mode} ${theme} default fixed inset-0 z-10 overflow-y-auto `} onClose={onClose}>
        <>
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-left sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="absolute inset-0" onClick={onClose}>
                {/* eslint-disable */}
                <div
                  className="absolute top-2/3 left-1/2 h-[90vh] w-[90%] max-w-[800px] -translate-x-1/2 -translate-y-1/2 overflow-auto bg-white py-16 px-4 dark:bg-primary-200 sm:px-6 lg:py-24 lg:px-8"
                  onClick={(e) => e.stopPropagation()}
                >
                  {/* eslint-enable */}
                  <div className="relative mx-auto max-w-xl">
                    <div className="text-center">
                      <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-light-100 sm:text-4xl">
                        {formatAccountMessage({ id: 'address.create.headline', defaultMessage: 'New Address' })}
                      </h2>
                      <p className="mt-4 text-lg leading-6 text-gray-400">
                        {formatAccountMessage({
                          id: 'address.create.desc',
                          defaultMessage: 'Add a new address here to associate it with your account',
                        })}
                      </p>
                    </div>
                    <div className="mt-12">
                      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                        <div className="flex w-full flex-col justify-between gap-4 sm:flex-row">
                          <div className="w-full sm:w-[48%]">
                            <label htmlFor="first-name" className="mb-2 block text-base font-medium text-[#808080]">
                              {formatMessage({ id: 'firstName', defaultMessage: 'First Name' })} *
                            </label>
                            <div className="mt-1">
                              <input
                                required
                                type="text"
                                name="firstName"
                                id="first-name"
                                autoComplete="given-name"
                                className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                                onChange={handleChange}
                                placeholder={formatCheckMessage({
                                  id: 'placeholder.firstName',
                                  defaultMessage: 'First Name',
                                })}
                              />
                            </div>
                          </div>

                          <div className="w-full sm:w-[48%]">
                            <label htmlFor="last-name" className="mb-2 block text-base font-medium text-[#808080]">
                              {formatMessage({ id: 'lastName', defaultMessage: 'Last Name' })} *
                            </label>
                            <div className="mt-1">
                              <input
                                required
                                type="text"
                                name="lastName"
                                id="last-name"
                                autoComplete="family-name"
                                className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                                onChange={handleChange}
                                placeholder={formatCheckMessage({
                                  id: 'placeholder.lastName',
                                  defaultMessage: 'Last Name',
                                })}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-2">
                          <label htmlFor="street-name" className="mb-2 block text-base font-medium text-[#808080]">
                            {formatMessage({ id: 'street.nameAndNumber', defaultMessage: 'Street name and number' })} *
                          </label>
                          <div className="mt-1">
                            <input
                              required
                              id="street-name"
                              name="streetName"
                              type="text"
                              autoComplete="address-line1"
                              className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                              onChange={handleChange}
                              placeholder={formatCheckMessage({
                                id: 'placeholder.street',
                                defaultMessage: 'Enter street and number',
                              })}
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label htmlFor="phone" className="mb-2 block text-base font-medium text-[#808080]">
                            {formatMessage({ id: 'phone', defaultMessage: 'Phone' })}
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="phone"
                              id="phone"
                              autoComplete="tel"
                              className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                              onChange={handleChange}
                              placeholder={formatCheckMessage({
                                id: 'placeholder.phone',
                                defaultMessage: 'Enter your phone',
                              })}
                            />
                          </div>
                        </div>
                        <div className="flex w-full flex-col justify-between gap-4 sm:flex-row">
                          <div className="w-full sm:w-[48%]">
                            <label htmlFor="postal-code" className="mb-2 block text-base font-medium text-[#808080]">
                              {formatMessage({ id: 'zipCode', defaultMessage: 'Postal Code' })} *
                            </label>
                            <div className="mt-1">
                              <input
                                required
                                type="text"
                                name="postalCode"
                                id="postal-code"
                                autoComplete="postal-code"
                                className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                                onChange={handleChange}
                                placeholder={formatCheckMessage({
                                  id: 'placeholder.postCode',
                                  defaultMessage: 'Enter Post Code',
                                })}
                              />
                            </div>
                          </div>
                          <div className="w-full sm:w-[48%]">
                            <label htmlFor="city" className="mb-2 block text-base font-medium text-[#808080]">
                              {formatMessage({ id: 'city', defaultMessage: 'City' })} *
                            </label>
                            <div className="mt-1">
                              <input
                                required
                                type="text"
                                name="city"
                                id="city"
                                autoComplete="city"
                                className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                                onChange={handleChange}
                                placeholder={formatCheckMessage({
                                  id: 'placeholder.city',
                                  defaultMessage: 'Enter city',
                                })}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="country">
                            <span>{formatMessage({ id: 'country', defaultMessage: 'Country' })}</span> *
                          </label>
                          <div className="mt-1 w-full">
                            <select
                              required
                              name="country"
                              id="country"
                              className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                              value={data.country}
                              onChange={handleChange}
                            >
                              {availableCountryOptions?.map((code, i) => (
                                <option key={i} value={code}>
                                  {formatMessage({ id: `country.${code}` })}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {data.country === 'DE' && (
                          <div className="sm:col-span-2">
                            <label htmlFor="packstation" className="mb-2 block text-base font-medium text-[#808080]">
                              {formatMessage({ id: 'packstation', defaultMessage: 'Packstation' })}
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="packstationNumber"
                                id="packstation"
                                disabled={data.country !== 'DE'}
                                className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                                onChange={handleChange}
                                value={data.packstationNumber}
                                placeholder={formatCheckMessage({
                                  id: 'placeholder.packstation',
                                  defaultMessage: 'Enter packstation number',
                                })}
                              />
                            </div>
                          </div>
                        )}
                        {account.addresses.length > 0 && (
                          <div className="mt-4">
                            <legend className="sr-only">
                              {formatAccountMessage({
                                id: 'address.setDefault.delivery',
                                defaultMessage: 'Set as default delivery address',
                              })}
                            </legend>
                            <CustomCheckbox
                              labelTitle={formatAccountMessage({
                                id: 'address.setDefault.delivery',
                                defaultMessage: 'Set as default delivery address',
                              })}
                              id="isDefaultShippingAddress"
                              checked={data.isDefaultShippingAddress}
                              onChange={handleCheckboxChange}
                            />
                            <legend className="sr-only">
                              {formatAccountMessage({
                                id: 'address.setDefault.billing',
                                defaultMessage: 'Set as default billing address',
                              })}
                            </legend>
                            <CustomCheckbox
                              labelTitle={formatAccountMessage({
                                id: 'address.setDefault.billing',
                                defaultMessage: 'Set as default billing address',
                              })}
                              id="isDefaultBillingAddress"
                              checked={data.isDefaultBillingAddress}
                              onChange={handleCheckboxChange}
                            />
                          </div>
                        )}
                        <div className="text-center sm:col-span-2">
                          <p className="mt-4 text-sm leading-6 text-gray-400">
                            {formatAccountMessage({
                              id: 'address.create.safety',
                              defaultMessage:
                                'All the personal associated to your account is safely stored in our database, and we will not share it with third parties.',
                            })}
                          </p>
                        </div>
                        <div className="mt-4 flex gap-4 sm:col-span-2 sm:gap-8">
                          <button
                            type="submit"
                            className="flex w-full justify-center rounded border border-transparent bg-primary py-3 px-8 text-sm font-medium text-white transition-colors duration-200 ease-out hover:bg-[#33B8A1] focus:outline-none disabled:bg-[#808080]"
                          >
                            {formatMessage({ id: 'save', defaultMessage: 'Save' })}
                          </button>
                          <button
                            type="button"
                            className="flex w-full justify-center rounded border border-transparent bg-primary py-3 px-8 text-sm font-medium text-white transition-colors duration-200 ease-out hover:bg-[#33B8A1] focus:outline-none disabled:bg-[#808080]"
                            onClick={onClose}
                          >
                            {formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateAddress;
