import React, { useEffect, useState } from 'react';
import { DataTestId } from '@Types/playwright';
import { useSearchBox } from 'react-instantsearch';
import { SearchInput } from 'components/tailgrids-ui/products/product-list/SearchInput';

type SearchInputProps = Partial<React.ComponentProps<'input'>> & DataTestId;

export const TypesenseSearchInput = (props: SearchInputProps) => {
  const { className = '', ...rest } = props;
  const { query, refine } = useSearchBox();
  const [value, setValue] = useState(query);

  useEffect(() => {
    refine(value);
  }, [refine, value]);

  return <SearchInput className={className} value={value} onChange={(e) => setValue(e.target.value)} {...rest} />;
};
