import React, { FC } from 'react';
import { DataTestId } from '@Types/playwright';
import { useFormat } from 'helpers/hooks/useFormat';
import { Collapse } from './Collapse';
import { SingleCheckBox } from './SingleCheckBox';

type CheckBoxFilterProps = {
  label?: string;
  defaultLabel?: string;
  items?: {
    onChange: (label: string) => void;
    label: string;
    value: string;
    color?: string;
    selected: boolean;
  }[];
  canToggleShowMore?: boolean;
  isShowingMore?: boolean;
  toggleShowMore?: () => void;
  open?: boolean;
} & DataTestId;

export const CheckBoxFilter: FC<CheckBoxFilterProps> = (props) => {
  const { formatMessage } = useFormat({ name: 'product' });

  return (
    <Collapse open={props.open} title={formatMessage({ id: props.label, defaultMessage: props.defaultLabel })}>
      <div className="mt-1 flex flex-col items-start gap-1" data-testid={props['data-testid']}>
        {props.items.map((item) => (
          <SingleCheckBox {...item} filterLabel={props.label} value={item.value} key={item.label} />
        ))}
        {props.children}
      </div>
    </Collapse>
  );
};
