import React from 'react';
import Tile from 'components/commercetools-ui/content/tile';
import Hero from 'components/tailgrids-ui/hero';
import { FullPageWidthWrapper } from 'components/tailgrids-ui/full-bleed';
import HeroVideo from 'components/tailgrids-ui/hero/hero-video';

const VideoTileTastic = ({ data }) => {
  return (
    <FullPageWidthWrapper>
      <div className="fixed-screen-width lg:relative-width">
        <HeroVideo
          video={data.video}
          title={data.title}
          titleColor={data.titleColor}
          subtitle={data.subtitle}
          text={data.text}
          textColor={data.textColor}
          ctaLabel={data.ctaLabel}
          ctaReference={data.ctaReference}
        />
      </div>
    </FullPageWidthWrapper>
  );
};

export default VideoTileTastic;
