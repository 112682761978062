import React from 'react';
import { Reference, ReferenceLink } from 'helpers/reference';
import { MediaItemWithMods } from 'frontastic';

export interface TileProps {
  video: MediaItemWithMods;
  title: string;
  titleColor?: string;
  subtitle: string;
  text: string;
  textColor?: string;
  ctaLabel?: string;
  ctaReference?: Reference;
}

const HeroVideo = (props: TileProps) => {
  const regExp = /^#[0-9A-F]{6}$/i;
  return (
    <section>
      <div className="relative h-[100vh] w-full">
        <video
          src={props?.video?.media?.file}
          autoPlay
          loop
          muted
          playsInline
          className="h-full w-full object-cover object-center"
        />
        <div
          className={`absolute top-1/2 left-1/2 flex h-full w-full flex-col items-start justify-center px-4 sm:px-10 lg:max-w-[1280px] lg:px-4`}
          style={{
            transform: 'translate(-50%, -50%)',
            color: regExp.test(props.textColor.trim()) ? props.textColor.trim() : 'white',
          }}
        >
          <div className="flex flex-col gap-9 sm:gap-10">
            <div className="flex max-w-[570px] flex-col gap-4 sm:gap-6">
              <h1 className="w-full text-heading-1 font-bold !leading-tight">{props.title}</h1>
              <h4 className="text-heading-4 font-medium">{props.subtitle}</h4>
              <p className="text-body-color text-base font-medium">{props.text}</p>
            </div>
            <ReferenceLink
              target={props.ctaReference}
              className="inline-flex w-fit items-center justify-center rounded bg-primary py-3 px-8 text-center text-base font-semibold text-white hover:bg-opacity-90"
            >
              {props.ctaLabel}
            </ReferenceLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroVideo;
