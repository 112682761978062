import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const getEnvironmentVariable = (environmentVariable: string, name: string): string => {
  if (!environmentVariable) console.error("Couldn't find environment variable:", name);
  return environmentVariable;
};

const typesensePort = getEnvironmentVariable(
  publicRuntimeConfig?.NEXT_PUBLIC_TYPESENSE_PORT,
  'NEXT_PUBLIC_TYPESENSE_PORT',
);

export const publicConfig = {
  typesenseProtocol:
    getEnvironmentVariable(publicRuntimeConfig?.NEXT_PUBLIC_TYPESENSE_PROTOCOL, 'NEXT_PUBLIC_TYPESENSE_PROTOCOL') ??
    'https',
  typesenseHost:
    getEnvironmentVariable(publicRuntimeConfig?.NEXT_PUBLIC_TYPESENSE_HOST, 'NEXT_PUBLIC_TYPESENSE_HOST') ??
    'commercetools-typesense-preprod.zyres.net',
  typesensePort: (typesensePort ? +typesensePort : 443) as number,
  typesenseSearchOnlyKey:
    getEnvironmentVariable(publicRuntimeConfig?.NEXT_PUBLIC_TYPESENSE_API_KEY, 'NEXT_PUBLIC_TYPESENSE_API_KEY') ??
    'SXQv6XGIKzaPHebL72yOCKVT5PsS8mVC',
  typesenseLogLevel:
    getEnvironmentVariable(publicRuntimeConfig?.NEXT_PUBLIC_TYPESENSE_LOG_LEVEL, 'NEXT_PUBLIC_TYPESENSE_LOG_LEVEL') ??
    '',
  siteUrl: getEnvironmentVariable(publicRuntimeConfig?.NEXT_PUBLIC_SITE_URL, 'NEXT_PUBLIC_SITE_URL') ?? '',
};
