import { S3SingleProductFragment } from 'generated/strapiTypes';
import React from 'react';
import SingleProduct from './singleProduct';
import { useAccount } from 'frontastic';

export const SingleProductAdapter = (props: S3SingleProductFragment) => {
  const { account } = useAccount();

  if (props?.customerGroups?.length > 0 && !props?.customerGroups?.includes(account?.customerGroup?.id)) return null;

  return <SingleProduct product={props.product ?? ''} />;
};
