import React from 'react';
import { useRouter } from 'next/router';
import { CustomInstantSearch, TypesenseProps } from 'features/typesense/components';
import { InstantSearchFallback } from 'features/typesense/components/InstantSearchFallback';

const TypesenseSearch = ({ data }: TypesenseProps) => {
  const router = useRouter();
  const locale = router.locale;
  const selectedCategory = router.asPath.split('?')[0].substring(1);
  if (data.fallback === false) {
    return <CustomInstantSearch data={data} selectedCategory={selectedCategory} locale={locale} />;
  }
  return <InstantSearchFallback category={selectedCategory} />;
};

export default TypesenseSearch;
