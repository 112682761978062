import React, { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import validator from 'validator';
import { useRouter } from 'next/router';
import { CustomCheckbox } from '../adyen-checkout/panels/address';
import { useFormat } from 'helpers/hooks/useFormat';
import toast from 'react-hot-toast';
import Image from 'frontastic/lib/image';

export type FormProps = {
  formName: string;
  id: string;
  submitText: string;
  fields: Field[];
  pageId: string;
};

export type Field = {
  inputType: InputType;
  displayName: string;
  placeholder: string;
  required?: boolean;
};

const salutations: Record<string, string[]> = {
  'en-GB': ['Mr', 'Mrs', 'Diverse'],
  'en-DE': ['Mr', 'Mrs', 'Diverse'],
  'de-DE': ['Herr', 'Frau', 'Divers'],
  'fr-FR': ['Monsieur', 'Madame', 'Divers'],
};

export type InputType = 'text' | 'date' | 'checkbox' | 'email' | 'number' | 'tel' | 'multiline_text' | 'salutation';

export const ContactForm = ({ id, formName, submitText, fields, pageId }: FormProps) => {
  const [validationSchema, setValidationSchema] = useState<any>(
    z.object({
      firstName: z.string().min(1, { message: 'First name is required' }),
    }),
  );
  const { locale } = useRouter();
  const { formatMessage } = useFormat({ name: 'common' });
  const [response, setResponse] = useState(false);
  const [loading, setLoading] = useState(false);

  const validationMapper: Record<string, any> = {
    text: z.string().min(1, {
      message: formatMessage({ id: 'required.field', defaultMessage: 'This field is required' }),
    }),
    number: z.string().min(6, {
      message: formatMessage({ id: 'required.field', defaultMessage: 'This field is required' }),
    }),
    tel: z.string().refine(validator.isMobilePhone, {
      message: formatMessage({ id: 'required.valid.phone', defaultMessage: 'Valid phone number is required' }),
    }),
    email: z
      .string()
      .min(1, {
        message: formatMessage({ id: 'required.email', defaultMessage: 'Email is required' }),
      })
      .email({
        message: formatMessage({ id: 'required.valid.email', defaultMessage: 'Valid email is required' }),
      }),
    multiline_text: z.string().min(10, {
      message: formatMessage({ id: 'required.minimum', defaultMessage: 'Minimum 10 characters' }),
    }),
    checkbox: z.literal(true, {
      errorMap: () => ({
        message: formatMessage({ id: 'confirm.mandatory', defaultMessage: 'Please confirm the mandatory information' }),
      }),
    }),
    date: z.coerce.date({
      errorMap: () => ({
        message: formatMessage({ id: 'required.valid.date', defaultMessage: 'Valid date is required' }),
      }),
    }),
    salutation: z.string().min(1, {
      message: formatMessage({ id: 'required.field', defaultMessage: 'This field is required' }),
    }),
  };

  const optionalValidationMapper: Record<string, any> = {
    text: z.optional(z.string()),
    number: z.optional(z.string()),
    tel: z.string(),
    email: z.string(),
    multiline_text: z.string(),
    checkbox: z.literal(true, {
      errorMap: () => ({
        message: formatMessage({ id: 'confirm.mandatory', defaultMessage: 'Please confirm the mandatory information' }),
      }),
    }),
    date: z.optional(z.coerce.date()),
    salutation: z.optional(z.string()),
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<z.infer<typeof validationSchema>>({
    resolver: zodResolver(validationSchema),
  });

  useEffect(() => {
    const newSchema = getSchema(fields);
    setValidationSchema(z.object(newSchema));
  }, [fields, locale]);

  const generateInputName = useCallback((name: string): string => {
    return name.split(' ').join('').trim().toLowerCase();
  }, []);

  const getSchema = (fields: Field[]) => {
    const newSchema = fields.reduce(
      (acc, cur) => ({
        ...acc,
        [generateInputName(cur.displayName)]: cur.required
          ? validationMapper[cur.inputType]
          : optionalValidationMapper[cur.inputType],
      }),
      {},
    );
    return newSchema;
  };

  const onSubmit: SubmitHandler<z.infer<typeof validationSchema>> = async (data) => {
    const { email } = data;
    setResponse(false);
    setLoading(true);
    try {
      const res = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          message: data,
          id,
          pageId,
          locale,
        }),
      });
      const result = await res.json();
      if (result.message === 'email sent') {
        toast.success(formatMessage({ id: 'form.success.message', defaultMessage: 'Message sent!' }), {
          style: {
            border: '1px solid #34D399',
            padding: '16px',
            color: '#242424',
          },
          iconTheme: {
            primary: '#34D399',
            secondary: '#FFFFFF',
          },
        });
        reset();
        reset({
          [`${Object.keys(data)[Object.keys(data).length - 1]}`]: true,
        });
      } else {
        toast.error(formatMessage({ id: 'form.error.message', defaultMessage: 'Message not sent!' }), {
          style: {
            border: '1px solid #F87171',
            padding: '16px',
            color: '#B45454',
          },
          iconTheme: {
            primary: '#F87171',
            secondary: '#FFFFFF',
          },
        });
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      toast.error(formatMessage({ id: 'form.error.message', defaultMessage: 'Message not sent!' }), {
        style: {
          border: '1px solid #F87171',
          padding: '16px',
          color: '#B45454',
        },
        iconTheme: {
          primary: '#F87171',
          secondary: '#FFFFFF',
        },
      });
    }
  };

  return (
    <section className="my-12 mx-auto flex max-w-[1280px] flex-col flex-wrap justify-between gap-8 px-4 sm:px-10 md:flex-row md:gap-0 lg:px-4">
      <div className="h-full w-full basis-[30%] rounded">
        <Image
          src="/images/form-image.jpg"
          layout="fill"
          className="w-full rounded object-cover"
          alt="Register Image"
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full basis-2/3">
        <div className="flex w-full flex-col gap-6 rounded bg-[#F6F9FF] p-8">
          <h2 className="text-start my-8 text-2xl font-semibold tracking-tight text-[#242424]">{formName}</h2>
          {fields?.map((field, idx) => {
            const inputName = generateInputName(field.displayName);
            return (
              <div key={idx}>
                {field.inputType !== 'checkbox' && (
                  <label className="mb-2 block w-full text-base font-medium text-[#242424]">
                    {field?.required ? `${field.displayName}*` : field.displayName}
                  </label>
                )}
                {field.inputType === 'multiline_text' ? (
                  <>
                    <textarea
                      className="w-full appearance-none rounded border border-gray-300 bg-[#F6F9FF] p-4 leading-tight text-gray-700 focus:border-primary focus:bg-white focus:bg-opacity-50 focus:outline-none focus:ring-primary"
                      placeholder={field.placeholder}
                      rows={6}
                      {...register(inputName)}
                    ></textarea>
                    {errors[inputName] && (
                      <p className="mt-2 text-sm font-light text-red-500">{errors[inputName].message}</p>
                    )}
                  </>
                ) : field.inputType === 'salutation' ? (
                  <div className="mb-4 md:w-[50%]">
                    <select
                      className="mt-2 w-full appearance-none rounded border border-gray-300 bg-[#F6F9FF] p-4 leading-tight text-gray-700 focus:border-primary focus:bg-white focus:bg-opacity-50 focus:outline-none focus:ring-primary"
                      {...register(inputName)}
                    >
                      {salutations[locale].map((item, idx) => (
                        <option key={idx} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    {errors[inputName] && (
                      <p className="mt-2 text-sm font-light text-red-500">{errors[inputName].message}</p>
                    )}
                  </div>
                ) : field.inputType === 'checkbox' ? (
                  <>
                    <Controller
                      control={control}
                      name={inputName}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <CustomCheckbox onChange={onChange} checked={value} id={name} labelTitle={field.placeholder} />
                      )}
                    />
                    {errors[inputName] && (
                      <p className="mt-2 text-sm font-light text-red-500">{errors[inputName].message}</p>
                    )}
                  </>
                ) : (
                  <div className="flex w-full flex-col gap-6">
                    <div className="w-full">
                      <input
                        type={field?.inputType}
                        className="w-full appearance-none rounded border border-gray-300 bg-[#F6F9FF] p-4 leading-tight text-gray-700 focus:border-primary focus:bg-white focus:bg-opacity-50 focus:outline-none focus:ring-primary"
                        placeholder={field?.placeholder ?? ''}
                        // required={field?.required ?? false}
                        {...register(inputName)}
                      />
                      {errors[inputName] && (
                        <p className="mt-2 text-sm font-light text-red-500">{errors[inputName].message}</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          <button
            type="submit"
            className="inline-flex w-fit items-center justify-center rounded bg-primary py-3 px-8 text-center text-base font-semibold text-white hover:bg-secondary disabled:cursor-not-allowed disabled:bg-[#EDEDED]"
            disabled={loading}
          >
            {submitText}
          </button>
        </div>
      </form>
    </section>
  );
};
