import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { Order } from '@Types/cart/Order';

type Props = {
  order: Order;
  delivery?: any;
  customerId?: string;
};

const styles = StyleSheet.create({
  table: {
    // display: 'table',
    width: '90%',
    margin: '0 auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    tablelayout: 'fixed',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    // wordBreak: 'break-word',
  },
  tableCell: {
    // margin: 'auto',
    marginTop: 5,
    fontSize: 10,
    wordWrap: 'break-all',
    overflowWrap: 'break-all',
    whiteSpace: 'normal',
  },
  addressContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: 10,
    marginBottom: 20,
    marginTop: 20,
  },
  rightElement: {
    marginRight: 30,
  },
  leftElement: {
    marginLeft: 30,
  },
});

export const DeliveryNote = ({ order, delivery, customerId }: Props) => {
  return (
    <Document>
      <Page>
        <View style={styles.addressContainer}>
          <View style={styles.leftElement}>
            <View>
              <Text style={{ fontSize: 20 }}>Delivery Note</Text>
            </View>
            <View style={{ marginTop: 20 }}>
              <Text>Sunrise Ecommerce GmbH</Text>
              <Text>Boxhagener Str.</Text>
              <Text>20149 Hamburg</Text>
            </View>
          </View>
          <View style={[styles.rightElement, { border: '2px solid gray', padding: 5 }]}>
            <Text>Order Date: {order.createdAt}</Text>
            <Text>Order Number: {order.orderNumber}</Text>
            {customerId && <Text>Customer Number: {customerId}</Text>}
            <Text>Delivery Method: {order.shippingInfo.shippingMethodName}</Text>
          </View>
        </View>
        <View style={styles.addressContainer}>
          <View style={styles.leftElement}>
            <Text style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 5, color: 'gray' }}>Shipping Address</Text>
            <Text>
              {order.shippingAddress.firstName} {order.shippingAddress.lastName}
            </Text>
            <Text>{order.shippingAddress.streetName}</Text>
            <Text>{order.shippingAddress.postalCode}</Text>
            <Text>{order.shippingAddress.city}</Text>
            <Text>{order.shippingAddress.country}</Text>
          </View>
          <View style={styles.rightElement}>
            <Text style={{ fontSize: 10, fontWeight: 'bold', marginBottom: 5, color: 'gray' }}>Billing Address</Text>
            <Text>
              {order.billingAddress.firstName} {order.billingAddress.lastName}
            </Text>
            <Text>{order.billingAddress.streetName}</Text>
            <Text>{order.billingAddress.postalCode}</Text>
            <Text>{order.billingAddress.city}</Text>
            <Text>{order.billingAddress.country}</Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: '40%' }]}>
              <Text style={styles.tableCell}>Product #</Text>
            </View>
            <View style={[styles.tableCol, { width: '40%' }]}>
              <Text style={styles.tableCell}>Description</Text>
            </View>
            <View style={[styles.tableCol, { width: '10%' }]}>
              <Text style={styles.tableCell}>Ordered</Text>
            </View>
            <View style={[styles.tableCol, { width: '10%' }]}>
              <Text style={styles.tableCell}>Shipped</Text>
            </View>
          </View>
          {delivery.items.map((item) => (
            <View style={styles.tableRow} key={item.lineItemId}>
              <View style={[styles.tableCol, { width: '40%' }]}>
                <Text style={styles.tableCell}>
                  {' '}
                  {order.lineItems.find((lineItem) => lineItem.lineItemId === item.id).variant.sku}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: '40%' }]}>
                <Text style={styles.tableCell}>
                  {order.lineItems.find((lineItem) => lineItem.lineItemId === item.id).name}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: '10%' }]}>
                <Text style={styles.tableCell}>
                  {order.lineItems.find((lineItem) => lineItem.lineItemId === item.id).count}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: '10%' }]}>
                <Text style={styles.tableCell}>{item.quantity}</Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};
