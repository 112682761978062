import React from 'react';
import { Media } from '../CZ11Cta/CZ11Cta';
import { strapiConfig } from 'helpers/strapiConfig';
import { CustomImage } from 'lib/customImage';
import parse from 'html-react-parser';
import Image from 'frontastic/lib/image';
import { ReferenceLink } from 'helpers/reference';
import useTransformImageSrc from 'helpers/hooks/useTransformImageSrc';

type CZ13ImageWithText = {
  imageTextItem: ImageTextItem[];
};

export type ImageTextItem = {
  alignment: 'Left' | 'Right';
  title: string;
  subtitle: string;
  text: string;
  image: Media;
  imageAlt: string;
  buttonText: string;
  buttonUrl: string;
};

function CZ13ImageWithText({ imageTextItem }: CZ13ImageWithText) {
  return (
    <section>
      <div className="">
        <div className="group mb-20 flex flex-col items-center justify-center">
          {imageTextItem.map((item, idx) => (
            <div
              key={idx}
              className={`flex w-full ${
                item.alignment === 'Left' ? 'flex-col-reverse md:flex-row-reverse' : 'flex-col md:flex-row'
              }`}
            >
              <div
                className={`basis-12/12 flex md:basis-5/12 ${
                  item.alignment === 'Left' ? 'flex-row' : 'flex-row-reverse'
                }`}
              >
                <div
                  className={`basis-12/12 flex flex-col py-20 md:basis-7/12 ${
                    item.alignment === 'Left' ? 'px-4 sm:px-10 md:pl-10 md:pr-0' : 'px-4 sm:px-10 md:pr-10 md:pl-0'
                  }`}
                >
                  <h2 className="mb-2 text-4xl font-semibold !leading-tight text-black">{item.title}</h2>
                  <h4 className="mb-6 text-2xl font-medium !leading-tight text-black">{item.subtitle}</h4>

                  <div className="text-body-color text-lg font-medium">{parse(item.text)}</div>
                  {item.buttonText && (
                    <ReferenceLink
                      target={{
                        link: item.buttonUrl,
                        target: item.buttonUrl,
                        type: 'link',
                        openInNewWindow: false,
                      }}
                      className="disabled: mt-10 w-fit cursor-pointer rounded bg-primary py-2 px-4 text-white hover:bg-[#33B8A1] disabled:cursor-auto disabled:bg-[#EDEDED] disabled:text-[#808080]"
                    >
                      <span className="text-white">{item.buttonText}</span>
                    </ReferenceLink>
                  )}
                </div>
              </div>
              <div className="basis-7/12">
                <ItemImage imgSrc={item.image.data.attributes.url} imgAlt={item.imageAlt} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

const ItemImage = ({ imgSrc, imgAlt }: { imgSrc: string; imgAlt?: string }) => {
  const src = useTransformImageSrc(`${strapiConfig.url}${imgSrc}`);
  return <Image src={src} layout="fill" className="h-full min-h-[470px] w-full object-cover" alt={imgAlt ?? ''} />;
};

export default CZ13ImageWithText;
