import React from 'react';

type GridIconProps = {
  className?: string;
  color?: string;
};

export const GridIcon = ({ className, color = '#242424' }: GridIconProps) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5" y="5" width="9" height="9" rx="1" stroke={color} strokeWidth="2" />
      <rect x="18" y="5" width="9" height="9" rx="1" stroke={color} strokeWidth="2" />
      <rect x="5" y="18" width="9" height="9" rx="1" stroke={color} strokeWidth="2" />
      <rect x="18" y="18" width="9" height="9" rx="1" stroke={color} strokeWidth="2" />
    </svg>
  );
};
