import React, { useState } from 'react';
import parse from 'html-react-parser';

export type CZ10AccordionProps = {
  title: string;
  accordionItems: AccordionItem[];
};

export type AccordionItem = {
  title: string;
  text: string;
};

const CZ10Accordion = ({ title, accordionItems }: CZ10AccordionProps) => {
  return (
    <section className="container mx-auto max-w-[1280px] py-10 px-4 font-urbanist sm:px-10 lg:px-4">
      {title && <h2 className="mb-10 text-4xl font-semibold !leading-tight text-black">{title}</h2>}
      <div className="flex flex-col gap-4">
        {accordionItems.map((item, idx) => (
          <AccordionEntry key={idx} title={item.title} text={item.text} />
        ))}
      </div>
    </section>
  );
};

const AccordionEntry = ({ title, text }: AccordionItem) => {
  const [active, setActive] = useState(false);
  const handleToggle = () => {
    setActive(!active);
  };
  return (
    <div className="rounded border-[1px] border-[#E7E7E7] bg-white p-6">
      <button
        className={`flex w-full cursor-pointer items-center justify-between text-left`}
        onClick={() => handleToggle()}
      >
        <h4 className="text-dark mr-2 text-lg font-bold">{title}</h4>
        <span className="icon inline-flex h-8 w-full max-w-[32px] items-center justify-center rounded-full border-[1px] border-primary text-lg font-semibold text-primary">
          {active ? '-' : '+'}
        </span>
      </button>

      <div className={`${active ? 'block' : 'hidden'}`}>
        <div className="text-relaxed mt-6 text-base font-normal text-primary md:mr-20">{parse(text)}</div>
      </div>
    </div>
  );
};

export default CZ10Accordion;
