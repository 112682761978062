import React from 'react';

type Props = {
  className?: string;
};

const Visa: React.FC<Props> = ({ className }: Props) => (
  <svg width="53" height="17" viewBox="0 0 53 17" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.7133 11.3232C37.7238 8.76097 35.5302 7.65472 33.827 6.79514C32.7491 6.25157 31.868 5.80699 31.868 5.15578C31.868 4.60072 32.4259 4.01303 33.6133 3.86128C35.0249 3.72681 36.4491 3.96495 37.7301 4.55081L38.4638 1.18954C37.2144 0.738175 35.8901 0.504808 34.5554 0.5C30.4375 0.5 27.5754 2.6176 27.5754 5.64274C27.5754 7.87843 29.6522 9.1221 31.2228 9.86154C32.7933 10.6011 33.4038 11.1216 33.3859 11.7938C33.3859 12.8359 32.0954 13.3063 30.8912 13.3236C29.406 13.3419 27.9407 12.9952 26.6333 12.3153L25.8828 15.6765C27.3617 16.2326 28.9375 16.5121 30.5249 16.4996C34.9038 16.4996 37.7838 14.4156 37.8007 11.1888L37.7133 11.3232ZM26.3891 0.769867L22.8996 16.3152H18.7123L22.2017 0.769867H26.3891ZM43.9428 10.8526L46.1407 5.02141L47.4154 10.8526H43.9428ZM52.4754 16.366H48.6017L48.0954 14.0468H42.8438L41.988 16.366H37.5912L43.8554 1.96263C44.1438 1.27693 44.8312 0.826521 45.6007 0.819803H49.0902L52.4754 16.366ZM13.7386 16.2643L20.5091 0.718965H15.9723L11.628 11.2896L9.88279 2.29876C9.72805 1.38449 8.907 0.715123 7.94595 0.718965H0.844894L0.739624 1.17225C2.16383 1.44115 3.54803 1.881 4.8575 2.48313C5.4154 2.73571 5.7933 3.25337 5.85225 3.84492L9.1849 16.2643H13.7386Z"
      fill="#242424"
    />
  </svg>
);

export default Visa;
