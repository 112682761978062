import { Cz10AccordionFragment } from 'generated/strapiTypes';
import CZ10Accordion, { AccordionItem } from './CZ10Accordion';
import { useAccount } from 'frontastic';

export const CZ10AccordionAdapter = (props: Cz10AccordionFragment) => {
  const { account } = useAccount();

  const accordionItems: AccordionItem[] = [];
  props?.accordionItem?.forEach((item) => {
    accordionItems.push({
      title: item?.title ?? '',
      text: item?.text ?? '',
    });
  });

  if (props?.customerGroups?.length > 0 && !props?.customerGroups?.includes(account?.customerGroup?.id)) return null;

  return <CZ10Accordion title={props?.title ?? ''} accordionItems={accordionItems} />;
};
