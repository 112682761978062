import React from 'react';
import parse from 'html-react-parser';
import { FullPageWidthWrapper } from '../../full-bleed';

export type CZ12TextBlockProps = {
  title: string;
  subtitle?: string;
  text: string;
};

const CZ12TextBlock = ({ title, subtitle, text }: CZ12TextBlockProps) => {
  return (
    <section className="container mx-auto max-w-[1280px] py-10 px-4 font-urbanist sm:px-10 lg:px-4">
      <h4 className="mb-2 text-2xl font-bold text-primary">{subtitle}</h4>
      <h2 className="mb-4 text-4xl font-semibold !leading-tight text-black">{title}</h2>
      <div className="text-base font-medium text-[#808080]"> {parse(text)}</div>
    </section>
  );
};

export default CZ12TextBlock;
