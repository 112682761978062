import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { LineItem } from '@Types/wishlist/LineItem';
import Price from 'components/commercetools-ui/price';
import TrashCanIcon from 'components/icons/trash-can';
import { useFormat } from 'helpers/hooks/useFormat';
import { CustomImage } from 'lib/customImage';

export interface Props {
  item: LineItem;
  removeLineItems: (lineItem: LineItem) => void;
}

const WishlistRow: React.FC<Props> = ({ item, removeLineItems }) => {
  const { formatMessage: formatWishlistMessage } = useFormat({ name: 'wishlist' });
  const router = useRouter();
  const goToProductPage = (itemUrl: string) => router.push(itemUrl);

  return (
    <tr>
      <td className="border-t py-4">
        <div className="flex items-center">
          <div className="relative mr-4 w-[80px] rounded-[2px] border border-neutral-200">
            {/* <Image
              alt={item.lineItemId}
              layout="fill"
              loader={({ src }) => src}
              objectFit="contain"
              objectPosition="bottom"
              className="rounded-[2px] border border-neutral-200"
              src={item.variant.images[0]}
              onClick={() => goToProductPage(item._url)}
            /> */}
            <CustomImage imgSrc={item.variant.images[0]} height={80} width={80} />
          </div>
          <div>
            <h5
              onClick={() => goToProductPage(item._url)}
              className="cursor-pointer truncate text-left text-sm font-semibold text-[#242424]"
            >
              {item.name}
            </h5>
          </div>
        </div>
      </td>
      <td className="border-t p-4">
        <span className="block">
          {item.variant.discountedPrice ? (
            <div className="flex items-center">
              <Price price={item.variant.discountedPrice} className="text-base font-semibold text-[#242424]" />
              <Price
                price={item.variant?.price}
                className="pl-6 text-sm font-medium text-[#808080] line-through decoration-[#808080] decoration-1"
              />
            </div>
          ) : (
            <Price price={item.variant?.price} className=" text-base font-semibold text-[#242424]" />
          )}
        </span>
      </td>
      <td className="border-t p-4">
        <span className="text-sm font-medium">
          {item.variant.isOnStock ? (
            <p className="text-[#33B8A1]">
              {formatWishlistMessage({ id: 'wishlist.inStock', defaultMessage: 'In Stock' })}
            </p>
          ) : (
            <p className="text-[#E77474]">
              {formatWishlistMessage({ id: 'wishlist.outOfStock', defaultMessage: 'Out of Stock' })}
            </p>
          )}
        </span>
      </td>
      <td className="border-t p-4">
        <Link href={item?._url}>
          <a className="max-w-[200px] rounded-[4px] bg-primary px-2 py-3 text-sm font-bold text-white hover:opacity-90">
            {formatWishlistMessage({ id: 'wishlist.chooseOptions', defaultMessage: 'Choose Options' })}
          </a>
        </Link>
      </td>
      <td className="border-t p-4 text-center xl:pr-11">
        <button
          type="button"
          onClick={() => removeLineItems(item)}
          className="p-3 text-sm font-bold text-white hover:opacity-90"
        >
          <TrashCanIcon className="h-5 w-5" />
        </button>
      </td>
    </tr>
  );
};

export default WishlistRow;

export const WishlistMobileItem = ({ item, removeLineItems }: Props) => {
  const { formatMessage: formatWishlistMessage } = useFormat({ name: 'wishlist' });
  const router = useRouter();
  const goToProductPage = (itemUrl: string) => router.push(itemUrl);

  return (
    <div className="flex w-full justify-between border-b border-[#E7E7E7] pb-6 pt-6 first:pt-0 last:border-none">
      <div className="relative mr-7 h-[80px] min-w-[80px] rounded-[4px] border border-neutral-200">
        <CustomImage imgSrc={item.variant.images[0]} height={80} width={80} />
      </div>
      <div className="flex w-full flex-col justify-between">
        <div className="w-full items-start justify-between sm:flex">
          <div className="mb-2 w-full">
            <div className="flex w-full items-start justify-between gap-3">
              <h3
                className="mb-2 block cursor-pointer text-sm font-semibold text-black hover:text-primary"
                onClick={() => goToProductPage(item._url)}
              >
                {item.name}
              </h3>
              <div className="flex items-end gap-2 md:hidden">
                <button
                  type="button"
                  className="text-sm font-medium text-[#808080] transition hover:text-white"
                  onClick={() => removeLineItems(item)}
                >
                  <TrashCanIcon className="h-5 w-5" />
                </button>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium">
                {item.variant.isOnStock ? (
                  <p className="text-[#33B8A1]">
                    {formatWishlistMessage({ id: 'wishlist.inStock', defaultMessage: 'In Stock' })}
                  </p>
                ) : (
                  <p className="text-[#E77474]">
                    {formatWishlistMessage({ id: 'wishlist.outOfStock', defaultMessage: 'Out of Stock' })}
                  </p>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          {item.variant.discountedPrice ? (
            <div className="flex items-center">
              <Price price={item.variant.discountedPrice} className="text-xs font-bold text-[#242424]" />
              <Price
                price={item.variant?.price}
                className="pl-6 text-xs font-medium text-[#808080] line-through decoration-[#808080] decoration-1"
              />
            </div>
          ) : (
            <Price price={item.variant?.price} className="text-xs font-bold text-[#242424]" />
          )}

          <Link href={item?._url}>
            <a className="rounded-[4px] bg-primary px-2 py-2 text-sm font-bold text-white hover:opacity-90">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_926_5404)">
                  <path
                    d="M15.0008 7.44698H8.57578V0.996982C8.57578 0.696982 8.32578 0.421982 8.00078 0.421982C7.70078 0.421982 7.42578 0.671982 7.42578 0.996982V7.44698H1.00078C0.700781 7.44698 0.425781 7.69698 0.425781 8.02198C0.425781 8.32198 0.675781 8.59698 1.00078 8.59698H7.45078V14.997C7.45078 15.297 7.70078 15.572 8.02578 15.572C8.32578 15.572 8.60078 15.322 8.60078 14.997V8.57198H15.0008C15.3008 8.57198 15.5758 8.32198 15.5758 7.99698C15.5758 7.69698 15.3008 7.44698 15.0008 7.44698Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_926_5404">
                    <rect width="16" height="16" fill="white" transform="translate(0 -0.00302124)" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};
