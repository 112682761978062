import React, { FC, useEffect, useRef, useState } from 'react';
import ChevronDown from 'components/icons/chevron-down';
import { desktop } from 'helpers/utils/screensizes';

type CollapseProps = {
  title: string;
  open?: boolean;
};

export const Collapse: FC<CollapseProps> = (props) => {
  const { children, title, open = true } = props;
  const [collapsed, setCollapsed] = useState<boolean>(!open);
  const beforeWidth = useRef(1920);
  const maxWidth = 0;

  useEffect(() => {
    if (
      window &&
      window.innerWidth < maxWidth &&
      !(beforeWidth.current < maxWidth) &&
      window.innerWidth !== beforeWidth.current
    ) {
      setCollapsed(true);
      beforeWidth.current = window.innerWidth;
    } else if (
      window &&
      window.innerWidth >= maxWidth &&
      !(beforeWidth.current >= maxWidth) &&
      window.innerWidth !== beforeWidth.current
    ) {
      setCollapsed(false);
      beforeWidth.current = window.innerWidth;
    }
  }, [window.innerWidth, maxWidth]);

  return (
    <div className="inline-flex w-full flex-col items-start justify-start gap-2 bg-white">
      <div
        className="inline-flex w-full cursor-pointer flex-row items-center justify-between"
        onClick={() => setCollapsed(!collapsed)}
      >
        <h4 className="cursor-pointer select-none text-base font-semibold text-neutral-800">{title}</h4>
        <ChevronDown className={(collapsed ? 'rotate-0' : 'rotate-180') + ' transition-transform duration-200'} />
      </div>
      <div className={(collapsed ? 'hidden' : '') + ' mb-4 inline-flex w-full flex-col gap-2'}>
        <div className="h-px w-full border border-neutral-200"></div>
        {children}
      </div>
    </div>
  );
};
