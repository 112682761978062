import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
// import { UserIcon } from '@heroicons/react/outline';
import { Account } from '@Types/account/Account';
import { DataTestId } from '@Types/playwright';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';
import { logout } from 'frontastic/actions/account';

type AccountButtonProps = {
  accountLink: Reference;
  account: Account;
} & DataTestId;

const UserIcon = (props: any) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.98696 7.73233C7.05976 7.73233 5.51801 6.21627 5.51801 4.36617C5.51801 2.51606 7.05976 1 8.98696 1C10.9142 1 12.4559 2.51606 12.4559 4.36617C12.4559 6.21627 10.9142 7.73233 8.98696 7.73233ZM8.98696 2.15632C7.70216 2.15632 6.67432 3.15846 6.67432 4.36617C6.67432 5.57388 7.70216 6.57602 8.98696 6.57602C10.2718 6.57602 11.2996 5.57388 11.2996 4.36617C11.2996 3.15846 10.2718 2.15632 8.98696 2.15632Z"
      fill="black"
    />
    <path
      d="M14.0491 16.5459H3.92493C3.41101 16.5459 2.99988 16.1348 2.99988 15.6209V13.3083C2.99988 10.79 5.05555 8.73438 7.57375 8.73438H10.426C12.9442 8.73438 14.9999 10.79 14.9999 13.3083V15.6209C14.9742 16.1091 14.563 16.5459 14.0491 16.5459ZM4.15619 15.3896H13.8436V13.3083C13.8436 11.4324 12.3018 9.89069 10.426 9.89069H7.54806C5.67226 9.89069 4.1305 11.4324 4.1305 13.3083V15.3896H4.15619Z"
      fill="black"
    />
  </svg>
);

const AccountButton: React.FC<AccountButtonProps> = ({ accountLink, account, ...props }) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="relative flex items-center" {...props}>
      {account ? (
        <Menu>
          <div className="relative flex space-x-8">
            <Menu.Button className="flex">
              <span className="sr-only">Account</span>
              <UserIcon className="h-6 w-6 text-primary-400 dark:text-light-100" aria-hidden="true" />
            </Menu.Button>
            <div className="absolute -right-[1px] -bottom-[2px] h-[9px] w-[9px] rounded-md bg-green-700"></div>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 top-6 z-50 mt-2 w-fit origin-top-right rounded-md bg-white shadow-sm ring-1 ring-black/5 focus:outline-none dark:bg-primary-400 dark:shadow-3xl">
              <div className="py-1 ">
                <Menu.Item>
                  <ReferenceLink
                    target={{ link: '/account', type: 'link', target: '/account', openInNewWindow: false }}
                    className={`block w-36 cursor-pointer py-2 px-4 ${
                      account ? 'text-left' : 'text-center'
                    }  text-sm text-primary-400 hover:bg-[#F6F9FF] hover:text-primary dark:bg-primary-400  dark:text-light-100`}
                  >
                    {formatAccountMessage({ id: 'customer.area', defaultMessage: 'Customer Area' })}
                  </ReferenceLink>
                </Menu.Item>
                {account && (
                  <Menu.Item>
                    <button
                      onClick={handleLogout}
                      className="block w-36 cursor-pointer py-2 px-4 text-left text-sm hover:bg-[#F6F9FF] hover:text-primary dark:bg-primary-400 dark:text-light-100"
                    >
                      {formatAccountMessage({ id: 'signout', defaultMessage: 'Logout' })}
                    </button>
                  </Menu.Item>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      ) : (
        <div className="flex space-x-8">
          <div className="flex">
            <ReferenceLink
              target={accountLink}
              className="-m-2 p-2 text-primary-400 hover:text-primary-500 dark:text-light-100"
            >
              <span className="sr-only">Account</span>
              <UserIcon className="h-6 w-6" aria-hidden="true" />
            </ReferenceLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountButton;
