import { Order } from '@Types/cart/Order';
import { FullPageWidthWrapper } from 'components/tailgrids-ui/full-bleed';
import { MediaItemWithMods, useAccount, useCart } from 'frontastic';
import Image from 'frontastic/lib/image';
import { useFormat } from 'helpers/hooks/useFormat';
import { useQueryOrder } from 'helpers/hooks/useQueryOrder';
import { useAnonymousStore } from 'helpers/stores/anonymousStore';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

type Props = {
  title: string;
  image: MediaItemWithMods;
  isCentered: boolean;
};

const ThankYouBanner = ({ title, image, isCentered }: Props) => {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { formatMessage } = useFormat({ name: 'common' });
  const router = useRouter();
  const { getThankYouOrder } = useCart();
  const [order, setOrder] = useState<Partial<Order>>();
  const { loggedIn } = useAccount();
  const { anonymousId } = useAnonymousStore();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getOrder = async () => {
      const order = await getThankYouOrder(router?.query?.orderId as string, anonymousId);
      setOrder(order);
      setLoading(false);
    };
    getOrder();
  }, [loggedIn]);

  if (!order?.orderId) return <></>;

  return (
    <FullPageWidthWrapper>
      <section className="relative h-[380px] bg-primary">
        <Image src={image?.media?.file} layout="fill" className="h-full w-full object-cover" alt="Banner Image" />
        <div
          className={`absolute top-1/2 left-1/2 flex h-full w-full max-w-[1280px] flex-col items-start justify-end px-4 pb-20 sm:px-10 lg:px-4`}
          style={{
            transform: 'translate(-50%, -50%)',
          }}
        >
          <div
            className={`flex w-full flex-col justify-center gap-4 ${
              isCentered ? 'items-center text-center' : 'items-start text-left'
            }`}
          >
            <h1 className="text-heading-3 font-bold text-white sm:text-heading-1 sm:!leading-tight">{title}</h1>
          </div>
        </div>
      </section>
    </FullPageWidthWrapper>
  );
};

export default ThankYouBanner;
