import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Product } from '@Types/product/Product';
import { TypesenseProduct } from '@Types/typesense';
import { MultiSearchRequestWithPresetSchema } from 'typesense/lib/Typesense/MultiSearch';
import ProductCarousel from 'components/tailgrids-ui/products/slider';
import {
  getTypesenseConfig,
  getTypesenseInstantSearchAdapter,
  mapLocalizedString,
  TYPESENSE_ANY,
  TYPESENSE_COLLECTION_PRODUCTS,
} from 'features/typesense/utils';
import { countryToCurrency } from 'helpers/countryCurrencyMapper';
import { useAccount } from 'frontastic';

type UpsellProduct = {
  sku: string;
  productId: string;
  gender?: string;
  category_ids?: string[];
};

type UpsellProductsProps = {
  title?: string;
  product_results: (RelationProducts | CategoryProducts | DirectProducts)[];
  productAmount?: number;
  inCheckout?: boolean;
};

type RelationProducts = {
  type: 'relation';
  related_products: UpsellProduct[];
};

type CategoryProducts = {
  type: 'category';
  category_slugs?: string[];
  category_ids?: string[];
};

type DirectProducts = {
  type: 'product-list';
  products: UpsellProduct[];
};

export const UpsellProducts = ({ title, product_results, productAmount = 50, inCheckout }: UpsellProductsProps) => {
  const [upsellProducts, setUpsellProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const router = useRouter();
  const locale = router.locale;
  const country = locale.split('-')[1];
  const { account } = useAccount();
  const priceGroup = account?.customerGroup?.id ?? TYPESENSE_ANY;
  const instantSearchAdapter = useMemo(() => {
    return getTypesenseInstantSearchAdapter();
  }, []);

  useEffect(() => {
    const performTypesenseCall = async () => {
      setLoading(true);
      try {
        const response = await instantSearchAdapter.typesenseClient.multiSearch.perform<TypesenseProduct[]>({
          searches: product_results
            ?.map((result) => {
              let results: MultiSearchRequestWithPresetSchema[] = [];
              const config = {
                ...getTypesenseConfig({}),
                preset: undefined,
                collection: TYPESENSE_COLLECTION_PRODUCTS,
                q: '*',
                exclude_fields: undefined,
                group_limit: 1,
              };
              switch (result.type) {
                case 'relation': {
                  results = [
                    ...results,
                    ...result.related_products.map((related_product) => {
                      const filters = [
                        `product_id:!=${related_product.productId}`,
                        ...(related_product?.gender ? [`attributes.gender:=${related_product.gender}`] : []),
                        ...(related_product?.category_ids
                          ? [`category_ids:!=[${related_product.category_ids.join(',')}]`]
                          : []),
                      ];
                      return {
                        ...config,
                        vector_query: `embedding:([], id: ${related_product.sku})`,
                        filter_by: filters.join(' && '),
                        include_fields: `product_id,names,slugs,sku,price.${country},image_urls,in_stock`,
                        per_page: Math.round(productAmount / result.related_products.length),
                      };
                    }),
                  ];
                  break;
                }
                case 'category': {
                  if (result?.category_ids) {
                    results = [
                      ...results,
                      ...result?.category_ids?.map((category_id) => {
                        return {
                          ...config,
                          filter_by: `category_ids:=${category_id}`,
                          include_fields: `product_id,names,slugs,sku,price.${country},image_urls,in_stock,category_ids`,
                          per_page: Math.round(
                            productAmount / ((result.category_ids?.length ?? 0) + (result.category_slugs?.length ?? 0)),
                          ),
                        };
                      }),
                    ];
                  }
                  if (result?.category_slugs) {
                    results = [
                      ...results,
                      ...result?.category_slugs?.map((category_slug) => {
                        return {
                          ...config,
                          filter_by: `categories.${locale.split('-')[0]}:=['${category_slug}']`,
                          include_fields: `product_id,names,slugs,sku,price.${country},image_urls,in_stock,category_ids`,
                          per_page: Math.round(
                            productAmount / ((result.category_ids?.length ?? 0) + (result.category_slugs?.length ?? 0)),
                          ),
                        };
                      }),
                    ];
                  }
                  break;
                }
                case 'product-list': {
                  results = [
                    ...results,
                    ...result.products.map((product) => {
                      return {
                        ...config,
                        filter_by: `product_id:=${product.productId}`,
                        include_fields: `product_id,names,slugs,sku,price.${country},image_urls,in_stock`,
                        per_page: Math.round(productAmount / result.products.length),
                      };
                    }),
                  ];
                  break;
                }
              }
              return results;
            })
            .reduce((acc, curr) => [...acc, ...curr], []),
        });
        let responseProducts: Product[] = [];
        response?.results
          ?.map((result) => {
            const productResult: Product[] = result?.grouped_hits
              ?.map((groupedHit) => {
                if (
                  product_results &&
                  groupedHit.group_key.filter((key) => {
                    const shouldBeIn = product_results
                      .map((product_result) => {
                        if (product_result.type === 'product-list') {
                          return product_result.products.map((product) => product.productId);
                        } else {
                          return [];
                        }
                      })
                      .reduce((acc, curr) => [...acc, ...curr], []);
                    const shouldNotBeIn = product_results
                      .map((product_result) => {
                        if (product_result.type === 'relation') {
                          return product_result.related_products.map((product) => product.productId);
                        } else {
                          return [];
                        }
                      })
                      .reduce((acc, curr) => [...acc, ...curr], []);
                    if (shouldBeIn.includes(key)) {
                      return false;
                    } else if (shouldNotBeIn.includes(key)) {
                      return true;
                    }
                  }).length > 0
                ) {
                  return null;
                }
                const variants = groupedHit;
                const slug = mapLocalizedString(variants?.hits?.[0]?.document?.slugs, locale);
                return {
                  productId: groupedHit?.group_key?.[0],
                  distance: Math.min(...variants?.hits?.map((hit) => hit?.['vector_distance'])),
                  slug: slug,
                  name: mapLocalizedString(variants?.hits?.[0]?.document?.names, locale),
                  version: `${variants?.[0]?.version}`,
                  _url: `/${slug}/p/${variants?.hits?.[0]?.document.sku}`,
                  variants: variants?.hits?.map((hit) => {
                    const document = hit?.document;
                    const price = document?.price?.[country]?.[priceGroup];
                    return {
                      sku: document?.sku,
                      name: mapLocalizedString(document?.names, locale),
                      price: {
                        centAmount: price?.discounted?.cent_amount ?? price?.cent_amount,
                        fractionDigits: 2,
                        currencyCode: countryToCurrency[country],
                      },
                      images: document?.image_urls,
                      ...(price?.discounted?.cent_amount
                        ? {
                            discountedPrice: {
                              centAmount: price?.cent_amount,
                              fractionDigits: 2,
                              currencyCode: countryToCurrency[country],
                            },
                          }
                        : {}),
                      isOnStock: document?.in_stock === 'true',
                    };
                  }),
                } as Product;
              })
              .filter((result) => result !== null);
            return productResult;
          })
          .forEach((productArray) => {
            responseProducts = [...responseProducts, ...productArray];
          });
        setUpsellProducts(
          responseProducts.sort((a, b) => {
            if (a.distance || b.distance) {
              return b?.distance - a?.distance;
            } else {
              return a?.productRelevance - b?.productRelevance;
            }
          }).reduce((acc, curr) => {
            return [
              ...acc,
              ...(!acc.map((item) => item.productId).includes(curr.productId) ? [curr] : [])
            ];
          }, [] as Product[]),
        );
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    performTypesenseCall().then();
  }, [country, instantSearchAdapter.typesenseClient.multiSearch, locale, priceGroup, productAmount, product_results]);

  return <ProductCarousel products={upsellProducts} loading={loading} title={title} inCheckout={inCheckout} />;
};
