import { gql } from 'graphql-tag';

export const sections = gql`
  fragment S55Form on ComponentSectionsS55Form {
    id
    formName
    submitText
    emailRecipients
    fields {
      id
      inputType
      displayName
      placeholder
      required
    }
    customerGroups
  }

  fragment S1Banner on ComponentBasicBlockS1Banner {
    title
    description
    customerGroups
    image {
      data {
        attributes {
          url
        }
      }
    }
    buttonText
    buttonUrl
    targetBlank
  }

  fragment S4ProductList on ComponentBasicBlockS4ProductList {
    id
    Products {
      product
    }
    category
    customerGroups
  }

  fragment S3SingleProduct on ComponentBasicBlockS3SingleProduct {
    id
    product
    customerGroups
  }

  fragment Cz12TextBlock on ComponentSectionsCz12TextBlock {
    id
    title
    subtitle
    text
    customerGroups
  }

  fragment Cz10Accordion on ComponentSectionsCz10Accordion {
    id
    title
    accordionItem {
      title
      text
    }
    customerGroups
  }

  fragment Cz15Article on ComponentSectionsCz15Article {
    id
    title
    subtitle
    text
    image {
      data {
        attributes {
          url
          width
          height
        }
      }
    }
    imageMobile {
      data {
        attributes {
          url
          width
          height
        }
      }
    }
    customerGroups
  }

  fragment Cz11Cta on ComponentSectionsCz11Cta {
    id
    ctaItem {
      id
      icon {
        data {
          attributes {
            url
          }
        }
      }
      title
      subtitle
      text
      buttonText
      buttonUrl
      iconAlt
    }
    customerGroups
  }

  fragment Cz13ImageWithText on ComponentSectionsCz13ImageWithText {
    id
    imageTextItem {
      alignment
      title
      subtitle
      text
      image {
        data {
          attributes {
            url
            width
            height
          }
        }
      }
      imageMobile {
        data {
          attributes {
            url
            width
            height
          }
        }
      }
      imageAlt
      buttonText
      buttonUrl
      buttonTargetBlank
    }
    customerGroups
  }

  fragment CzAboutUs on ComponentSectionsCzAboutUs {
    title
    subtitle
    text
    AboutItem {
      title
      subtitle
      image {
        data {
          attributes {
            url
            width
            height
          }
        }
      }
      imageMobile {
        data {
          attributes {
            url
            width
            height
          }
        }
      }
      imageAlt
      imageTitle
      linkUrl
      targetBlank
    }
    customerGroups
  }

  fragment Cz16Features on ComponentSectionsCz16Features {
    id
    title
    FeatItem {
      id
      icon {
        data {
          attributes {
            url
          }
        }
      }
      imageAlt
      imageTitle
      title
      text
      linkUrl
      targetBlank
    }
    customerGroups
  }

  fragment Cz14ImageGallery on ComponentSectionsCz14ImageGallery {
    title
    galleryItem {
      id
      text
      image {
        data {
          attributes {
            url
            width
            height
          }
        }
      }
      imageMobile {
        data {
          attributes {
            url
            width
            height
          }
        }
      }
      imageAlt
      imageTitle
      linkUrl
      linkTargetBlank
    }
    customerGroups
  }
`;
