import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type IProduct = {
  size: string | null;
  updateSize: (newSize: string) => void;
};

export const useProductStore = create(
  persist<IProduct>(
    (set) => ({
      size: null,
      updateSize: (newSize: string) => set({ size: newSize }),
    }),
    {
      name: 'product-storage',
    },
  ),
);
