import React from 'react';

type Props = {
  className?: string;
};

const PinterestIcon: React.FC<Props> = ({ className }: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_399_3174)">
      <path
        d="M0.674975 12.0375C0.749975 15.525 2.36248 19.0125 5.09998 21.1125C5.96248 21.75 6.89997 22.125 7.87498 22.5375C7.46247 19.875 8.47498 17.2125 9.03748 14.5875C9.11248 14.325 9.14997 14.025 9.14997 13.725C9.14997 13.3125 8.99998 12.9 8.88748 12.4875C8.77498 11.8125 8.84997 11.1 9.14997 10.4625C9.56248 9.6 10.5375 8.925 11.4 9.2625C12.1875 9.5625 12.4875 10.6125 12.3375 11.4375C12.1875 12.3 11.7375 13.05 11.5125 13.875C11.25 14.7 11.2875 15.7125 11.8875 16.275C12.45 16.8 13.35 16.8375 14.0625 16.5375C15.1125 16.0875 15.7875 15.0375 16.2 13.9875C16.95 12.0375 16.8 9.5625 15.2625 8.1375C14.625 7.5 13.725 7.0875 12.75 6.9375C11.1 6.675 9.29998 7.1625 8.13748 8.3625C6.97498 9.5625 6.44998 11.4 6.93748 12.975C7.08747 13.5 7.38748 14.025 7.49998 14.55C7.61247 15.075 7.57498 15.75 7.19998 16.125C7.16248 16.1625 7.12497 16.2 7.04997 16.2375C6.97497 16.275 6.86247 16.2 6.78748 16.1625C6.07498 15.7125 5.51248 15 5.17498 14.25C4.12498 11.9625 4.64997 9.1125 6.29997 7.2375C7.94998 5.3625 10.6125 4.4625 13.0875 4.8C15.4125 5.1 17.7 6.4875 18.675 8.625C19.275 9.9 19.3875 11.3625 19.1625 12.75C18.9375 14.175 18.375 15.525 17.4375 16.6125C16.5 17.7 15.15 18.45 13.725 18.525C12.5625 18.6 11.325 18.1875 10.725 17.2125C10.35 19.2375 9.63748 21.225 8.58747 22.9875C8.54997 23.0625 10.9875 23.5875 11.2125 23.5875C13.9875 23.8125 16.95 22.725 19.125 21C25.125 16.2375 24.4875 7.125 18.5625 2.625C15.4875 0.262496 11.85 -0.187504 8.24998 1.0875C7.16248 1.4625 6.14998 2.1 5.21248 2.775C3.71248 3.9 2.51247 5.3625 1.72497 7.05C0.937475 8.5875 0.637475 10.3125 0.674975 12.0375Z"
        fill="#808080"
      />
    </g>
    <defs>
      <clipPath id="clip0_399_3174">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PinterestIcon;
