import React, { FC } from 'react';
import { DataTestId } from '@Types/playwright';

type DropdownProps = {
  options?: {
    label: string;
    value: string;
    isSelected?: boolean;
  }[];
  onChange?: (value: string) => void;
  className?: string;
} & DataTestId;

export const Dropdown: FC<DropdownProps> = ({ options, onChange, className = '', ...rest }) => {
  const currentValue = options?.find((option) => option?.isSelected === true) ?? options[0];

  return (
    <>
      <select
        data-testid={rest['data-testid']}
        defaultValue={currentValue.value}
        className={
          'my-2 w-full rounded-sm border border-gray-200 py-0.5 focus:outline-none focus:ring-0 focus:ring-transparent ' +
          className
        }
        onChange={(event) => onChange(event?.target?.value)}
      >
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
};
