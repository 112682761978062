import React, { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';
import { useAccount } from 'frontastic';
import { localizedProductCategories } from 'frontastic/actions/productCategories';
import { loginSSO } from 'frontastic/actions/sso';
import { CustomCheckbox } from '../adyen-checkout/panels/address';

export interface LoginProps {
  registerLink?: Reference;
  accountLink?: Reference;
}

const Login: React.FC<LoginProps> = ({ registerLink }) => {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage } = useFormat({ name: 'common' });

  const [showResendVerification, setShowResendVerification] = useState<boolean>(false);

  // query params
  const router = useRouter();
  //account actions
  const { login, loggedIn, requestConfirmationEmail, requestPasswordReset } = useAccount();

  //login data
  const [data, setData] = useState({ email: '', password: '', rememberMe: false });
  const [checked, setChecked] = useState<boolean>(false);

  //error
  const [error, setError] = useState('');

  //success
  const [success, setSuccess] = useState('');

  //processing...
  const [loading, setLoading] = useState(false);

  //attempting to resend verification email
  const [resendVerification, setResendVerification] = useState(false);

  //attempting to request a password reset
  const [resendPasswordReset, setResendPasswordReset] = useState(false);

  //not on default login modal
  const subModal = resendVerification || resendPasswordReset;

  //Password is visible while typing
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  //Toggles password visibility
  const togglePasswordVisibility = useCallback(() => setIsPasswordVisible(!isPasswordVisible), [isPasswordVisible]);

  //get back to login modal
  const backToLogin = () => {
    setResendPasswordReset(false);
    setResendVerification(false);
  };

  //wants to resend verification
  const toResendVerification = () => {
    setResendVerification(true);
    setResendPasswordReset(false);
  };

  //requesting a password reset
  const toResendPassword = () => {
    setResendPasswordReset(true);
    setResendVerification(false);
  };

  //handle text input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  //handle checkbox input change
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.id]: e.target.checked });
    setChecked(!checked);
  };

  //login user
  const loginUser = async () => {
    try {
      const response = await login(data.email, data.password);
      if (!response.accountId) {
        setSuccess('');
        if (typeof response === 'string' && response === `Your email address "${data.email}" was not yet verified.`) {
          setError(
            formatErrorMessage({
              id: 'accountNotVerified',
              defaultMessage: "Your email address was not yet confirmed. Didn't receive the verification email?",
            }),
          );
          setShowResendVerification(true);
        } else {
          setError(formatErrorMessage({ id: 'auth.wrong', defaultMessage: 'Wrong email address or password' }));
          setShowResendVerification(false);
        }
      }
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };

  //resend verification email for user
  const resendVerificationEmailForUser = async () => {
    try {
      await requestConfirmationEmail(data.email, data.password);
      setSuccess(
        formatAccountMessage({
          id: 'verification.resent',
          defaultMessage: 'An email was sent to {email}',
          values: { email: data.email },
        }),
      );
      setError('');
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };

  //request a password reset for user
  const resendPasswordResetForUser = async () => {
    try {
      await requestPasswordReset(data.email);
      setSuccess(
        formatAccountMessage({
          id: 'verification.resent',
          defaultMessage: 'An email was sent to {email}',
          values: { email: data.email },
        }),
      );
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };

  //form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //processing starts
    setLoading(true);
    //if user is attempting to resend verification email
    if (resendVerification) resendVerificationEmailForUser();
    //if user is attempting tor equest a password reset
    else if (resendPasswordReset) resendPasswordResetForUser();
    //if user wants to login
    else loginUser();
    //processing ends
    setLoading(false);
  };

  const handleRedirect = async () => {
    const res = await localizedProductCategories(router.asPath.split('current')[1], router.locale);
    const { newSlug } = res;
    if (newSlug) {
      router.push(newSlug);
    } else if (router.asPath.includes('current')) {
      router.push(router.asPath.split('current')[1]);
    } else {
      router.push('/');
    }
  };
  const onSSOLogin = async () => {
    const res = await loginSSO();
    if (res.url) {
      window.location.href = res.url;
    }
  };

  if (loggedIn) {
    if (!router.asPath.includes('current')) {
      router.push('/');
    } else {
      handleRedirect();
    }
  }

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8">
        <div className="mx-auto w-full max-w-[500px] rounded-sm border-[1px] border-[#E7E7E7] bg-white px-6 pb-32 pt-16 dark:bg-primary-200 lg:px-12">
          <form className="space-y-7" onSubmit={handleSubmit}>
            <div className="py-6 text-center">
              <h2 className="text-3xl font-extrabold text-[#242424]">
                {resendPasswordReset
                  ? formatAccountMessage({ id: 'password.reset.headline', defaultMessage: 'Reset your password' })
                  : formatAccountMessage({ id: 'account.sign.in', defaultMessage: 'Sign in to your account' })}
              </h2>
              {!subModal && (
                <h3 className="text-md mt-6 text-[#808080]">
                  {formatAccountMessage({ id: 'details.enter', defaultMessage: 'Please enter your details' })}
                </h3>
              )}
            </div>
            {success && <p className="text-sm text-[#34D399]">{success}</p>}
            {error && (
              <div className="flex flex-col gap-2 sm:block">
                <span className="mr-2 text-sm text-[#F87171]">{error}</span>
                {showResendVerification && (
                  <span
                    className="inline w-fit cursor-pointer text-sm text-primary underline"
                    onClick={async () => await resendVerificationEmailForUser()}
                  >
                    {formatAccountMessage({
                      id: 'verification.resend',
                      defaultMessage: 'Send a new verification email',
                    })}
                  </span>
                )}
              </div>
            )}

            <div>
              <label htmlFor="email" className="mb-2 block text-base font-medium text-[#808080]">
                {formatMessage({ id: 'email', defaultMessage: 'Email' })}
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder={formatAccountMessage({ id: 'email.enter', defaultMessage: 'Enter your email' })}
                  required
                  className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                  onChange={handleChange}
                  data-testid="login-email"
                />
              </div>
            </div>

            {!resendPasswordReset && (
              <div>
                <label htmlFor="password" className="mb-2 block text-base font-medium text-[#808080]">
                  {formatAccountMessage({ id: 'password', defaultMessage: 'Password' })}
                </label>
                <div className="relative mt-1">
                  <input
                    id="password"
                    name="password"
                    type={isPasswordVisible ? 'text' : 'password'}
                    autoComplete="current-password"
                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                    required
                    className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                    onChange={handleChange}
                    data-testid="login-password"
                  />
                  <span
                    className="absolute right-3 top-1/2 block h-4 w-4 -translate-y-1/2 text-neutral-600"
                    onClick={togglePasswordVisibility}
                  >
                    {!isPasswordVisible ? <EyeOffIcon /> : <EyeIcon />}
                  </span>
                </div>
              </div>
            )}

            {subModal ? (
              <div>
                <ArrowLeftIcon className="w-4 cursor-pointer text-primary hover:text-[#33B8A1]" onClick={backToLogin} />
              </div>
            ) : (
              <div className="mt-2 space-y-2">
                <div className="flex flex-col items-start justify-between md:flex-row">
                  <label className="flex items-center rounded py-4 text-sm">
                    <CustomCheckbox
                      labelTitle={formatMessage({ id: 'rememberMe', defaultMessage: 'Remember me' })}
                      id="rememberMe"
                      checked={checked}
                      onChange={handleCheckboxChange}
                    />
                  </label>

                  <div className="py-4 text-right text-sm">
                    <span
                      className="cursor-pointer text-primary underline transition hover:text-[#33B8A1]"
                      onClick={toResendPassword}
                    >
                      {formatAccountMessage({ id: 'password.forgot', defaultMessage: 'Forgot your password?' })}
                    </span>
                  </div>
                </div>

                {/* <div className="flex items-center justify-end">
                  <div className="text-sm">
                    <span
                      className="cursor-pointer font-medium text-accent-400 hover:text-accent-500"
                      onClick={toResendVerification}
                    >
                      {formatAccountMessage({
                        id: 'verification.resend',
                        defaultMessage: 'Bestätigungsmail erneut senden',
                      })}
                    </span>
                  </div>
                </div> */}
              </div>
            )}
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded border border-transparent bg-primary py-3 px-8 text-sm font-medium text-white transition-colors duration-200 ease-out hover:bg-[#33B8A1] focus:outline-none disabled:bg-[#808080]"
                disabled={loading}
                data-testid="login-button"
              >
                {subModal
                  ? formatMessage({ id: 'submit', defaultMessage: 'Submit' })
                  : formatAccountMessage({ id: 'sign.in', defaultMessage: 'Sign in' })}
              </button>

              <button
                onClick={onSSOLogin}
                className="mt-3 flex w-full justify-center rounded border border-neutral-400 py-3 px-8 text-sm font-medium transition-colors duration-200 ease-out hover:bg-neutral-400 focus:outline-none disabled:bg-[#808080]"
                type="button"
              >
                SSO Login
              </button>
              <p className="mt-6 text-center text-sm text-[#808080]">
                {formatAccountMessage({ id: 'account.doNotHave', defaultMessage: "Don't have an account?" })}{' '}
                <ReferenceLink
                  target={registerLink}
                  className="font-medium text-primary underline hover:text-[#33B8A1]"
                >
                  {formatAccountMessage({ id: 'account.register.here', defaultMessage: 'Register here' })}
                </ReferenceLink>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
