import React from 'react';
import { DataTestId } from '@Types/playwright';
import { useRange, UseRangeProps } from 'react-instantsearch';
import { PriceRange } from 'components/tailgrids-ui/products/product-filter';
import { TYPESENSE_LABEL_PREFIX } from 'features/typesense/utils';

type PriceRangeSliderProps = UseRangeProps & { className?: string; open?: boolean } & DataTestId;

export const PriceRangeSlider = (props: PriceRangeSliderProps) => {
  const { className, open, ...rest } = props;
  const { start, range, canRefine, refine } = useRange(rest);
  const { min, max } = range;

  return (
    <PriceRange
      open={open}
      attribute={TYPESENSE_LABEL_PREFIX + props.attribute.split('.')[0]}
      refine={refine}
      min={min}
      max={max}
      canRefine={canRefine}
      start={start}
      className={className}
      data-testid={props['data-testid']}
    />
  );
};
