import React from 'react';
import { UpsellProducts } from 'features/typesense/components';

const ProductList = ({ products, category }: { products?: string[]; category?: string }) => {
  return (
    <UpsellProducts
      title=""
      product_results={[
        {
          type: 'product-list',
          products: products.map((product) => ({
            sku: product?.split('"; ')?.[0]?.replace('SKU: "', '') ?? '',
            productId: product?.split('"; ')?.[1]?.replace('ID: "', '') ?? '',
          })),
        },
        {
          type: 'category',
          category_ids: [category?.split('"; ')?.[0]?.replace('ID: "', '') ?? ''],
        },
      ]}
    />
  );
};

export default ProductList;
