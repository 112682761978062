import React, { ChangeEvent, useState, useEffect, SetStateAction, useMemo, ReactElement, ReactNode } from 'react';
import { useRouter } from 'next/router';
import { Address } from '@Types/account/Address';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { ProjectSettings } from '@Types/ProjectSettings';
import { countryToCurrency } from 'helpers/countryCurrencyMapper';
import { useFormat } from 'helpers/hooks/useFormat';
import { ReferenceLink } from 'helpers/reference';
import { getTaxedCountries } from 'helpers/utils/getTaxedCountries';
import { useAccount, useCart } from 'frontastic/provider';
import { FormData } from '..';
import { ShippingMethodsProps } from './shippingMethods';

type AddressProps = {
  data: FormData;
  updateData: React.Dispatch<SetStateAction<FormData>>;
  billingIsSameAsShipping?: boolean;
  shippingIsSameAsBilling?: boolean;
  toggleBillingAddressOption?: () => void;
  toggleShippingAddressOption?: () => void;
  disabledBtn?: boolean;
  shippingMethodSelector?: ReactElement<ShippingMethodsProps>;
  addressSelector?: ReactElement;
  availableAddresses?: Partial<Address>[];
  children?: ReactNode;
};

export const regionToCurrency: Record<string, string> = {
  DE: 'EUR',
  FR: 'EUR',
  GB: 'GBP',
  US: 'USD',
  NL: 'EUR',
};

export const regionToZone: Record<string, string> = {
  DE: 'EU',
  FR: 'EU',
  GB: 'GB',
  US: 'US',
  NL: 'EU',
};

const AddressPanel: React.FC<AddressProps> = ({
  data,
  updateData,
  shippingIsSameAsBilling,
  toggleShippingAddressOption,
  addressSelector,
  availableAddresses,
  children,
}) => {
  const [projectSettings, setProjectSettings] = useState<ProjectSettings>(null);
  const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>(null);
  const [availableCountryOptions, setAvailableCountryOptions] = useState<string[]>(null);
  const { getProjectSettings, getShippingMethods } = useCart();
  const { formatMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const [currentShippingMethod, setCurrentShippingMethod] = useState<ShippingMethod>();
  const [countryMapper, setCountryMapper] = useState<string | undefined>();
  const { addAddress, account } = useAccount();
  const { loggedIn } = useAccount();
  const router = useRouter();

  useEffect(() => {
    getShippingMethods().then((data) => {
      setShippingMethods(data);
    });

    getProjectSettings().then((data) => {
      setProjectSettings(data);
    });
  }, []);

  useEffect(() => {
    if (shippingMethods && projectSettings) {
      const totalCountries = getTaxedCountries(shippingMethods, projectSettings?.countries);

      setAvailableCountryOptions(totalCountries);
      if (
        shippingMethods.find((method) => method.name === `Standard ${regionToZone[router.locale.split('-')[1]]}`)
          ?.billingCountry
      ) {
        setCountryMapper(
          JSON.parse(
            shippingMethods.find((method) => method.name === `Standard ${regionToZone[router.locale.split('-')[1]]}`)
              .billingCountry,
          ),
        );
      }
    } else {
      getShippingMethods().then((data) => {
        setCurrentShippingMethod(
          data.find((method) => method.name === `Standard ${regionToZone[router.locale.split('-')[1]]}`),
        );
      });
    }
  }, [shippingMethods, projectSettings, currentShippingMethod]);

  const handleChange = (e: ChangeEvent) => {
    updateData((newData) => {
      const updatedData = {
        ...newData,
        [(e.target as HTMLInputElement)?.name]: (e.target as HTMLInputElement)?.value,
      };
      return {
        ...updatedData,
        email: updatedData.email,
        firstName: updatedData.billingFirstName,
        lastName: updatedData.billingFirstName,
        shippingFirstName: shippingIsSameAsBilling ? updatedData.billingFirstName : updatedData.shippingFirstName,
        shippingLastName: shippingIsSameAsBilling ? updatedData.billingLastName : updatedData.shippingLastName,
        shippingStreetName: shippingIsSameAsBilling ? updatedData.billingStreetName : updatedData.shippingStreetName,
        shippingCity: shippingIsSameAsBilling ? updatedData.billingCity : updatedData.shippingCity,
        shippingPostalCode: shippingIsSameAsBilling ? updatedData.billingPostalCode : updatedData.shippingPostalCode,
        shippingAdditionalAddressInfo: shippingIsSameAsBilling
          ? updatedData.billingAdditionalAddressInfo
          : updatedData.shippingAdditionalAddressInfo,
        shippingCountry: shippingIsSameAsBilling ? updatedData.billingCountry : updatedData.shippingCountry,
        shippingPackstation: shippingIsSameAsBilling ? updatedData.billingPackstation : updatedData.shippingPackstation,
      };
    });
  };

  const handleSaveAddressChange = async () => {
    if (
      account.addresses.filter(
        (address) =>
          address.firstName === data.billingFirstName &&
          address.streetName === data.billingStreetName &&
          address.postalCode === data.billingPostalCode &&
          address.city === data.billingCity &&
          address.country === data.billingCountry,
      ).length === 0
    ) {
      try {
        await addAddress({
          firstName: data.billingFirstName,
          lastName: data.billingLastName,
          city: data.billingCity,
          postalCode: data.billingPostalCode,
          country: data.billingCountry,
          streetName: data.billingStreetName,
          phone: data.phone,
          packstationNumber: data.billingPackstation,
        });
      } catch (err) {}
    }
  };

  const relevantCountries = useMemo<any>(() => {
    return availableCountryOptions?.filter(
      (country) => countryToCurrency[country] === regionToCurrency[router.locale.split('-')[1]],
    );
  }, [router.locale, availableCountryOptions]);

  return (
    <section aria-labelledby="cart-heading" className="mb-12 bg-white md:rounded lg:col-span-8 lg:col-start-1">
      {/* {JSON.stringify(data, null, 2)} */}
      {!loggedIn && (
        <div className="mx-6 my-4">
          <div className="flex items-center gap-2 text-sm text-[#242424]">
            <p>{formatAccountMessage({ id: 'account.alreadyHave', defaultMessage: 'Already have an account?' })}</p>
            <ReferenceLink
              target={{
                link: !router.asPath.includes('current') ? `/login?current${router.asPath}` : router.asPath,
                target: !router.asPath.includes('current') ? `/login?current${router.asPath}` : router.asPath,
                type: 'link',
                openInNewWindow: false,
              }}
              className="text-primary hover:text-primary dark:text-light-100"
            >
              <span className="cursor-pointer font-medium text-primary underline hover:text-[#33B8A1]">
                {formatAccountMessage({ id: 'account.login.here', defaultMessage: 'Login here' })}
              </span>
            </ReferenceLink>
          </div>
        </div>
      )}

      {account?.addresses.filter(
        (address) => regionToZone[address.country] === regionToZone[router.locale.split('-')[1]],
      ).length > 0 &&
        loggedIn &&
        addressSelector}

      <form className="border-t-4 border-neutral-100 bg-white px-4 py-5 md:border-t-0 md:px-6">
        <h3 className="my-6 text-base font-bold text-[#242424]">
          {formatMessage({ id: 'personalDetails', defaultMessage: 'Your Personal Details' })}
        </h3>
        <div className="flex w-full justify-between">
          <div className="mb-4 w-[48%]">
            <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="firstName">
              <span>{formatCommonMessage({ id: 'firstName', defaultMessage: 'First name' })}</span> *
            </label>
            <input
              className=" w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
              id="billing-first-name"
              data-testid="billing-first-name"
              name="billingFirstName"
              type="text"
              onChange={handleChange}
              value={data.billingFirstName}
              placeholder={formatMessage({ id: 'placeholder.firstName', defaultMessage: 'First Name' })}
              required
            />
          </div>
          <div className="mb-4 w-[48%]">
            <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="lastName">
              <span>{formatCommonMessage({ id: 'lastName', defaultMessage: 'Last name' })}</span> *
            </label>
            <input
              className=" w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
              id="billing-last-name"
              data-testid="billing-last-name"
              name="billingLastName"
              type="text"
              onChange={handleChange}
              value={data.billingLastName}
              placeholder={formatMessage({ id: 'placeholder.lastName', defaultMessage: 'Last Name' })}
            />
          </div>
        </div>
        <div className="flex w-full justify-between">
          <div className="mb-4 w-[48%]">
            <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="email">
              <span>{formatCommonMessage({ id: 'email', defaultMessage: 'Email' })}</span> *
            </label>
            <input
              className=" w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
              id="email"
              data-testid="email"
              name="email"
              type="email"
              onChange={handleChange}
              value={data.email}
              placeholder={formatMessage({ id: 'placeholder.email', defaultMessage: 'Enter your email' })}
            />
          </div>
          <div className="mb-4 w-[48%]">
            <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="phone">
              {formatCommonMessage({ id: 'phone', defaultMessage: 'Phone number' })}
            </label>
            <input
              className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
              id="phone"
              data-testid="phone"
              name="phone"
              type="text"
              onChange={handleChange}
              value={data.phone}
              placeholder={formatMessage({ id: 'placeholder.phone', defaultMessage: 'Enter your phone' })}
            />
          </div>
        </div>
        {/* </TabItem> */}
        {/* <TabItem title={formatMessage({ id: 'billingAddress', defaultMessage: 'Billing address' })}> */}
        <h3 className="my-6 text-base font-bold text-[#242424]">
          {formatMessage({ id: 'billingAddress', defaultMessage: 'Billing address' })}
        </h3>
        <div className="mb-4">
          <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="address">
            <span>{formatCommonMessage({ id: 'street.nameAndNumber', defaultMessage: 'Street name and number' })}</span>{' '}
            *
          </label>
          <input
            className=" w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
            id="billing-street-name"
            data-testid="billing-street-name"
            name="billingStreetName"
            type="text"
            onChange={handleChange}
            value={data.billingStreetName}
            required
            placeholder={formatMessage({ id: 'placeholder.street', defaultMessage: 'Enter street and number' })}
          />
        </div>
        <div className="mb-4">
          <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="address">
            <span>
              {formatCommonMessage({ id: 'street.additionalAddressInfo', defaultMessage: 'Additional Address Info' })}
            </span>{' '}
          </label>
          <input
            className=" w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
            id="billing-additional-address-info"
            data-testid="billing-additional-address-info"
            name="billingAdditionalAddressInfo"
            type="text"
            onChange={handleChange}
            value={data.billingAdditionalAddressInfo}
            placeholder={formatMessage({
              id: 'placeholder.additionalAddress',
              defaultMessage: 'Enter additional address',
            })}
          />
        </div>
        <div className="flex w-full justify-between">
          <div className="mb-4 w-[48%]">
            <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="zip">
              <span>{formatCommonMessage({ id: 'zipCode', defaultMessage: 'Postal code' })}</span> *
            </label>
            <input
              className=" w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
              id="billing-postalCode"
              data-testid="billing-postalCode"
              name="billingPostalCode"
              type="text"
              onChange={handleChange}
              value={data.billingPostalCode}
              required
              placeholder={formatMessage({ id: 'placeholder.postCode', defaultMessage: 'Enter post code' })}
            />
          </div>
          <div className="mb-4 w-[48%]">
            <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="city">
              <span>{formatCommonMessage({ id: 'city', defaultMessage: 'City' })}</span> *
            </label>
            <input
              className=" w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
              id="billing-city"
              data-testid="billing-city"
              name="billingCity"
              type="text"
              onChange={handleChange}
              value={data.billingCity}
              required
              placeholder={formatMessage({ id: 'placeholder.city', defaultMessage: 'Enter city' })}
            />
          </div>
        </div>
        <div className="flex w-full justify-between">
          <div className="mb-4 w-[48%]">
            <label className="text-base font-medium leading-tight text-[#808080]" htmlFor="shipping-country">
              <span>{formatCommonMessage({ id: 'country', defaultMessage: 'Country' })}</span> *
            </label>
            <select
              id="billing-country"
              data-testid="billing-country"
              name="billingCountry"
              className=" mt-2 w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
              onChange={handleChange}
              value={data.billingCountry}
            >
              {relevantCountries?.map((code, i) => (
                <option key={i} value={code} className="text-base font-medium text-[#808080]">
                  {formatCommonMessage({ id: `country.${code}` })}
                </option>
              ))}
            </select>
          </div>
          {data.billingCountry === 'DE' && (
            <div className="mb-4 w-[48%]">
              <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="billing-packstation">
                <span>{formatCommonMessage({ id: 'packstation', defaultMessage: 'Packstation' })}</span>
              </label>
              <input
                className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary disabled:cursor-not-allowed disabled:bg-[#EDEDED]"
                id="billing-packstation"
                data-testid="billing-packstation"
                name="billingPackstation"
                type="text"
                onChange={handleChange}
                value={data.billingPackstation}
                disabled={data.billingCountry !== 'DE'}
                placeholder={formatMessage({ id: 'placeholder.packstation', defaultMessage: 'Enter packstation' })}
              />
            </div>
          )}
        </div>
        {/* </TabItem> */}

        {loggedIn && (
          <>
            <button
              type="button"
              onClick={handleSaveAddressChange}
              className="disabled: my-4 cursor-pointer rounded bg-primary py-2 px-4 text-white hover:bg-[#33B8A1] disabled:cursor-auto disabled:bg-[#EDEDED] disabled:text-[#808080]"
              disabled={
                availableAddresses.length >= 4 ||
                data.billingLastName === '' ||
                data.billingLastName === '' ||
                data.email === '' ||
                data.billingStreetName === '' ||
                data.billingPostalCode === '' ||
                data.billingCity === '' ||
                data.billingCountry === '' ||
                availableAddresses.filter(
                  (address) =>
                    address.firstName === data.billingFirstName &&
                    address.lastName === data.billingLastName &&
                    address.streetName === data.billingStreetName &&
                    address.postalCode === data.billingPostalCode &&
                    address.city === data.billingCity &&
                    address.country === data.billingCountry,
                ).length > 0
              }
            >
              {formatMessage({
                id: 'saveAddress',
                defaultMessage: 'Save Address',
              })}
            </button>
          </>
        )}

        <label className="flex items-center rounded py-4 text-sm">
          <CustomCheckbox
            labelTitle={formatMessage({
              id: 'shippingDetailsLabel',
              defaultMessage: 'Shipping address is the same as billing address',
            })}
            id="shipping-same-as-billing"
            data-testid="shipping-same-as-billing"
            checked={shippingIsSameAsBilling}
            onChange={toggleShippingAddressOption}
          />
        </label>
        {!shippingIsSameAsBilling && (
          <>
            <h3 className="my-6 text-base font-bold text-[#242424]">
              {formatMessage({ id: 'shippingAddress', defaultMessage: 'Shipping Address' })}
            </h3>
            <div className="flex w-full justify-between">
              <div className="mb-4 w-[48%]">
                <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="firstName">
                  <span>{formatCommonMessage({ id: 'firstName', defaultMessage: 'First name' })}</span> *
                </label>
                <input
                  className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                  id="shipping-first-name"
                  data-testid="shipping-first-name"
                  name="shippingFirstName"
                  type="text"
                  onChange={handleChange}
                  value={data.shippingFirstName}
                  placeholder={formatMessage({ id: 'placeholder.firstName', defaultMessage: 'First Name' })}
                  required
                />
              </div>

              <div className="mb-4 w-[48%]">
                <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="lastName">
                  <span>{formatCommonMessage({ id: 'lastName', defaultMessage: 'Last name' })}</span> *
                </label>
                <input
                  className=" w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                  id="shipping-last-name"
                  data-testid="shipping-last-name"
                  name="shippingLastName"
                  type="text"
                  onChange={handleChange}
                  value={data.shippingLastName}
                  placeholder={formatMessage({ id: 'placeholder.lastName', defaultMessage: 'Last Name' })}
                />
              </div>
            </div>

            <div className="mb-4">
              <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="address">
                <span>
                  {formatCommonMessage({ id: 'street.nameAndNumber', defaultMessage: 'Street name and number' })}
                </span>{' '}
                *
              </label>
              <input
                className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                id="shipping-street-name"
                data-testid="shipping-street-name"
                name="shippingStreetName"
                type="text"
                onChange={handleChange}
                value={data.shippingStreetName}
                required
                placeholder={formatMessage({ id: 'placeholder.street', defaultMessage: 'Enter street and number' })}
              />
            </div>

            <div className="mb-4">
              <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="additionalAddressInfo">
                <span>
                  {formatCommonMessage({
                    id: 'street.additionalAddressInfo',
                    defaultMessage: 'Additional Address Info',
                  })}
                </span>{' '}
              </label>
              <input
                className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                id="shipping-additional-address-info"
                data-testid="shipping-additional-address-info"
                name="shippingAdditionalAddressInfo"
                type="text"
                onChange={handleChange}
                value={data.shippingAdditionalAddressInfo}
                placeholder={formatMessage({
                  id: 'placeholder.additionalAddress',
                  defaultMessage: 'Enter additional address',
                })}
              />
            </div>

            <div className="flex w-full justify-between">
              <div className="mb-4 w-[48%]">
                <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="zip">
                  <span>{formatCommonMessage({ id: 'zipCode', defaultMessage: 'Postal code' })}</span> *
                </label>
                <input
                  className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                  id="shipping-postalCode"
                  data-testid="shipping-postalCode"
                  name="shippingPostalCode"
                  type="text"
                  onChange={handleChange}
                  value={data.shippingPostalCode}
                  required
                  placeholder={formatMessage({ id: 'placeholder.postCode', defaultMessage: 'Enter post code' })}
                />
              </div>
              <div className="mb-4 w-[48%]">
                <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="city">
                  <span>{formatCommonMessage({ id: 'city', defaultMessage: 'City' })}</span> *
                </label>
                <input
                  className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                  id="shipping-city"
                  data-testid="shipping-city"
                  name="shippingCity"
                  type="text"
                  onChange={handleChange}
                  value={data.shippingCity}
                  required
                  placeholder={formatMessage({ id: 'placeholder.city', defaultMessage: 'Enter city' })}
                />
              </div>
            </div>

            <div className="flex w-full justify-between">
              <div className="mb-4 w-[48%]">
                <label className="text-base font-medium leading-tight text-[#808080]" htmlFor="shipping-country">
                  <span>{formatCommonMessage({ id: 'country', defaultMessage: 'Country' })}</span> *
                </label>
                <select
                  id="shipping-country"
                  data-testid="shipping-country"
                  name="shippingCountry"
                  className="mt-2 w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                  onChange={handleChange}
                  value={data.shippingCountry}
                >
                  {countryMapper !== undefined
                    ? countryMapper[data?.billingCountry].split(' ')?.map((code, i) => (
                        <option key={i} value={code}>
                          {formatCommonMessage({ id: `country.${code}` })}
                        </option>
                      ))
                    : currentShippingMethod && currentShippingMethod?.billingCountry
                    ? currentShippingMethod?.billingCountry[data?.billingCountry].split(' ')?.map((code, i) => (
                        <option key={i} value={code}>
                          {formatCommonMessage({ id: `country.${code}` })}
                        </option>
                      ))
                    : relevantCountries?.map((code, i) => (
                        <option key={i} value={code}>
                          {formatCommonMessage({ id: `country.${code}` })}
                        </option>
                      ))}
                </select>
              </div>
              {data.shippingCountry === 'DE' && (
                <div className="mb-4 w-[48%]">
                  <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="shipping-packstation">
                    <span>{formatCommonMessage({ id: 'packstation', defaultMessage: 'Packstation' })}</span>
                  </label>
                  <input
                    className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary disabled:cursor-not-allowed disabled:bg-[#EDEDED]"
                    id="shipping-packstation"
                    data-testid="shipping-packstation"
                    name="shippingPackstation"
                    type="text"
                    onChange={handleChange}
                    value={data.shippingPackstation}
                    disabled={data.shippingCountry !== 'DE'}
                    placeholder={formatMessage({ id: 'placeholder.packstation', defaultMessage: 'Enter packstation' })}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </form>
      {children}
    </section>
  );
};

export default AddressPanel;

export const TabItem = ({
  title,
  tabOpened = false,
  children,
}: {
  title: string;
  tabOpened?: boolean;
  children: React.ReactNode;
}) => {
  const [tabOpen, setTabOpen] = useState(tabOpened);

  return (
    <div className="border-b border-[#e7e7e7]">
      <button
        type="button"
        onClick={() => {
          setTabOpen(!tabOpen);
        }}
        className="my-4 flex w-full items-center justify-between text-left"
      >
        <span className={`text-base ${tabOpen ? 'font-bold text-[#242424]' : 'font-medium text-[#808080]'}`}>
          {title}
        </span>
        <span className="text-xl font-medium text-black">
          {tabOpen ? (
            <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8657 6.42754L8.01802 1.82536L8.01338 1.82105C7.77576 1.60513 7.48847 1.50883 7.20531 1.50883C6.90685 1.50883 6.62306 1.63403 6.39721 1.83925L1.54517 6.42733L1.54182 6.43071C1.32433 6.65031 1.26347 7.03715 1.52375 7.29993C1.74024 7.51851 2.12086 7.5804 2.38105 7.3218L7.20531 2.74182L12.0372 7.32904L12.0422 7.33341C12.2793 7.53853 12.6496 7.53954 12.8869 7.29993L12.8947 7.292L12.902 7.28349C13.1051 7.04419 13.1061 6.67032 12.8688 6.43071L12.8657 6.42754ZM7.02433 2.51279L2.18016 7.1117C2.1737 7.11823 2.16705 7.12437 2.16023 7.13013C2.16697 7.12442 2.17355 7.11834 2.17994 7.11189L7.02433 2.51279ZM12.6609 7.11336C12.6682 7.10716 12.6753 7.10059 12.6822 7.09364C12.7906 6.96589 12.7906 6.76513 12.6641 6.63738L7.81968 2.03828C7.63892 1.87403 7.42201 1.80103 7.20509 1.80103C6.99754 1.80103 6.78999 1.88457 6.61411 2.03567C6.79004 1.88445 6.99768 1.80084 7.20531 1.80084C7.42223 1.80084 7.63914 1.87384 7.8199 2.03809L12.6643 6.63719C12.7908 6.76495 12.7908 6.9657 12.6824 7.09345C12.6754 7.10047 12.6682 7.10711 12.6609 7.11336Z"
                fill="#242424"
              />
            </svg>
          ) : (
            <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.1899 2.56684L7.16252 7.1826L7.16729 7.18692C7.41103 7.40348 7.70573 7.50006 7.99619 7.50006C8.30235 7.50006 8.59345 7.37449 8.82512 7.16866L13.8023 2.56704L13.8057 2.56365C14.0288 2.34341 14.0912 1.95543 13.8242 1.69187C13.6022 1.47264 13.2117 1.41057 12.9448 1.66993L7.99619 6.26344L3.03972 1.66267L3.03454 1.65829C2.79141 1.45257 2.41156 1.45155 2.16813 1.69187L2.16007 1.69982L2.15266 1.70836C1.94426 1.94837 1.94323 2.32334 2.18667 2.56365L2.1899 2.56684ZM8.18164 6.4933L13.1509 1.88065C13.1575 1.87411 13.1644 1.86795 13.1714 1.86217C13.1644 1.86789 13.1577 1.87399 13.1511 1.88046L8.18183 6.49314C8.18177 6.49319 8.1817 6.49325 8.18164 6.4933ZM8.5982 6.97537C8.41869 7.1247 8.20744 7.20719 7.99619 7.20719C7.77368 7.20719 7.55117 7.13398 7.36575 6.96924L2.39645 2.35656C2.26666 2.22843 2.26666 2.02708 2.37791 1.89895C2.38514 1.89182 2.39259 1.88508 2.40026 1.87874C2.39267 1.88503 2.38529 1.8917 2.37813 1.89877C2.26688 2.0269 2.26688 2.22824 2.39668 2.35637L7.36598 6.96905C7.5514 7.13379 7.77391 7.20701 7.99641 7.20701C8.20757 7.20701 8.41874 7.12458 8.5982 6.97537Z"
                fill="#808080"
              />
            </svg>
          )}
        </span>
      </button>
      <div className={`py-6 ${!tabOpen && 'hidden'}`}>{children}</div>
    </div>
  );
};

export const CustomCheckbox = ({ labelTitle, id, checked, onChange }) => {
  return (
    <div className="w-fit">
      <div className="mb-4">
        <label htmlFor={id} className="text-body-color flex cursor-pointer select-none items-center">
          <div className="relative">
            <input type="checkbox" id={id} className="sr-only" checked={checked} onChange={onChange} />
            <div
              className={`box mr-4 flex h-5 w-5 items-center justify-center rounded ${
                checked ? 'bg-primary' : 'border border-[#E7E7E7] bg-white'
              }`}
            >
              {checked ? (
                <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.4"
                  ></path>
                </svg>
              ) : null}
            </div>
          </div>
          <span className="text-[#808080]">{labelTitle}</span>
        </label>
      </div>
    </div>
  );
};
