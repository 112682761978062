import React, { useEffect, useState } from 'react';
import { Media } from '../CZ11Cta/CZ11Cta';
import { strapiConfig } from 'helpers/strapiConfig';
import { ReferenceLink } from 'helpers/reference';
import Image from 'frontastic/lib/image';
import parse from 'html-react-parser';
import useTransformImageSrc from 'helpers/hooks/useTransformImageSrc';

type CZ17AboutUsItemProps = {
  title: string;
  subtitle?: string;
  text: string;
  aboutUsList: AboutUsItem[];
};

export type AboutUsItem = {
  title?: string;
  subtitle?: string;
  image: Media;
  imageTitle: string;
  imageAlt: string;
  imageMobile?: Media;
  linkUrl?: string;
  targetBlank: boolean;
};

export const CZ17AboutUs = ({ title, subtitle, text, aboutUsList }: CZ17AboutUsItemProps) => {
  return (
    <section className="container mx-auto max-w-[1280px] py-10 px-4 font-urbanist sm:px-10 lg:px-4">
      <div className="-mx-4 flex flex-wrap">
        <div className="w-full px-4 md:w-1/2">
          <div className="mb-[60px] max-w-[510px]">
            <h4 className="mb-2 text-2xl font-bold text-primary">{subtitle}</h4>
            <h2 className="mb-4 text-4xl font-semibold leading-[1.208] text-black sm:text-4xl md:text-[40px]">
              {title}
            </h2>
            <div className="text-base text-[#808080]">{parse(text)}</div>
          </div>
          {aboutUsList
            .filter((item) => aboutUsList.indexOf(item) % 2 !== 0)
            .map((item, idx) => (
              <AboutUsCard
                key={idx}
                img={`${strapiConfig.url}${item?.image?.data?.attributes?.url}`}
                imgAlt={item.imageAlt}
                subtitle={item.subtitle}
                title={item.title}
                link={item.linkUrl}
                targetBlank={item.targetBlank}
              />
            ))}
        </div>
        <div className="w-full px-4 md:w-1/2">
          {aboutUsList
            .filter((item) => aboutUsList.indexOf(item) % 2 === 0)
            .map((item, idx) => (
              <AboutUsCard
                key={idx}
                img={`${strapiConfig.url}${item?.image?.data?.attributes?.url}`}
                imgAlt={item.imageAlt}
                subtitle={item.subtitle}
                title={item.title}
                link={item.linkUrl}
                targetBlank={item.targetBlank}
              />
            ))}
        </div>
      </div>
    </section>
  );
};

const AboutUsCard = ({ img, imgAlt, subtitle, link, title, targetBlank }) => {
  const imgSrc = useTransformImageSrc(img);
  return (
    <div className="mb-[60px] mr-0 lg:mr-4">
      {img && (
        <div className="mb-9 h-[340px] overflow-hidden rounded">
          <Image src={imgSrc} layout="fill" className="h-full w-full object-cover" alt={imgAlt} />
        </div>
      )}
      <div>
        <h4 className="mb-2 text-sm font-semibold text-primary">{subtitle}</h4>
        {link !== '' ? (
          <ReferenceLink
            target={{
              link: link,
              target: link,
              type: 'link',
              openInNewWindow: targetBlank,
            }}
          >
            <h3 className="cursor-pointer text-2xl font-bold text-[#212B36] hover:text-primary">{title}</h3>
          </ReferenceLink>
        ) : (
          <h3 className="text-2xl font-bold text-[#212B36]">{title}</h3>
        )}
      </div>
    </div>
  );
};
