import React, { FC } from 'react';
import { DataTestId } from '@Types/playwright';
import { useSortBy, UseSortByProps } from 'react-instantsearch';
import { Dropdown } from 'components/tailgrids-ui/products/product-filter';

type SortByDropdownProps = UseSortByProps & DataTestId;

export const SortByDropdown: FC<SortByDropdownProps> = (props) => {
  const { refine, currentRefinement, options, canRefine } = useSortBy(props);
  const {} = props;

  return (
    <Dropdown
      onChange={(value) => {
        if (canRefine) {
          refine(value);
        }
      }}
      options={options.map((option) => {
        return {
          value: option.value,
          label: option.label,
          isSelected: option.value === currentRefinement,
        };
      })}
      data-testid={props['data-testid']}
    />
  );
};
