import React from 'react';
import { GiftIcon } from '@heroicons/react/outline';
import { Cart } from '@Types/cart/Cart';
import Price from 'components/commercetools-ui/price';
import { useFormat } from 'helpers/hooks/useFormat';
import { StringHelpers } from 'helpers/stringHelpers';
import Image from 'frontastic/lib/image';
import { CustomImage } from 'lib/customImage';

export interface Props {
  readonly cart: Cart;
}

const CartItems: React.FC<Props> = ({ cart }) => {
  const { formatMessage } = useFormat({ name: 'checkout' });

  return (
    <ul role="list" className="flex-auto divide-y divide-gray-200 overflow-y-auto">
      {cart?.lineItems?.map((lineItem, i) => (
        <li key={i} className="flex items-end justify-between py-6">
          <div className="flex w-full items-start space-x-4">
            {/* <Image
              src={lineItem.variant.images[0]}
              alt={lineItem.name}
              className="h-auto w-20 flex-none rounded-[2px] border border-neutral-200 bg-gray-200 object-contain"
            /> */}
            <div className="h-[100px] min-w-[100px] rounded-[4px] border border-neutral-200">
              <CustomImage imgSrc={lineItem.variant.images[0]} width={100} height={100} />
            </div>
            <div className="flex w-full flex-col justify-between space-y-4">
              <div className="w-full space-y-1">
                <h3 className="text-base font-semibold text-[#242424] ">
                  <span className="pr-2">{`${lineItem.count}x`}</span> {lineItem.name}
                </h3>
                <div className="flex w-full items-end justify-between">
                  <div>
                    {lineItem.variant.attributes?.color && (
                      <p className="text-sm font-normal text-[#808080]">
                        {formatMessage({ id: 'thankyou.color', defaultMessage: 'Color' })}{' '}
                        {StringHelpers.capitaliseFirstLetter(lineItem.variant.attributes.color.label)}
                      </p>
                    )}
                    {lineItem.variant.attributes?.commonSize &&
                      lineItem.variant.attributes.commonSize.key.toLowerCase() !== 'onesize' && (
                        <p className="text-sm font-normal capitalize text-[#808080]">
                          {formatMessage({ id: 'thankyou.size', defaultMessage: 'Size' })}{' '}
                          {lineItem.variant.attributes.commonSize.key.toUpperCase()}
                        </p>
                      )}
                    {lineItem.count && (
                      <p className="text-sm font-normal capitalize text-[#808080]">
                        {' '}
                        {formatMessage({ id: 'thankyou.quantity', defaultMessage: 'Quantity' })} {lineItem.count}
                      </p>
                    )}
                    {lineItem.isGift && (
                      <div className="my-2 inline-flex text-sm font-normal text-[#808080]">
                        <GiftIcon className="h-5 w-5" aria-hidden="true" />
                      </div>
                    )}
                  </div>
                  {/* <Price price={lineItem.price || {}} className="text-base font-semibold text-[#242424]" /> */}
                  {lineItem?.discounts.length > 0 ? (
                    <div className="flex flex-col items-end">
                      <Price
                        price={{
                          centAmount: lineItem?.price.centAmount - lineItem?.discounts[0].discountedAmount.centAmount,
                          currencyCode: lineItem?.price.currencyCode,
                          fractionDigits: lineItem?.price.fractionDigits,
                        }}
                        className="text-lg font-semibold text-gray-900"
                      />
                      <Price
                        price={lineItem?.price}
                        className="pl-6 text-sm font-medium text-[#808080] line-through decoration-[#808080] decoration-1"
                      />
                    </div>
                  ) : (
                    <Price price={lineItem?.price} className=" text-lg font-semibold text-gray-900" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default CartItems;
