import React from 'react';

type Props = {
  className?: string;
};

const TrashCanIcon: React.FC<Props> = ({ className }: Props) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2812 3.25H12.875V2.71875C12.875 1.78125 12.125 1.03125 11.1875 1.03125H8.78125C7.84375 1.03125 7.09375 1.78125 7.09375 2.71875V3.25H4.6875C3.78125 3.25 3.03125 4 3.03125 4.90625V5.84375C3.03125 6.53125 3.4375 7.09375 4.03125 7.34375L4.53125 18.1562C4.59375 19.1875 5.40625 19.9688 6.4375 19.9688H13.4687C14.5 19.9688 15.3438 19.1562 15.375 18.1562L15.9375 7.3125C16.5312 7.0625 16.9375 6.46875 16.9375 5.8125V4.875C16.9375 4 16.1875 3.25 15.2812 3.25ZM8.53125 2.71875C8.53125 2.5625 8.65625 2.4375 8.8125 2.4375H11.2188C11.375 2.4375 11.5 2.5625 11.5 2.71875V3.25H8.5625V2.71875H8.53125ZM4.46875 4.90625C4.46875 4.78125 4.5625 4.65625 4.71875 4.65625H15.2812C15.4062 4.65625 15.5312 4.75 15.5312 4.90625V5.84375C15.5312 5.96875 15.4375 6.09375 15.2812 6.09375H4.71875C4.59375 6.09375 4.46875 6 4.46875 5.84375V4.90625ZM13.5 18.5625H6.5C6.21875 18.5625 6 18.3438 6 18.0938L5.5 7.5H14.5312L14.0312 18.0938C14 18.3438 13.7812 18.5625 13.5 18.5625Z"
      fill="#808080"
    />
    <path
      d="M10 10.2188C9.625 10.2188 9.28125 10.5313 9.28125 10.9375V15.7813C9.28125 16.1563 9.59375 16.5 10 16.5C10.375 16.5 10.7187 16.1875 10.7187 15.7813V10.9062C10.7187 10.5312 10.375 10.2188 10 10.2188Z"
      fill="#808080"
    />
    <path
      d="M12.9065 10.8125C12.5315 10.7812 12.1877 11.0937 12.1565 11.4687L11.969 15.0625C11.9377 15.4375 12.2502 15.7812 12.6252 15.8125H12.6565C13.0315 15.8125 13.344 15.5312 13.344 15.1562L13.5315 11.5625C13.594 11.1562 13.2815 10.8437 12.9065 10.8125Z"
      fill="#808080"
    />
    <path
      d="M7.06271 10.8125C6.6877 10.8437 6.3752 11.1562 6.40645 11.5625L6.62521 15.1875C6.65646 15.5625 6.96895 15.8437 7.3127 15.8437H7.34396C7.71896 15.8125 8.03146 15.5 8.00021 15.0937L7.81271 11.4687C7.78146 11.0937 7.43771 10.7812 7.06271 10.8125Z"
      fill="#808080"
    />
  </svg>
);

export default TrashCanIcon;
