import React, { useState } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import Address from '../address';
import CreateAddressModal from '../modals/createAddress';

const Addresses = () => {
  //18in messages
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  //account data
  const { account } = useAccount();

  //user addresses
  const addresses = account?.addresses;

  //create address modal
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const openCreateModal = () => {
    setCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalOpen(false);
  };

  return (
    <>
      <div className="mt-10">
        <div>
          <div className="space-y-1">
            <h3 className="my-6 text-lg font-bold text-[#242424]">
              {formatAccountMessage({ id: 'address.myAddresses', defaultMessage: 'My Addresses' })}{' '}
            </h3>
            <p className="max-w-2xl text-base font-medium text-gray-500">
              {formatAccountMessage({
                id: 'address.desc',
                defaultMessage: 'Manage or add delivery addresses for your account',
              })}
            </p>
          </div>

          <div className="mt-4 flex justify-start md:mt-0 md:justify-end">
            <button
              type="button"
              className="flex w-full justify-center rounded border border-transparent bg-primary py-3 px-8 text-sm font-medium text-white transition-colors duration-200 ease-out hover:bg-[#33B8A1] focus:outline-none disabled:bg-[#808080] md:w-fit"
              onClick={openCreateModal}
            >
              {formatAccountMessage({ id: 'address.add', defaultMessage: 'Add an address' })}
            </button>
          </div>
        </div>
        <div className="mt-4">
          <p className="my-6 text-base font-bold text-[#242424]">
            {formatAccountMessage({ id: 'billingAddress', defaultMessage: 'Billing Address' })}
          </p>
          <dl className="flex flex-col items-stretch lg:gap-4">
            {addresses
              ?.filter((address) => address.isDefaultBillingAddress)
              .map((address) => (
                <Address key={address.addressId} address={address} />
              ))}
          </dl>
        </div>
        <div>
          <p className="my-6 text-base font-bold text-[#242424]">
            {formatAccountMessage({ id: 'shippingAddress', defaultMessage: 'Shipping Address' })}
          </p>
          <dl className="flex flex-col items-stretch lg:gap-4">
            {addresses
              ?.filter((address) => address.isDefaultShippingAddress)
              .map((address) => (
                <Address key={address.addressId} address={address} />
              ))}
          </dl>
        </div>
        {addresses?.filter((address) => !address.isDefaultShippingAddress && !address.isDefaultBillingAddress).length >
          0 && (
          <div>
            <p className="my-6 text-base font-bold text-[#242424]">
              {formatAccountMessage({ id: 'otherAddresses', defaultMessage: 'Other Addresses' })}
            </p>
            <dl className="flex flex-col items-stretch lg:gap-4">
              {addresses
                ?.filter((address) => !address.isDefaultShippingAddress && !address.isDefaultBillingAddress)
                .map((address) => (
                  <Address key={address.addressId} address={address} />
                ))}
            </dl>
          </div>
        )}
        {/* <div className="block border-t border-gray-200 pt-7 lg:hidden">
          <button
            type="button"
            className="flex w-full justify-center rounded border border-transparent bg-primary py-3 px-8 text-sm font-medium text-white transition-colors duration-200 ease-out hover:bg-[#33B8A1] focus:outline-none disabled:bg-[#808080]"
            onClick={openCreateModal}
          >
            {formatAccountMessage({ id: 'address.add', defaultMessage: 'Add an address' })}
          </button>
        </div> */}
      </div>
      <CreateAddressModal open={createModalOpen} onClose={closeCreateModal} />
    </>
  );
};

export default Addresses;
