import { MouseEvent, useEffect } from 'react';
import { GiftIcon } from '@heroicons/react/outline';
import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { useTranslation, Trans } from 'next-i18next';
import DiscountForm from 'components/commercetools-ui/discount-form';
import Price from 'components/commercetools-ui/price';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';
import { useCart } from 'frontastic';
interface Props {
  readonly cart: Cart;
  readonly onSubmit?: (e: MouseEvent) => void;
  readonly submitButtonLabel?: string;
  readonly disableSubmitButton?: boolean;
  readonly showSubmitButton?: boolean;
  readonly showDiscountsForm?: boolean;

  termsLink?: Reference;
  cancellationLink?: Reference;
  privacyLink?: Reference;
}

const OrderSummary = ({
  cart,
  onSubmit,
  showSubmitButton = true,
  showDiscountsForm = true,
  submitButtonLabel,
  disableSubmitButton,
  termsLink,
  cancellationLink,
  privacyLink,
}: Props) => {
  //i18n messages
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { t } = useTranslation(['checkout']);

  const submitButtonClassName = `${disableSubmitButton ? 'opacity-75 pointer-events-none' : ''} ${
    !showDiscountsForm ? 'mt-7' : ''
  } w-full rounded-sm border border-transparent py-3 px-4 text-base shadow-sm font-medium text-white bg-primary hover:bg-opacity-90 focus:outline-none`;

  const interpolatedComponents = [
    <ReferenceLink key={0} className="cursor-pointer font-medium text-accent-500 hover:underline" target={termsLink} />,
    <ReferenceLink
      key={1}
      className="cursor-pointer font-medium text-accent-500 hover:underline"
      target={cancellationLink}
    />,
    <ReferenceLink
      key={2}
      className="cursor-pointer font-medium text-accent-500 hover:underline"
      target={privacyLink}
    />,
  ];

  const totalTaxes = cart?.taxed?.taxPortions?.reduce((a, b) => a + b.amount.centAmount, 0);

  const productPrice = cart?.lineItems?.reduce((a, b: LineItem) => {
    if (b.discountedPrice) {
      return a + b.discountedPrice.centAmount * b.count;
    } else {
      return a + b.price.centAmount * b.count;
    }
  }, 0);

  const discountPrice = cart?.lineItems?.reduce((a, b) => {
    const dp =
      a +
      b.count *
        b.discounts.reduce((x, y) => {
          return x + y.discountedAmount.centAmount;
        }, 0);
    return productPrice - cart.sum.centAmount + (cart?.shippingInfo?.price.centAmount ?? 0);
  }, 0);

  const remainingPrice =
    discountPrice > 0 ? cart?.sum?.centAmount - productPrice + discountPrice : cart?.sum?.centAmount - productPrice;

  return (
    <section aria-labelledby="summary-heading" className="sm:col-span-8 lg:col-span-4">
      {/* {showDiscountsForm && <DiscountForm cart={cart} />} */}
      <DiscountForm cart={cart} />
      {productPrice !== undefined ? (
        <dl className="rounded-t bg-white px-0 lg:pl-4">
          <div className="flex items-center justify-between">
            <dt className="text-xs font-normal text-[#808080]">
              {formatCartMessage({ id: 'subtotal', defaultMessage: 'Subtotal' })}
            </dt>
            <dd>
              <Price
                price={{
                  centAmount: productPrice,
                  currencyCode: cart?.sum?.currencyCode,
                }}
                className="text-xs font-normal text-[#242424]"
              />
            </dd>
          </div>

          {cart?.shippingInfo && (
            <div>
              <div className="flex items-center justify-between pt-4">
                <dt className="flex items-center text-xs font-normal text-[#808080]">
                  <span>{formatCartMessage({ id: 'shipping.estimate', defaultMessage: 'Shipping estimate' })}</span>
                </dt>
                <dd>
                  <Price price={cart?.shippingInfo?.price || {}} className="text-xs font-normal text-[#242424]" />
                </dd>
              </div>
              {cart?.shippingInfo?.price.centAmount !== remainingPrice && (
                <div className="flex items-center justify-between pt-4">
                  <dt className="flex items-center text-xs font-normal text-[#808080]">
                    <span>{formatCartMessage({ id: 'shipping.discount', defaultMessage: 'Shipping discount' })}</span>
                  </dt>
                  <dd>
                    <Price
                      price={{
                        centAmount: -remainingPrice,
                        currencyCode: cart?.sum?.currencyCode,
                      }}
                      className={`text-sm font-medium ${
                        cart?.shippingInfo?.price.centAmount === remainingPrice ? 'text-gray-900' : 'text-pink-600'
                      } dark:text-light-100`}
                    />
                  </dd>
                </div>
              )}
            </div>
          )}

          <div className="flex items-center justify-between pt-4">
            <dt
              className={`text-xs font-normal ${cart?.discountCodes?.length > 0 ? 'text-[#33B8A1]' : 'text-[#808080]'}`}
            >
              {formatCartMessage({ id: 'codesApplied', defaultMessage: 'Codes Applied' })}
            </dt>
            <dd
              className={`text-right text-xs font-normal ${
                cart?.discountCodes?.length > 0 ? 'text-[#33B8A1]' : 'text-[#242424]'
              }`}
            >
              <ul>
                {cart.discountCodes.length > 0 ? (
                  cart.discountCodes.map((code, idx) => <li key={idx}>{code.code}</li>)
                ) : (
                  <li>-</li>
                )}
              </ul>
            </dd>
          </div>

          {cart.lineItems.filter((item) => item.isGift).length > 0 && (
            <div className="flex items-center justify-between pt-4">
              <dt className="text-xs font-normal text-[#808080]">
                {formatCartMessage({ id: 'gift', defaultMessage: 'Gift Added' })}
              </dt>
              <dd className="text-sm text-pink-600">
                <div className="inline-flex text-pink-400">
                  <GiftIcon className="h-5 w-5" aria-hidden="true" />
                </div>
              </dd>
            </div>
          )}

          <div className="flex items-center justify-between pt-4">
            <dt className="flex text-xs font-normal text-[#808080]">
              <span>{formatCartMessage({ id: 'discounts', defaultMessage: 'Discounts' })}</span>
            </dt>
            <dd>
              <Price
                price={
                  {
                    fractionDigits: 2,
                    centAmount: discountPrice === 0 || discountPrice === undefined ? 0 : -discountPrice,
                    currencyCode: cart?.sum?.currencyCode,
                  } || {}
                }
                className={
                  discountPrice === 0 ? `text-xs font-normal text-[#242424]` : `text-xs font-normal text-[#242424]`
                }
              />
            </dd>
          </div>

          <div className="flex items-center justify-between pt-4">
            <dt className="text-base font-medium text-[#808080]">
              {formatCartMessage({ id: 'orderTotal', defaultMessage: 'Order total' })}
            </dt>
            <dd>
              <Price price={cart?.sum || {}} className="text-base font-semibold text-[#242424]" />
            </dd>
          </div>

          {cart?.taxed && (
            <div className="text-xs text-gray-500 dark:text-light-100">
              (
              {formatCartMessage({
                id: 'includedVat',
                defaultMessage: 'Tax included',
                values: { amount: CurrencyHelpers.formatForCurrency(totalTaxes || {}) },
              })}
              )
            </div>
          )}
        </dl>
      ) : (
        <dl className="mt-6 space-y-4 text-pink-600">
          {formatCartMessage({ id: 'codeNotValid', defaultMessage: 'Code is not valid' })}
        </dl>
      )}
      {/* {showDiscountsForm && <DiscountForm cart={cart} className="py-10" />} */}
      {showSubmitButton && (
        <div className="w-full rounded-b bg-white px-0 pb-7 lg:pl-4">
          <button
            type="submit"
            onClick={onSubmit}
            className={`${submitButtonClassName} rounded-[4px]`}
            data-testid="to-checkout"
          >
            {submitButtonLabel || formatCartMessage({ id: 'checkout.go', defaultMessage: 'Go to checkout' })}
          </button>
          {submitButtonLabel === formatCartMessage({ id: 'ContinueAndPay', defaultMessage: 'Continue and pay' }) && (
            <p className="px-1 py-5 text-center text-xs">
              <Trans i18nKey="disclaimer" t={t} components={interpolatedComponents} />
            </p>
          )}
        </div>
      )}
    </section>
  );
};

export default OrderSummary;
