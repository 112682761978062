import React, { FC } from 'react';
import { ProductProps, ProductGridItem } from '.';

export const ProductGrid: FC<ProductProps> = ({ products, locale, className = '', fallback = false }) => {
  const filteredProducts = products.filter((product) => product.variants.length > 0);
  return (
    <div
      className={`relative grid grid-cols-1 gap-8 rounded bg-white ${
        fallback
          ? 'sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4'
          : 'sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3'
      } ${className}`}
    >
      {filteredProducts.map((product) => (
        <ProductGridItem key={product.id} locale={locale} {...product} />
      ))}
    </div>
  );
};
