import React from 'react';
import { Product } from '@Types/product/Product';
import { UpsellProducts } from 'features/typesense/components';

const UpsellProductsTastic = ({ data }) => {
  const product: Product = data?.data?.dataSource?.product;

  if (product) {
    return (
      <UpsellProducts
        title={data?.title}
        product_results={[
          {
            type: 'relation',
            related_products: [
              {
                sku: product?.variants?.[0].sku,
                productId: product.productId,
                gender: product?.variants?.[0].attributes?.['gender']?.key,
              },
            ],
          },
        ]}
      />
    );
  }

  return null;
};

export default UpsellProductsTastic;
