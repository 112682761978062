import React, { useCallback, useEffect, useState } from 'react';
import { Media } from '../CZ11Cta/CZ11Cta';
import { EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { DotButton, useDotButton } from './EmblaCarouselDotButtons';
import { NextButton, PrevButton, usePrevNextButtons } from './EmblaCarouselArrowButtons';
import ClassNames from 'embla-carousel-class-names';
import Image from 'frontastic/lib/image';
import { strapiConfig } from 'helpers/strapiConfig';
import Link from 'next/link';
import { ReferenceLink } from 'helpers/reference';
import useTransformImageSrc from 'helpers/hooks/useTransformImageSrc';

type CZ13ImageWithText = {
  title?: string;
  imageGalleryList: ImageGalleryItem[];
};

export type ImageGalleryItem = {
  text?: string;
  image: Media;
  imageAlt?: string;
  imageTitle?: string;
  linkUrl?: string;
  linkTargetBlank?: boolean;
};

function CZ14ImageGallery({ title, imageGalleryList }: CZ13ImageWithText) {
  const [emblaRef, emblaApi] = useEmblaCarousel({ align: 'start' }, [ClassNames()]);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

  return (
    <section className="embla container mx-auto max-w-[1280px] py-10 px-4 font-urbanist sm:px-10 lg:px-4">
      {title && <h2 className="mb-8 text-4xl font-semibold text-[#222]">{title}</h2>}
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {imageGalleryList.map((item, idx) => (
            <div className="embla__slide embla__class-names" key={idx}>
              <GalleryImage imgSrc={item.image.data.attributes.url} imgAlt={item.imageAlt} />
              {item.linkUrl !== '' ? (
                <ReferenceLink
                  target={{
                    link: item.linkUrl,
                    target: item.linkUrl,
                    type: 'link',
                    openInNewWindow: item?.linkTargetBlank,
                  }}
                >
                  <p className="mt-4 cursor-pointer text-sm font-bold text-[#4C4C4C]">{item.text}</p>
                </ReferenceLink>
              ) : (
                <p className="mt-4 text-sm font-bold text-[#4C4C4C]">{item.text}</p>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="embla__controls">
        <div className="embla__dots">
          {imageGalleryList.map((_, index) => (
            <div
              onClick={() => onDotButtonClick(index)}
              className={`h-3 w-3 cursor-pointer rounded-full ${
                index === selectedIndex ? 'bg-[#2A5967]' : 'bg-[#EDEDED]'
              }`}
              key={index}
            ></div>
          ))}
        </div>
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>
      </div>
    </section>
  );
}

const GalleryImage = ({ imgSrc, imgAlt }: { imgSrc: string; imgAlt?: string }) => {
  const src = useTransformImageSrc(`${strapiConfig.url}${imgSrc}`);

  return <Image src={src} layout="fill" className="embla__slide__img" alt={imgAlt ?? ''} />;
};

export default CZ14ImageGallery;
