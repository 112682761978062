import React, { useState } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';

const Security = () => {
  //account data
  const { changePassword } = useAccount();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });

  //new passwords
  const initialData = { oldPassword: '', password: '', confirmPassword: '' };
  const [data, setData] = useState(initialData);

  //error
  const [error, setError] = useState('');

  //success
  const [success, setSuccess] = useState('');

  //loading..
  const [processing, setProcessing] = useState(false);

  //input change handler
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  //submission handler
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //loading starts
    setProcessing(true);
    //try updating user's password
    try {
      const response = await changePassword(data.oldPassword, data.password);
      if (response.accountId) {
        setSuccess(
          formatAccountMessage({ id: 'password.change.success', defaultMessage: 'Password successfully changed!' }),
        );
        setError('');
      } else {
        setError(
          formatAccountMessage({
            id: 'password.change.exception',
            defaultMessage: "Sorry, we couldn't fulfill your request",
          }),
        );
        setSuccess('');
      }
    } catch (err) {
      setError(formatAccountMessage({ id: 'password.change.wrong', defaultMessage: 'Wrong password entered' }));
      setSuccess('');
    } finally {
      setProcessing(false);
      setData(initialData);
    }
  };

  //save button disabled
  const submitDisabled =
    !(data.password && data.confirmPassword && data.oldPassword) || data.password !== data.confirmPassword;

  return (
    <div>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="my-6 text-lg font-bold text-[#242424]">Password</h3>
          <p className="max-w-2xl text-base font-medium text-gray-500">
            {formatAccountMessage({
              id: 'account.security.warning',
              defaultMessage: 'Be careful when using this setting',
            })}
          </p>
        </div>
      </div>
      <form className="mt-8" onSubmit={handleSubmit}>
        {success && <p className="text-sm text-[#34D399]">{success}</p>}
        {error && <p className="text-sm text-[#F87171]">{error}</p>}

        <div className="mt-3 max-w-sm">
          <input
            aria-label="old password"
            id="old-password"
            name="oldPassword"
            type="password"
            className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
            placeholder={formatAccountMessage({
              id: 'password.old',
              defaultMessage: 'Old password',
            })}
            required
            onChange={handleChange}
            value={data.oldPassword}
          />
        </div>
        <div className="mt-3 max-w-sm">
          <input
            aria-label="new password"
            id="password"
            name="password"
            type="password"
            className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
            placeholder={formatAccountMessage({
              id: 'password',
              defaultMessage: 'Password',
            })}
            required
            onChange={handleChange}
            value={data.password}
          />
        </div>
        <div className="mt-3 max-w-sm">
          <input
            aria-label="confirm new password"
            id="confirm-password"
            name="confirmPassword"
            type="password"
            className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
            placeholder={formatAccountMessage({
              id: 'password.confirm',
              defaultMessage: 'Confirm password',
            })}
            required
            onChange={handleChange}
            value={data.confirmPassword}
          />
        </div>
        <button
          type="submit"
          className="mt-6 flex w-fit justify-center rounded border border-transparent bg-primary py-3 px-8 text-sm font-medium text-white transition-colors duration-200 ease-out hover:bg-[#33B8A1] focus:outline-none disabled:bg-[#808080]"
          disabled={submitDisabled || processing}
        >
          {formatCommonMessage({
            id: 'save',
            defaultMessage: 'Save',
          })}
        </button>
      </form>
    </div>
  );
};

export default Security;
