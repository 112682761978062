import React from 'react';

type ListIconProps = {
  color?: string;
  className?: string;
};

export const ListIcon = ({ className, color = '#242424' }: ListIconProps) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 8H28" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M4 16H28" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M4 24H28" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
