import React, { FC } from 'react';

type SideContentProps = {
  title: string;
  outerClassName?: string;
  innerClassName?: string;
};

export const SideContent: FC<SideContentProps> = (props) => {
  const { children, title, outerClassName, innerClassName } = props;

  return (
    <div
      className={
        'inline-flex w-full flex-col items-start justify-start gap-2 bg-white' +
        (outerClassName ? ' ' + outerClassName : '')
      }
    >
      <div className="inline-flex w-full flex-row items-center justify-between">
        <h4 className="text-base font-semibold text-neutral-800">{title}</h4>
      </div>
      <div className="inline-flex w-full flex-col gap-2">
        <div className={'h-px w-full border border-neutral-200' + (innerClassName ? ' ' + innerClassName : '')}></div>
        {children}
      </div>
    </div>
  );
};
