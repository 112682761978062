import { fetchApiHub } from 'frontastic/lib/fetch-api-hub';
import { Review } from '../../../../types/product/Review';

export const queryProducts = async (query?: string, category?: string) => {
  const queryParams = [...(query ? ['query=' + query] : []), ...(category ? ['category=' + category] : [])];
  return fetchApiHub(
    '/action/typesense_extension/fallback' + (queryParams.length > 0 ? '?' + queryParams.join('&') : ''),
    {
      method: 'GET',
    },
  );
};

export const queryCategory = async (slug: string, language: string) => {
  return fetchApiHub('/action/typesense_extension/queryCategory', { method: 'POST' }, { slug, language });
};

export const getCategoryFull = async (id: string) => {
  return await fetchApiHub('/action/typesense_extension/queryCategoryFull', { method: 'POST' }, { id });
};

export const getVersion = async () => {
  return await fetchApiHub('/action/typesense_extension/version', { method: 'GET' });
};

export const addReview = async (review: Review) => {
  return await fetchApiHub('/action/product/addReview', { method: 'POST' }, review);
};
