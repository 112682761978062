import { useState, useEffect } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { hugeDesktop, mediumDesktop } from 'helpers/utils/screensizes';

const useTransformImageSrc = (src: string) => {
  const [transformedSrc, setTransformedSrc] = useState<string>('');
  const { width } = useWindowSize();

  useEffect(() => {
    if (src) {
      const transformSrc = (source: string) => {
        const fileName = source.substring(source.lastIndexOf('/') + 1, source.lastIndexOf('.'));
        const extension = source.substring(source.lastIndexOf('.'));

        const newFileName =
          width > hugeDesktop
            ? `large_${fileName}.webp`
            : width < hugeDesktop && width > mediumDesktop
            ? `medium_${fileName}.webp`
            : `small_${fileName}.webp`;

        const newSrc = source.replace(`${fileName}${extension}`, newFileName);
        return newSrc;
      };

      setTransformedSrc(transformSrc(src));
    }
  }, [src, width]);

  return transformedSrc;
};

export default useTransformImageSrc;
