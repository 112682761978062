export const ctColorToCssColor = (color: string) => {
  let colorCode = '';
  switch (color.toLowerCase()) {
    case 'oliv': {
      colorCode = 'olive';
      break;
    }
    case 'petrol': {
      colorCode = '#005F6A';
      break;
    }
    case 'multicolored': {
      colorCode =
        'linear-gradient(' +
        '90deg, ' +
        'hsl(0,100%,50%), ' +
        'hsl(0,100%,50%), ' +
        'hsl(30,100%,50%), ' +
        'hsl(60,100%,50%), ' +
        'hsl(90,100%,50%), ' +
        'hsl(120,100%,50%), ' +
        'hsl(150,100%,50%), ' +
        'hsl(180,100%,50%), ' +
        'hsl(210,100%,50%), ' +
        'hsl(240,100%,50%), ' +
        'hsl(270,100%,50%), ' +
        'hsl(300,100%,50%), ' +
        'hsl(330,100%,50%), ' +
        'hsl(0,100%,50%), ' +
        'hsl(0,100%,50%))';
      break;
    }
    default: {
      colorCode = color;
    }
  }
  return colorCode;
};
