import React from 'react';

type Props = {
  className?: string;
  fill?: string;
};

const Icon: React.FC<Props> = ({ className, fill = '#2D3748' }: Props) => (
  <svg width={20} height={20} className={className} viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" fill={fill} />
  </svg>
);

export default Icon;
