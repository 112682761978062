import React from 'react';

type Props = {
  className?: string;
};

const Paypal: React.FC<Props> = ({ className }: Props) => (
  <svg width="68" height="17" viewBox="0 0 68 17" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5859 4.26651H11.1643C13.6226 4.26651 14.5479 5.49312 14.4049 7.29517C14.1686 10.2703 12.3438 11.9162 9.92341 11.9162H8.70139C8.3693 11.9162 8.14593 12.1329 8.05611 12.72L7.53728 16.133C7.50301 16.3544 7.38482 16.4825 7.20755 16.5H4.33095C4.06031 16.5 3.96458 16.2961 4.03549 15.8546L5.78934 4.91302C5.85789 4.47502 6.10135 4.26651 6.5859 4.26651Z"
      fill="#009EE3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.4635 4.06339C28.0081 4.06339 29.4334 4.88928 29.2384 6.94761C29.002 9.39384 27.6725 10.7474 25.5747 10.7532H23.7417C23.4781 10.7532 23.3505 10.9652 23.2819 11.3997L22.9274 13.6212C22.8742 13.9566 22.6993 14.122 22.4416 14.122H20.7363C20.4644 14.122 20.3699 13.9508 20.4302 13.5676L21.8377 4.66446C21.9075 4.22647 22.0741 4.06339 22.3778 4.06339H26.4599H26.4635ZM23.6861 8.83004H25.0748C25.9434 8.79742 26.5202 8.2045 26.5781 7.13515C26.6135 6.47467 26.1609 6.00173 25.4412 6.00522L24.134 6.01105L23.6861 8.83004ZM33.8748 13.4406C34.0308 13.3008 34.1891 13.2286 34.1667 13.401L34.1111 13.8134C34.0828 14.0289 34.169 14.143 34.3723 14.143H35.8874C36.1427 14.143 36.2668 14.0417 36.3294 13.6526L37.2631 7.87717C37.3104 7.58712 37.2383 7.44501 37.0149 7.44501H35.3485C35.1984 7.44501 35.1252 7.52771 35.0862 7.7537L35.0247 8.10898C34.9928 8.2942 34.9065 8.32681 34.8261 8.14043C34.5437 7.48112 33.8228 7.18524 32.817 7.20854C30.4805 7.2563 28.9051 9.00477 28.7361 11.246C28.6061 12.9793 29.866 14.341 31.5276 14.341C32.7331 14.341 33.272 13.9916 33.8795 13.4441L33.8748 13.4406ZM32.6055 12.5518C31.5997 12.5518 30.8989 11.7609 31.0443 10.7917C31.1896 9.82251 32.1304 9.03156 33.1361 9.03156C34.1419 9.03156 34.8427 9.82251 34.6973 10.7917C34.552 11.7609 33.6124 12.5518 32.6055 12.5518ZM40.2283 7.42637H38.6919C38.3752 7.42637 38.2464 7.65934 38.3468 7.9459L40.2543 13.4511L38.3835 16.0709C38.2263 16.2899 38.348 16.4891 38.569 16.4891H40.2957C40.3963 16.5005 40.4982 16.4832 40.5891 16.4392C40.6801 16.3953 40.7563 16.3264 40.8086 16.2409L46.6753 7.94707C46.8561 7.69196 46.771 7.42404 46.4744 7.42404H44.8399C44.5598 7.42404 44.4475 7.53354 44.2868 7.76302L41.8404 11.2576L40.7472 7.75486C40.6833 7.54286 40.5238 7.42637 40.2295 7.42637H40.2283Z"
      fill="#113984"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.7789 4.06297C54.3236 4.06297 55.7489 4.88887 55.5539 6.94719C55.3175 9.39342 53.9879 10.747 51.8902 10.7528H50.0583C49.7948 10.7528 49.6671 10.9648 49.5986 11.3993L49.244 13.6207C49.1909 13.9562 49.0159 14.1216 48.7583 14.1216H47.0529C46.7811 14.1216 46.6865 13.9504 46.7468 13.5672L48.1567 4.66171C48.2265 4.22372 48.3931 4.06064 48.6968 4.06064H52.7789V4.06297ZM50.0016 8.82962H51.3903C52.2589 8.79701 52.8357 8.20409 52.8936 7.13474C52.929 6.47425 52.4764 6.00131 51.7566 6.00481L50.4495 6.01064L50.0016 8.82962ZM60.1902 13.4402C60.3462 13.3004 60.5046 13.2282 60.4822 13.4006L60.4266 13.8129C60.3982 14.0284 60.4845 14.1426 60.6878 14.1426H62.2029C62.4582 14.1426 62.5823 14.0413 62.6449 13.6522L63.5786 7.87676C63.6259 7.5867 63.5538 7.44459 63.3304 7.44459H61.6664C61.5163 7.44459 61.443 7.5273 61.404 7.75328L61.3425 8.10857C61.3106 8.29378 61.2244 8.3264 61.144 8.14002C60.8615 7.4807 60.1406 7.18483 59.1349 7.20812C56.7984 7.25588 55.223 9.00435 55.054 11.2456C54.924 12.9789 56.1838 14.3406 57.8455 14.3406C59.0509 14.3406 59.5899 13.9912 60.1973 13.4437L60.1902 13.4402ZM58.9221 12.5514C57.9164 12.5514 57.2156 11.7604 57.3609 10.7913C57.5063 9.82209 58.447 9.03115 59.4528 9.03115C60.4585 9.03115 61.1594 9.82209 61.014 10.7913C60.8686 11.7604 59.9279 12.5514 58.9221 12.5514ZM65.9103 14.1519H64.1612C64.1308 14.1533 64.1005 14.148 64.0723 14.1364C64.0442 14.1248 64.0191 14.1073 63.9986 14.0851C63.9782 14.0628 63.963 14.0364 63.9541 14.0077C63.9453 13.9789 63.9429 13.9486 63.9473 13.9189L65.4837 4.32507C65.4984 4.25959 65.5351 4.20093 65.5879 4.15863C65.6407 4.11633 65.7064 4.09288 65.7744 4.09209H67.5236C67.554 4.09075 67.5843 4.09605 67.6124 4.10761C67.6405 4.11916 67.6657 4.1367 67.6862 4.15895C67.7066 4.18121 67.7218 4.20764 67.7307 4.23635C67.7395 4.26507 67.7419 4.29536 67.7375 4.32507L66.2011 13.9189C66.1869 13.9849 66.1504 14.044 66.0975 14.0868C66.0447 14.1296 65.9787 14.1533 65.9103 14.1543V14.1519Z"
      fill="#009EE3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.72238 0.5H8.30555C9.59612 0.5 11.1278 0.54077 12.1512 1.4319C12.8355 2.02715 13.1948 2.97419 13.1121 3.99461C12.8308 7.4438 10.7378 9.37632 7.92973 9.37632H5.67005C5.28477 9.37632 5.03068 9.62793 4.92195 10.3082L4.29085 14.2688C4.24948 14.525 4.13721 14.6765 3.9363 14.6951H1.10816C0.794972 14.6951 0.683878 14.4621 0.765425 13.9473L2.79819 1.25251C2.87973 0.742292 3.16456 0.5 3.72238 0.5Z"
      fill="#113984"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.98779 9.90615L5.7879 4.91235C5.85763 4.47436 6.10109 4.26468 6.58564 4.26468H11.1641C11.9216 4.26468 12.535 4.38117 13.0148 4.59667C12.5551 7.66727 10.5401 9.37264 7.90221 9.37264H5.64608C5.34353 9.3738 5.12134 9.52291 4.98779 9.90615Z"
      fill="#172C70"
    />
  </svg>
);

export default Paypal;
