// Doc Examples
import Categories from 'components/tailgrids-ui/categories';
import AccountDetails from './account/details';
import AccountLogin from './account/login';
import AccountOrdersHistory from './account/orders';
import AccountRegister from './account/register';
import ResetPassword from './account/reset-password';
import AccountTest from './account/test';
import BannerTastic from './banner';
import CtaTastic from './call-to-action';
import Cart from './cart';
import CartUpsellProductsTastic from './cart/upsell';
import Checkout from './checkout';
import ThankYou from './checkout/thank-you';
import CheckoutUpsellProductsTastic from './checkout/upsell';
import Blog from './content/blog';
import CategoryTeasers from './content/category-teasers';
import Markdown from './content/markdown';
import Spacer from './content/spacer';
import Tile from './content/tile';
import VideoTileTastic from './content/video-tile';
import HelloWorld from './doc-examples/hello-world';
import SimpleButton from './doc-examples/simple-button';
import StarWarsCharacterFilter from './doc-examples/star-wars/character-filter';
import StarWarsCharacterSearch from './doc-examples/star-wars/character-search';
import StarWarsOpeningCrawl from './doc-examples/star-wars/movies';
import Footer from './footer';
import Header from './header';
import Hero from './hero';
import NewsCardTastic from './news-card';
import Newsletter from './newsletter';
import NotFound from './not-found';
import ProductDetails from './products/details';
import ProductList from './products/product-list';
import RecentProductsTastic from './products/recent-products';
import SearchTastic from './products/search';
import ProductSlider from './products/slider';
import ProductDetailsUpsellTastic from './products/upsell-products';
import Showcase from './showcase';
import ThankYouBannerTastic from './thank-you/thank-you-banner';
import ThankYouPromoTastic from './thank-you/thank-you-promo';
import ThankYouRegistrationTastic from './thank-you/thank-you-registration';
import Wishlist from './wishlist';
import HighlightsTastic from './highlights';
import StrapiContentTastic from './strapi-content';

export const tastics = {
  // Doc Examples
  'example/simple-button': SimpleButton,
  'example/hello-world': HelloWorld,
  'example/star-wars/movie': StarWarsOpeningCrawl,
  'example/star-wars/character-search': StarWarsCharacterSearch,
  'example/star-wars/character-filter': StarWarsCharacterFilter,
  //'contentful/blog': ContentfulBlog,
  'example/call-to-action': CtaTastic,
  'example/categories-links': Categories,

  'other/promo-banner': BannerTastic,
  'hero/hero-video': VideoTileTastic,
  'checkout/thank-you-banner': ThankYouBannerTastic,
  'checkout/thank-you-promo': ThankYouPromoTastic,
  'checkout/thank-you-registration': ThankYouRegistrationTastic,
  'checkout/upsell-products': CheckoutUpsellProductsTastic,
  'footer/services-highlight': HighlightsTastic,

  'zyres/typesense': SearchTastic,

  'cart/upsell-products': CartUpsellProductsTastic,

  'products/recent-products': RecentProductsTastic,
  'products/upsell-products': ProductDetailsUpsellTastic,

  'strapi/strapi-content': StrapiContentTastic,

  'commercetools/ui/checkout': Checkout,
  'commercetools/ui/thank-you': ThankYou,
  'commercetools/ui/cart': Cart,
  'commercetools/ui/footer': Footer,
  'commercetools/ui/header': Header,
  'commercetools/ui/content/blog': Blog,
  'commercetools/ui/content/tile': Tile,
  'commercetools/ui/content/spacer': Spacer,
  'commercetools/ui/content/showcase': Showcase,
  'commercetools/ui/content/markdown': Markdown,
  'commercetools/ui/content/newsletter': Newsletter,
  'commercetools/ui/products/details': ProductDetails,
  'commercetools/ui/products/product-list': ProductList,
  'commercetools/ui/products/slider': ProductSlider,
  // 'commercetools/ui/products/similar-products': SimilarProducts,
  'commercetools/ui/wishlist': Wishlist,
  'commercetools/ui/account/test': AccountTest,
  'commercetools/ui/account/details': AccountDetails,
  'commercetools/ui/account/login': AccountLogin,
  'commercetools/ui/account/register': AccountRegister,
  'commercetools/ui/account/orders': AccountOrdersHistory,
  'commercetools/ui/account/reset-password': ResetPassword,
  'commercetools/ui/content/category-teasers': CategoryTeasers,
  'commercetools/ui/content/news-card': NewsCardTastic,
  'commercetools/ui/hero': Hero,
  default: NotFound,
};
