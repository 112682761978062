import { Cart } from '@Types/cart/Cart';
import { CartDetails } from 'frontastic/actions/cart';
import { FormData } from '.';

export const mapToCartStructure = (data: FormData, billingIsSameAsShipping: boolean): CartDetails => {
  const commonData = {
    firstName: data.billingFirstName,
    lastName: data.billingLastName,
  };

  const mappedData: CartDetails = {
    account: {
      email: data.email,
    },
    billing: {
      firstName: data.billingFirstName,
      lastName: data.billingLastName,
      streetName: data.billingStreetName,
      postalCode: data.billingPostalCode,
      city: data.billingCity,
      additionalStreetInfo: data.billingAdditionalAddressInfo,
      country: data.billingCountry,
      packstationNumber: data.billingPackstation,
    },
    shipping: {
      // ...commonData,
      addressId: '1st-shipping-address',
      firstName: data.shippingFirstName,
      lastName: data.shippingLastName,
      streetName: data.shippingStreetName,
      postalCode: data.shippingPostalCode,
      city: data.shippingCity,
      additionalStreetInfo: data.shippingAdditionalAddressInfo,
      country: data.shippingCountry,
      packstationNumber: data.shippingPackstation,
    },
  };

  if (billingIsSameAsShipping) {
    mappedData.shipping = {
      // ...commonData,
      firstName: data.billingFirstName,
      lastName: data.billingLastName,
      streetName: data.billingStreetName,
      postalCode: data.billingPostalCode,
      city: data.billingCity,
      additionalStreetInfo: data.billingAdditionalAddressInfo,
      country: data.billingCountry,
      packstationNumber: data.billingPackstation,
    };
  }

  return mappedData;
};

export const mapToFormStructure = (data: Cart): FormData => {
  if (!data?.shippingAddress) return;

  const mappedData: FormData = {
    firstName: data.billingAddress.firstName,
    lastName: data.billingAddress.lastName,
    email: data.email,
    shippingFirstName: data.shippingAddress.firstName,
    shippingLastName: data.shippingAddress.lastName,
    shippingStreetName: data.shippingAddress.streetName,
    shippingCity: data.shippingAddress.city,
    shippingPostalCode: data.shippingAddress.postalCode,
    shippingCountry: data.shippingAddress.country,
    shippingAdditionalAddressInfo: data.shippingAddress.additionalAddressInfo,
    billingFirstName: data.billingAddress.firstName,
    billingLastName: data.billingAddress.lastName,
    billingStreetName: data.billingAddress.streetName,
    billingCity: data.billingAddress.city,
    billingPostalCode: data.billingAddress.postalCode,
    billingCountry: data.billingAddress.country,
    billingAdditionalAddressInfo: data.billingAddress.additionalAddressInfo,
    shippingPackstation: data.shippingAddress.packstationNumber,
    billingPackstation: data.billingAddress.packstationNumber,
  };

  return mappedData;
};
