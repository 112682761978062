import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useFormat } from 'helpers/hooks/useFormat';
// import Image from 'frontastic/lib/image';
import { FullPageWidthWrapper } from 'components/tailgrids-ui/full-bleed';
import { CldImage } from 'next-cloudinary';

const RecentProduct = (props) => {
  const { recentCollections } = props;
  const { formatMessage } = useFormat({ name: 'common' });

  if (recentCollections[0].title === '') return <></>;

  return (
    <FullPageWidthWrapper>
      <section className="container mx-auto max-w-[1280px] py-20 px-4 font-urbanist sm:px-10 lg:px-4">
        <div>
          {recentCollections.map((item, idx) => (
            <ProductCard
              key={idx}
              subtitle={item.subtitle}
              title={item.title}
              details={item.text}
              link={item?.reference?.link}
              button={formatMessage({ id: 'viewAllItems', defaultMessage: 'View All Items' })}
              img={item?.fgImage?.media?.file}
            />
          ))}
        </div>
      </section>
    </FullPageWidthWrapper>
  );
};

export default RecentProduct;

const ProductCard = ({ subtitle, title, details, link, button, img }) => {
  const router = useRouter();

  return (
    <>
      <div className="group mb-20 flex flex-col-reverse flex-wrap items-center justify-center gap-6 last:mb-0 md:mb-0 md:flex-nowrap md:justify-between odd:md:flex-row even:md:flex-row-reverse">
        <div className="w-full lg:w-2/3">
          <div className="mb-12 max-w-[665px] md:mb-0 group-even:md:ml-auto">
            <span className="mb-4 block text-base font-bold text-primary sm:text-heading-4">{subtitle}</span>
            <h2 className="mb-6 text-heading-3 font-semibold !leading-tight text-black sm:text-heading-2">{title}</h2>
            <p className="text-body-color mb-10 text-base font-medium sm:text-lg">{details}</p>
            <Link href={link} locale={router.locale}>
              <a className="inline-flex items-center justify-center rounded bg-primary py-3 px-8 text-center text-base font-semibold text-white hover:bg-opacity-90">
                {button}
              </a>
            </Link>
          </div>
        </div>
        <div className="h-[452px] w-full md:w-[470px]">
          <CldImage
            src={img}
            width={470}
            height={452}
            alt={title}
            className="h-full w-full rounded border-[1px] border-[#D8D8D8] object-cover"
          />
        </div>
      </div>
    </>
  );
};
