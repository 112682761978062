import React from 'react';
import parse from 'html-react-parser';
import { FullPageWidthWrapper } from '../../full-bleed';
import { strapiConfig } from 'helpers/strapiConfig';
import Image from 'frontastic/lib/image';
import useTransformImageSrc from 'helpers/hooks/useTransformImageSrc';

export type CZ15ArticleProps = {
  title: string;
  subtitle?: string;
  text: string;
  imageSrc: string;
};

const CZ15Article = ({ title, subtitle, text, imageSrc }: CZ15ArticleProps) => {
  const imgSrc = useTransformImageSrc(`${strapiConfig.url}${imageSrc}`);
  return (
    <section className="container mx-auto flex max-w-[1280px] flex-col px-4 pt-10 pb-20 font-urbanist sm:px-10 lg:px-4">
      {imageSrc && (
        <div className="relative mb-6 rounded-[2px] bg-primary md:h-[380px]">
          <Image src={imgSrc} layout="fill" className="h-full w-full rounded-[2px] object-cover" alt="Article Image" />
        </div>
      )}
      <h4 className="mb-2 text-2xl font-bold text-primary">{subtitle}</h4>
      <h2 className="mb-4 text-4xl font-semibold !leading-tight text-black">{title}</h2>
      <div className="text-base font-medium text-[#808080]"> {parse(text)}</div>
    </section>
  );
};

export default CZ15Article;
