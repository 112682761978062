import { Cz13ImageWithTextFragment, Enum_Componentmodulesimagetextitem_Alignment } from 'generated/strapiTypes';
import CZ13ImageWithText, { ImageTextItem } from './CZ13ImageWithText';
import { useWindowSize } from 'usehooks-ts';
import { tablet } from 'helpers/utils/screensizes';
import { useAccount } from 'frontastic';

export const CZ13ImageWithTextAdapter = (props: Cz13ImageWithTextFragment) => {
  const { width } = useWindowSize();
  const { account } = useAccount();

  const imageTextItems: ImageTextItem[] = [];

  props?.imageTextItem?.forEach((item) => {
    imageTextItems.push({
      text: item?.text ?? '',
      imageAlt: item?.imageAlt ?? '',
      alignment: item?.alignment === Enum_Componentmodulesimagetextitem_Alignment.Left ? 'Left' : 'Right',
      buttonText: item?.buttonText ?? '',
      subtitle: item?.subtitle ?? '',
      title: item?.title ?? '',
      image: {
        data: {
          attributes: {
            url:
              width > tablet
                ? item?.image?.data?.attributes?.url ?? ''
                : item?.imageMobile?.data?.attributes?.url ?? item?.image?.data?.attributes?.url ?? '',
          },
        },
      },
      buttonUrl: item?.buttonUrl ?? '',
    });
  });

  if (props?.customerGroups?.length > 0 && !props?.customerGroups?.includes(account?.customerGroup?.id)) return null;

  return <CZ13ImageWithText imageTextItem={imageTextItems} />;
};
