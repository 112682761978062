import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import AdyenCheckout from '@adyen/adyen-web';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { toast } from 'react-hot-toast';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAnonymousStore } from 'helpers/stores/anonymousStore';
import { useCart, useAdyen } from 'frontastic';
import '@adyen/adyen-web/dist/adyen.css';
import { captureOrder, createOrder } from 'frontastic/actions/paypal';

type Session = {
  id: string;
  sessionData?: string;
};

type SessionConfig = {
  environment: string;
  clientKey: string;
  session: Session;
} & Record<string, unknown>;

const Checkout = () => {
  const router = useRouter();
  const { data: cartList, checkout } = useCart();
  const { createSession, addPayment } = useAdyen();
  const [session, setSession] = useState<Session>();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'common' });
  const [adyenRedirectDone, setAdyenRedirectDone] = useState(false);
  const { setAnonymousId } = useAnonymousStore();

  const initializeSession = async (sessionConfiguration: SessionConfig) => {
    const checkout = await AdyenCheckout(sessionConfiguration);
    checkout.create('dropin').mount('#dropin-container');
  };

  useEffect(() => {
    if (session) {
      initializeSession(getSessionConfiguration(session));
    }
  }, [session]);

  // handle adyen redirect
  useEffect(() => {
    if (adyenRedirectDone) return;

    const { redirectResult, sessionId } = router.query;
    if (redirectResult && sessionId) {
      setAdyenRedirectDone(true);
      AdyenCheckout(
        getSessionConfiguration({
          id: sessionId as string,
        }),
      ).then((checkout) => {
        checkout.submitDetails({
          details: {
            redirectResult,
          },
        });
      });
    }
  }, [router.query]);

  useEffect(() => {
    if (!cartList?.orderId) {
      const host = typeof window !== 'undefined' ? window.location.origin : '';

      const { redirectResult, sessionId } = router.query;
      if (redirectResult || sessionId) return;

      if (typeof window === 'undefined' || !!session) return;

      createSession(`${host}/checkout?step=2`).then((res) => {
        const { id, sessionData } = res;

        setSession({ id, sessionData });
      });
    }
  }, [cartList, createSession, session]);

  const finishCheckout = async () => {
    const { order } = await checkout();
    setAnonymousId(order.anonymousId);
    router.push(`/thank-you?orderId=${order.orderId}`);
  };

  function getSessionConfiguration(session: Session): SessionConfig {
    const sessionConfiguration: SessionConfig = {
      environment: 'test', //process.env.NODE_ENV === 'production' ? 'live' : 'test',
      clientKey: 'test_X3GSZFLNEJDGRETU6EXLZBZXDAPGJ5GV',
      session,
      // 2nd argument is component (the dom node)
      onPaymentCompleted: async (result: { resultCode: string; sessionData: string }) => {
        if (result.resultCode === 'Authorised' || result.resultCode === 'Received') {
          await addPayment(result?.sessionData);
          await finishCheckout();
        } else {
          router.push('/checkout?step=2');
          setSession(undefined);
        }
      },
      onError: (error: Error) => {
        toast.error(error.message);
      },
      analytics: {
        enabled: false,
      },
    };
    return sessionConfiguration;
  }

  return (
    <div className="lg:col-span-7">
      <button
        className="mb-20 w-full rounded-md bg-primary py-3 text-white hover:bg-[#33B8A1]"
        onClick={async () => {
          finishCheckout();
        }}
        data-testid="offline-payment"
      >
        {formatAccountMessage({ id: 'payOffline', defaultMessage: 'Pay Offline' })}
      </button>
      <PayPalScriptProvider
        options={{
          clientId: 'AQb-a5rYBLV4VrZ03DX24cFKbFu0pTh_Mu6P7zC9xMWSyBryW22Sj_-HqLpnBkjQnuBEQcpwmUfhmFmp',
          currency: cartList?.sum?.currencyCode,
        }}
      >
        {/* fix for paypal button having 100 z-index and appearing over the navbar */}
        <style>
          {`
          iframe {
            z-index: 0 !important;
          }`}
        </style>
        <PayPalButtons
          style={{
            layout: 'horizontal',
          }}
          createOrder={async () => {
            const res = await createOrder();
            console.log('createOrder:', res);
            return res.id;
          }}
          onApprove={async (data) => {
            console.log('onApproveData', data);
            const res = await captureOrder(data);
            console.log('on Approve:', res);

            finishCheckout();
          }}
        />
      </PayPalScriptProvider>
      <section id="dropin-container" aria-labelledby="cart-heading"></section>
    </div>
  );
};

export default Checkout;
