import { fetchApiHub } from 'frontastic/lib/fetch-api-hub';

export async function createOrder() {
  return await fetchApiHub('/action/paypal/createOrder', { method: 'POST' });
}

export async function captureOrder(data: unknown) {
  return await fetchApiHub('/action/paypal/captureOrder', { method: 'POST', body: JSON.stringify(data) });
}

export async function cancelOrder() {
  return await fetchApiHub('/action/paypal/cancelOrder');
}

export async function errorOrder() {
  return await fetchApiHub('/action/paypal/errorOrder');
}
