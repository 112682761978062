import { S1BannerFragment } from 'generated/strapiTypes';
import React from 'react';
import Banner from './Banner';
import { useAccount } from 'frontastic';

export const BannerAdapter = (props: S1BannerFragment & { isFirstElement: boolean }) => {
  const { account } = useAccount();

  if (props?.customerGroups?.length > 0 && !props?.customerGroups?.includes(account?.customerGroup?.id)) return null;

  return (
    <Banner
      title={props?.title ?? ''}
      description={props?.description ?? ''}
      isCentered={false}
      image={{
        data: {
          attributes: {
            url: props?.image?.data?.attributes?.url,
          },
        },
      }}
      buttonText={props?.buttonText}
      buttonUrl={props?.buttonUrl}
      targentBlank={props?.targetBlank ?? false}
      isFirstElement={props?.isFirstElement ?? false}
    />
  );
};
