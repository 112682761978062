export type RatingProps = {
  value: number;
  setValue?: (value: number) => void;
};
type FillState = 'no' | 'half' | 'full';

function getStarFill(value: number, index: number): FillState {
  if (value >= index) return 'full';
  if (value >= index - 0.5) return 'half';
  return 'no';
}

/**
 * Rating component
 * when setValue is not provided, the component is read-only
 */
export function Rating(props: RatingProps) {
  return (
    <div className="flex gap-2 py-2">
      {new Array(5).fill(0).map((_, i) => (
        <Star fill={getStarFill(props.value, i + 1)} setValue={props.setValue} value={i + 1} key={i} />
      ))}
    </div>
  );
}

type StarPropsType = {
  fill: FillState;
  setValue?: (value: number) => void;
  value: number;
};

export function Star(props: StarPropsType) {
  return (
    <span
      onClick={() => {
        props?.setValue?.(props.value);
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        height="25px"
        width="25px"
        version="1.1"
        // id="Capa_1"
        viewBox="0 0 47.94 47.94"
        xmlSpace="preserve"
      >
        <defs>
          <linearGradient id="half">
            <stop offset="50%" stopColor="rgb(250 204 21)" />
            <stop offset="50%" stopColor="#d1d5db" />
          </linearGradient>
        </defs>
        <path
          fill={props.fill === 'half' ? 'url(#half)' : undefined}
          className={`origin-center stroke-yellow-400 object-left transition-all
            ${props.fill === 'full' ? 'fill-current text-yellow-400' : ''}
            ${props.fill === 'no' ? 'fill-transparent' : ''}
            ${props?.setValue ? 'hover:scale-95 active:text-yellow-500' : ''}
          `}
          d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757  c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042  c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685  c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528  c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956  C22.602,0.567,25.338,0.567,26.285,2.486z"
        />
      </svg>
    </span>
  );
}
