import { Order } from '@Types/cart/Order';
import { useRouter } from 'next/router';

export function useQueryOrder() {
  const router = useRouter();

  const { order: rawOrder } = router.query;

  if (typeof rawOrder !== 'string' || !rawOrder) {
    return null;
  }

  const order = JSON.parse(rawOrder) as Order;

  return order;
}
