import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { MediaItemWithMods } from 'frontastic';
import Image from 'frontastic/lib/image';

export type HighlightsProps = {
  serviceItems: ServiceItem[];
};

type ServiceItem = {
  fgImage?: MediaItemWithMods;
  title: string;
  text?: string;
};

const Highlights = (props: HighlightsProps) => {
  return (
    <section>
      <div className="bg-[#F6F9FF]">
        <div className="mx-auto flex max-w-[1280px] flex-col flex-wrap items-center justify-center gap-10 py-20 px-4 sm:px-10 md:flex-row md:flex-nowrap md:justify-between lg:px-4">
          {props.serviceItems.map((serviceItem, idx) => (
            <HighlightCard
              key={idx}
              icon={serviceItem.fgImage.media.file}
              title={serviceItem.title}
              details={serviceItem.text}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Highlights;

const HighlightCard = ({ icon, title, details }) => {
  return (
    <div className="max-w-xs">
      <div className="group relative text-center ">
        <div className="relative inline-flex h-20 w-20 items-center justify-center rounded-full bg-secondary-600">
          {/* {icon} */}
          <Image src={icon} alt={title} className="h-[32px] w-[32px] object-contain" />
        </div>
        <h4 className="text-dark my-4 font-urbanist text-2xl font-semibold">{title}</h4>
        <p className="font-urbanist text-base font-medium text-gray-100">{details}</p>
      </div>
    </div>
  );
};
