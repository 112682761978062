import React, { useState } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';
import { useAccount } from 'frontastic';
import { PartialAddress } from 'frontastic/actions/account';
import { NextFrontasticImage } from 'frontastic/lib/image';

export interface RegisterProps {
  logo?: NextFrontasticImage;
  loginLink?: Reference;
  newUserEmail?: string;
  showInCheckout?: boolean;
  newBillingAddress?: PartialAddress;
  newShippingAddress?: PartialAddress;
  saveAddress?: boolean;
}

const CheckoutRegister: React.FC<RegisterProps> = ({
  newUserEmail,
  showInCheckout,
  newBillingAddress,
  newShippingAddress,
  saveAddress = false,
}) => {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatSuccessMessage } = useFormat({ name: 'success' });

  //account actions
  const { register } = useAccount();

  //register data
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: newUserEmail ? newUserEmail : '',
    password: '',
    confirmPassword: '',
  });

  //error
  const [error, setError] = useState('');

  //success
  const [success, setSuccess] = useState('');

  //processing...
  const [loading, setLoading] = useState(false);

  //
  const [creationError, setCreationError] = useState<boolean>(false);

  //handle text input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, email: newUserEmail ? newUserEmail : data.email, [e.target.name]: e.target.value });
  };

  //data validation
  const validate = () => {
    //validation schema
    const passwordsMatch = data.password === data.confirmPassword;

    //UI error messages
    if (!passwordsMatch) {
      setCreationError(false);
      setError(formatErrorMessage({ id: 'password.noMatch', defaultMessage: "Passwords don't match" }));
    }

    //return a boolean representing the data validity
    return passwordsMatch;
  };

  //form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //validate data
    if (!validate()) return;
    //processing starts
    setLoading(true);
    //try registering the user with given credentials
    try {
      const response = await register(
        showInCheckout && saveAddress
          ? {
              email: newUserEmail ? newUserEmail : data.email,
              password: data.password,
              firstName: newBillingAddress?.firstName ?? '',
              lastName: newBillingAddress?.lastName ?? '',
              billingAddress: newBillingAddress,
              shippingAddress: newShippingAddress,
            }
          : {
              email: newUserEmail ? newUserEmail : data.email,
              password: data.password,
              firstName: newBillingAddress?.firstName ?? '',
              lastName: newBillingAddress?.lastName ?? '',
            },
      );

      if (!response.accountId) {
        setCreationError(true);
        setError(
          // formatErrorMessage({ id: 'account.create.fail', defaultMessage: "Sorry. We couldn't create your account.." }),
          formatErrorMessage({
            id: 'accountNotCreated',
            defaultMessage: 'Your account could not be created, maybe your email is already registered? ',
          }),
        );
        setSuccess('');
      } else {
        setError('');
        setCreationError(false);
        // setSuccess(
        //   formatSuccessMessage({
        //     id: 'account.created',
        //     defaultMessage: 'A verification email was sent to {email} ✓',
        //     values: { email: newUserEmail ? newUserEmail : data.email },
        //   }),
        // );

        (document.getElementById('password') as HTMLInputElement).disabled = true;
        (document.getElementById('confirm-password') as HTMLInputElement).disabled = true;
      }
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
      setSuccess('');
    }
    //processing ends
    setLoading(false);
  };

  const emailRegex = /\S+@\S+\.\S+/;

  return (
    <form className="mb-6 space-y-4 px-4 py-5 md:px-6" onSubmit={handleSubmit}>
      <h3 className="text-base font-bold text-[#242424]">
        {formatAccountMessage({ id: 'becomeMember', defaultMessage: 'Become a member' })}
      </h3>
      {success && <p className="text-sm text-[#34D399]">{success}</p>}
      {error && (
        <div className="justiy-start flex flex-row flex-wrap items-start gap-3">
          <p className="text-sm text-[#F87171]">{error}</p>
          {creationError && (
            <p className="text-center text-sm text-[#808080]">
              <ReferenceLink
                target={{
                  link: '/login',
                  target: '/login',
                  openInNewWindow: false,
                  type: 'link',
                }}
                className="font-medium text-primary underline hover:text-[#33B8A1]"
              >
                {formatAccountMessage({ id: 'account.login.here', defaultMessage: 'Login here' })}
              </ReferenceLink>
            </p>
          )}
        </div>
      )}
      <div className="flex w-full justify-between">
        <div className="w-[48%]">
          <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="password">
            {formatAccountMessage({ id: 'password', defaultMessage: 'Password' })}
          </label>
          <input
            className=" w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary disabled:bg-[#EDEDED]"
            id="password"
            name="password"
            type="password"
            autoComplete="new-password"
            onChange={handleChange}
            placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
            required
          />
        </div>
      </div>
      <div className="flex w-full justify-between">
        <div className="mb-4 w-[48%]">
          <label className="mb-2 block text-base font-medium text-[#808080]" htmlFor="password">
            {formatAccountMessage({ id: 'password.confirm', defaultMessage: 'Confirm Password' })}
          </label>
          <input
            className=" w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary disabled:bg-[#EDEDED]"
            id="confirm-password"
            name="confirmPassword"
            type="password"
            autoComplete="new-password"
            onChange={handleChange}
            placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
            required
          />
        </div>
      </div>
      <div>
        <input
          type="submit"
          value={formatAccountMessage({ id: 'createAccount', defaultMessage: 'Create my account' })}
          className="w-fit cursor-pointer text-sm text-primary underline hover:text-[#33B8A1] disabled:cursor-not-allowed disabled:text-primary"
          disabled={!emailRegex.test(newUserEmail)}
        />
      </div>
      {!emailRegex.test(newUserEmail) && (
        <p className="text-sm text-[#F87171]">
          {formatAccountMessage({ id: 'enterValidEmail', defaultMessage: 'Please enter a valid email' })}
        </p>
      )}
    </form>
  );
};

export default CheckoutRegister;
