import React from 'react';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import Price from 'components/commercetools-ui/price';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import CartItems from './cart-items';

export type ShippingMethodsProps = {
  shippingMethods: ShippingMethod[];
  currentShippingMethod: ShippingMethod;
  onSelectShippingMethod: (shippingMethodId: ShippingMethod) => void;
};

const ShippingMethods: React.FC<ShippingMethodsProps> = ({
  shippingMethods,
  currentShippingMethod,
  onSelectShippingMethod,
}) => {
  const { data } = useCart();
  const { formatMessage } = useFormat({ name: 'checkout' });

  console.log(currentShippingMethod);

  return (
    <>
      <section aria-labelledby="cart-heading" className="rounded-md bg-white lg:col-span-8 lg:py-4">
        <div className="px-4 py-5">
          <form>
            <div className="mb-4 text-base font-bold text-[#242424]">
              <span>{formatMessage({ id: 'deliveryOption', defaultMessage: 'Select Delivery Option' })}</span>
            </div>

            <div className="-mx-3 mb-8 mt-2 flex flex-wrap">
              {shippingMethods?.map(({ shippingMethodId, name, description, rates }, index) => (
                <div className="w-full px-3 md:w-1/2 lg:w-full xl:w-1/2" key={index}>
                  <div className="mb-3">
                    <input
                      type="radio"
                      name="shipping"
                      aria-label="Shipping method"
                      id={name}
                      className="shipping sr-only"
                      value={shippingMethodId ? shippingMethodId : currentShippingMethod?.shippingMethodId}
                      onChange={() => onSelectShippingMethod(shippingMethods[index])}
                    />
                    <label
                      htmlFor={name}
                      className={`flex cursor-pointer items-center justify-between overflow-hidden rounded-[4px] border-2 ${
                        data?.shippingInfo && currentShippingMethod?.shippingMethodId === shippingMethodId
                          ? 'border-[#33B8A1]'
                          : 'border-[#E7E7E7]'
                      } bg-white py-2 px-6 sm:px-4 md:px-6 lg:px-4 xl:px-6`}
                    >
                      <div className="flex items-center justify-start gap-1">
                        <CustomCheckbox
                          id={name}
                          onChange={() => onSelectShippingMethod(shippingMethods[index])}
                          // checked={currentShippingMethod?.shippingMethodId === shippingMethodId}
                          checked={data?.shippingInfo && currentShippingMethod?.shippingMethodId === shippingMethodId}
                          data-testid={`shipping-${index}`}
                        />
                        <div>
                          <span className="mb-1 block text-base font-medium text-[#242424] sm:text-base md:text-sm lg:text-base">
                            {name}
                          </span>
                          <span className="block text-xs font-normal text-[#808080]">{description}</span>
                        </div>
                      </div>
                      <Price price={rates?.[0]?.price} className="text-base font-semibold text-[#242424]" />
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </form>
        </div>
        <div className="w-full px-4">
          <h3 className="mb-4 border-b-2 border-[#E7E7E7] pb-4 text-base font-bold text-[#242424]">
            {formatMessage({ id: 'yourOrder', defaultMessage: 'Your Order' })}
          </h3>
          <CartItems cart={data} />
        </div>
      </section>
    </>
  );
};

export default ShippingMethods;

const CustomCheckbox = ({ id, checked, onChange, ...props }) => {
  return (
    <label htmlFor={id} className="text-body-color flex cursor-pointer select-none items-center" {...props}>
      <div className="relative mr-4 h-5 w-5">
        <input
          type="checkbox"
          id={id}
          className="invisible absolute inset-0 h-full w-full"
          checked={checked}
          onChange={onChange}
        />
        <div
          className={`box flex h-full w-full items-center justify-center rounded ${
            checked ? 'bg-primary' : 'border border-[#E7E7E7] bg-white'
          }`}
        >
          {checked ? (
            <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                fill="white"
                stroke="white"
                strokeWidth="0.4"
              ></path>
            </svg>
          ) : null}
        </div>
      </div>
    </label>
  );
};
