import React, { FC } from 'react';
import { DataTestId } from '@Types/playwright';
import { GridIcon } from 'components/icons/grid-icon';
import { ListIcon } from 'components/icons/list-icon';

export type ProductView = 'grid' | 'list';

type ProductViewSwitchProps = {
  onChange?: (view: ProductView) => void;
  value?: ProductView;
  justify?: 'start' | 'end' | 'center';
} & DataTestId;

export const ProductViewSwitch: FC<ProductViewSwitchProps> = ({ onChange, value, justify = 'end', ...props }) => {
  return (
    <div className={`my-4 inline-flex w-full flex-row items-center justify-${justify} gap-4`} {...props}>
      <div
        onClick={() => onChange && onChange('grid')}
        className={'cursor-pointer rounded ' + (value === 'grid' ? 'border border-neutral-300' : '')}
      >
        <GridIcon color={value === 'grid' ? 'rgb(22 78 99)' : 'black'} />
      </div>
      <div
        onClick={() => onChange && onChange('list')}
        className={'cursor-pointer rounded ' + (value === 'list' ? 'border border-neutral-300' : '')}
      >
        <ListIcon color={value === 'list' ? 'rgb(22 78 99)' : 'black'} />
      </div>
    </div>
  );
};
