import React, { useEffect, useState } from 'react';
import { LineItem } from '@Types/wishlist/LineItem';
import { Wishlist } from '@Types/wishlist/Wishlist';
import Spinner from 'components/commercetools-ui/spinner';
import EmptyWishlist from 'components/commercetools-ui/wishlist/empty_wishlist';
import { Reference } from 'helpers/reference';
import List from './list';

export interface Props {
  pageTitle?: string;
  emptyStateImage?: { media: any } | any;
  emptyStateTitle?: string;
  emptyStateSubtitle?: string;
  emptyStateCTALabel?: string;
  emptyStateCTALink?: Reference;
  items?: Wishlist;
  removeLineItems: (item: LineItem) => Promise<void>;
}

const WishList: React.FC<Props> = ({
  pageTitle,
  emptyStateImage,
  emptyStateTitle,
  emptyStateSubtitle,
  emptyStateCTALabel,
  emptyStateCTALink,
  items,
  removeLineItems,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (items?.lineItems) setLoading(false);
  }, [items]);

  if (loading)
    return (
      <div className="flex h-[75vh] items-center justify-center">
        <Spinner />
      </div>
    );

  if (items.lineItems.length === 0)
    return (
      <EmptyWishlist
        pageTitle={pageTitle}
        title={emptyStateTitle}
        subtitle={emptyStateSubtitle}
        ctaLabel={emptyStateCTALabel}
        ctaLink={emptyStateCTALink}
        image={emptyStateImage}
      />
    );

  return (
    <main className="mx-auto w-full pb-12">
      <List items={items.lineItems} removeLineItems={removeLineItems} />
    </main>
  );
};

export default WishList;
