import React from 'react';
import { FullPageWidthWrapper } from 'components/tailgrids-ui/full-bleed';
import HeroImage from 'components/tailgrids-ui/hero/hero-image';

const TileTastic = ({ data }) => {
  return (
    <FullPageWidthWrapper>
      <div className="fixed-screen-width lg:relative-width">
        <HeroImage
          image={data.image}
          title={data.title}
          titleColor={data.titleColor}
          subtitle={data.subtitle}
          text={data.text}
          textColor={data.textColor}
          ctaLabel={data.ctaLabel}
          ctaReference={data.ctaReference}
        />
      </div>
    </FullPageWidthWrapper>
  );
};

export default TileTastic;
