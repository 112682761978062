import React from 'react';
import { DataTestId } from '@Types/playwright';
import { useRefinementList, UseRefinementListProps } from 'react-instantsearch';
import ChevronDown from 'components/icons/chevron-down';
import { CheckBoxFilter } from 'components/tailgrids-ui/products/product-filter';
import { TYPESENSE_LABEL_PREFIX } from 'features/typesense/utils';
import { ctColorToCssColor } from 'helpers/ctColorToCssColor';
import { useFormat } from 'helpers/hooks/useFormat';

type FilterProps = UseRefinementListProps & { open?: boolean } & DataTestId;

export const Filter = (props: FilterProps) => {
  const { open, ...rest } = props;
  const { items, refine, isShowingMore, toggleShowMore, canToggleShowMore, canRefine } = useRefinementList(rest);
  const isColor = props.attribute.includes('color');
  const { formatMessage } = useFormat({ name: 'product' });

  return (
    <>
      <CheckBoxFilter
        open={open}
        label={TYPESENSE_LABEL_PREFIX + props.attribute}
        defaultLabel={props.attribute}
        items={items
          ?.filter((item) => !!item.label)
          ?.map((item) => {
            let colorCode = '';
            if (isColor) {
              colorCode = ctColorToCssColor(item.label);
            }
            return {
              label: item.label,
              value: item.value,
              color: colorCode,
              selected: item.isRefined,
              onChange: (label: string) => {
                if (canRefine) {
                  refine(label);
                }
              },
            };
          })}
        canToggleShowMore={canToggleShowMore}
        isShowingMore={isShowingMore}
        toggleShowMore={toggleShowMore}
        data-testid={props['data-testid']}
      >
        <div
          className={
            'flex w-full cursor-pointer select-none flex-row items-center justify-center ' +
            (canToggleShowMore ? '' : 'hidden')
          }
          onClick={() => toggleShowMore()}
          data-testid={isShowingMore ? 'show-less' : 'show-more'}
        >
          {isShowingMore ? (
            <>
              {formatMessage({ id: TYPESENSE_LABEL_PREFIX + 'showLess', defaultMessage: 'Show Less' })}
              <ChevronDown className="rotate-180" />
            </>
          ) : (
            <>
              {formatMessage({ id: TYPESENSE_LABEL_PREFIX + 'showMore', defaultMessage: 'Show More' })}
              <ChevronDown />
            </>
          )}
        </div>
      </CheckBoxFilter>
    </>
  );
};
