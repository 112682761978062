import { PageBodyDynamicZone } from 'generated/strapiTypes';
import { CZ12TextBlockAdapter } from './CZ12TextBlock/CZ12TextBlockAdapter';
import { CZ15ArticleAdapter } from './CZ15Article/CZ15ArticleAdapter';
import { CZ16FeaturesAdapter } from './CZ16Features/CZ16FeaturesAdapters';
import { CZ17AboutUsAdapter } from './CZ17AboutUs/CZ17AboutUsAdapter';
import { CZ10AccordionAdapter } from './CZ10Accordion/CZ10AccordionAdapter';
import { CZ11CtaAdapter } from './CZ11Cta/CZ11CtaAdapter';
import { CZ14ImageGalleryAdapter } from './CZ14ImageGallery/CZ14ImageGalleryAdapter';
import { CZ13ImageWithTextAdapter } from './CZ13ImageWithText/CZ13ImageWithTextAdapter';
import { ContactFormAdapter } from '../contact-form/ContactFormAdapter';
import { BannerAdapter } from './Banner/BannerAdapter';
import { ProductListAdapter } from './CZProducts/ProductListAdapter';
import { SingleProductAdapter } from './CZProducts/SingleProductAdapter';

type SectionMapperProps = {
  component: PageBodyDynamicZone;
};

type Typenames = Exclude<SectionMapperProps['component']['__typename'], undefined>;

const sectionMapper: Partial<Record<Typenames, React.FC<any>>> = {
  ComponentSectionsS55Form: ContactFormAdapter,
  ComponentBasicBlockS1Banner: BannerAdapter,
  ComponentBasicBlockS3SingleProduct: SingleProductAdapter,
  ComponentBasicBlockS4ProductList: ProductListAdapter,
  ComponentSectionsCz10Accordion: CZ10AccordionAdapter,
  ComponentSectionsCz11Cta: CZ11CtaAdapter,
  ComponentSectionsCz12TextBlock: CZ12TextBlockAdapter,
  ComponentSectionsCz13ImageWithText: CZ13ImageWithTextAdapter,
  ComponentSectionsCz14ImageGallery: CZ14ImageGalleryAdapter,
  ComponentSectionsCz15Article: CZ15ArticleAdapter,
  ComponentSectionsCz16Features: CZ16FeaturesAdapter,
  ComponentSectionsCzAboutUs: CZ17AboutUsAdapter,
};

export const ComponentMapper = ({ component }: SectionMapperProps) => {
  if (!component.__typename) {
    return null;
  }

  const DynamicComponent = sectionMapper[component.__typename];

  if (!DynamicComponent) {
    return null;
  }

  return <DynamicComponent {...(component as any)} />;
};
