import { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Address } from '@Types/account/Address';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount, useCart } from 'frontastic';
import Register from '../checkout-register';
import { mapToCartStructure } from './mapFormData';
import AddressComponent, { CustomCheckbox, regionToZone } from './panels/address';
import Checkout from './panels/checkout';
import CheckoutSummary from './panels/checkoutSummary';
import ShippingMethods from './panels/shippingMethods';
import { requiredDataIsValid } from './requiredDataIsValid';
import useQueryParamState from 'helpers/hooks/useQueryParam';
import { FullPageWidthWrapper } from '../full-bleed';
import dynamic from 'next/dynamic';

const DynamicAdyenPayment = dynamic(() => import('./panels/commercetoolsCheckout'), {
  ssr: false,
});

const DynamicGuestCheckout = dynamic(() => import('./commercetools-checkout/guestChekout'), {
  ssr: false,
});

const DynamicUserCheckout = dynamic(() => import('./commercetools-checkout/userCheckout'), {
  ssr: false,
});

export type FormData = {
  firstName: string;
  lastName: string;
  phone?: string;
  email: string;
  shippingFirstName: string;
  shippingLastName: string;
  shippingStreetName: string;
  shippingCity: string;
  shippingPostalCode: string;
  shippingCountry: string;
  // additionalAddressInfo?: string;
  shippingAdditionalAddressInfo?: string;
  billingFirstName: string;
  billingLastName: string;
  billingStreetName: string;
  billingCity: string;
  billingPostalCode: string;
  billingCountry: string;
  billingAdditionalAddressInfo?: string;
  // packstation?: string;
  billingPackstation?: string;
  shippingPackstation?: string;
};

const AdyenCheckout = ({ termsLink, cancellationLink, privacyLink, commercetoolsCheckout }) => {
  const { data: cartList, updateCart, setShippingMethod, removeItem } = useCart();
  const { formatMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { account } = useAccount();
  const router = useRouter();
  const containerRef = useRef();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentStepIndex, setCurrentStepIndex] = useQueryParamState<number>('step', 0);
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true);
  const [billingIsSameAsShipping, setBillingIsSameAsShipping] = useState<boolean>(true);
  const [shippingIsSameAsBilling, setShippingIsSameAsBilling] = useState<boolean>(true);
  const [currentShippingMethod, setCurrentShippingMethod] = useState<ShippingMethod>();
  const { loggedIn } = useAccount();
  const [cartItems, setCartItems] = useState([]);
  const { data: cartData } = useCart();
  const [dataIsValid, setDataIsValid] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [saveAddress, setSaveAddress] = useState<boolean>(false);
  const trigger = useRef(null);
  const modal = useRef(null);

  const isSameBillingCountry = useMemo(() => {
    return (
      loggedIn &&
      account?.addresses?.find((address) => address?.isDefaultBillingAddress)?.country === router.locale.split('-')[1]
    );
  }, []);

  const isSameShippingCountry = useMemo(() => {
    return (
      loggedIn &&
      account?.addresses?.find((address) => address?.isDefaultBillingAddress)?.country === router.locale.split('-')[1]
    );
  }, []);

  const [data, setData] = useState<FormData & { billingAddressId?: string }>({
    firstName: account?.firstName ?? account?.addresses[0]?.firstName ?? '',
    lastName: account?.lastName ?? account?.addresses[0]?.lastName ?? '',
    phone: isSameBillingCountry
      ? account?.addresses?.find((address) => address.isDefaultBillingAddress)?.phone
      : account?.addresses?.find((address) => address.country === router.locale.split('-')[1])?.phone ?? '',
    email: account?.email ?? '',
    shippingFirstName: isSameBillingCountry
      ? account?.addresses?.find((address) => address.isDefaultShippingAddress)?.firstName
      : account?.addresses?.find((address) => address.country === router.locale.split('-')[1])?.firstName ??
        account?.firstName ??
        '',
    shippingLastName: isSameBillingCountry
      ? account?.addresses?.find((address) => address.isDefaultShippingAddress)?.lastName
      : account?.addresses?.find((address) => address.country === router.locale.split('-')[1])?.lastName ??
        account?.lastName ??
        '',
    shippingStreetName: isSameShippingCountry
      ? account?.addresses?.find((address) => address.isDefaultShippingAddress)?.streetName
      : account?.addresses?.find((address) => address.country === router.locale.split('-')[1])?.streetName ?? '',
    shippingAdditionalAddressInfo:
      account?.addresses?.find((address) => address.isDefaultShippingAddress)?.additionalAddressInfo ?? '',
    shippingCity: isSameShippingCountry
      ? account?.addresses?.find((address) => address.isDefaultShippingAddress)?.city
      : account?.addresses?.find((address) => address.country === router.locale.split('-')[1])?.city ?? '',
    shippingPostalCode: isSameShippingCountry
      ? account?.addresses?.find((address) => address.isDefaultShippingAddress)?.postalCode
      : account?.addresses?.find((address) => address.country === router.locale.split('-')[1])?.postalCode ?? '',
    shippingCountry: isSameShippingCountry
      ? account?.addresses?.find((address) => address.isDefaultShippingAddress)?.country
      : account?.addresses?.find((address) => address.country === router.locale.split('-')[1])?.country ??
        router.locale.split('-')[1],
    shippingPackstation: isSameShippingCountry
      ? account?.addresses?.find((address) => address.isDefaultShippingAddress)?.packstationNumber
      : account?.addresses?.find((address) => address.country === router.locale.split('-')[1])?.packstationNumber ?? '',
    billingFirstName: isSameBillingCountry
      ? account?.addresses?.find((address) => address.isDefaultBillingAddress)?.firstName
      : account?.addresses?.find((address) => address.country === router.locale.split('-')[1])?.firstName ??
        account?.firstName ??
        '',
    billingLastName: isSameBillingCountry
      ? account?.addresses?.find((address) => address.isDefaultBillingAddress)?.lastName
      : account?.addresses?.find((address) => address.country === router.locale.split('-')[1])?.lastName ??
        account?.lastName ??
        '',
    billingStreetName: isSameBillingCountry
      ? account?.addresses?.find((address) => address.isDefaultBillingAddress)?.streetName
      : account?.addresses?.find((address) => address.country === router.locale.split('-')[1])?.streetName ?? '',
    billingCity: isSameBillingCountry
      ? account?.addresses?.find((address) => address.isDefaultBillingAddress)?.city
      : account?.addresses?.find((address) => address.country === router.locale.split('-')[1])?.city ?? '',
    billingPostalCode: isSameBillingCountry
      ? account?.addresses?.find((address) => address.isDefaultBillingAddress)?.postalCode
      : account?.addresses?.find((address) => address.country === router.locale.split('-')[1])?.postalCode ?? '',
    billingCountry: isSameBillingCountry
      ? account?.addresses?.find((address) => address.isDefaultBillingAddress)?.country
      : account?.addresses?.find((address) => address.country === router.locale.split('-')[1])?.country ??
        router.locale.split('-')[1],
    billingAdditionalAddressInfo: isSameBillingCountry
      ? account?.addresses?.find((address) => address.isDefaultBillingAddress)?.additionalAddressInfo
      : account?.addresses?.find((address) => address.country === router.locale.split('-')[1])?.additionalAddressInfo ??
        '',
    billingPackstation: isSameBillingCountry
      ? account?.addresses?.find((address) => address.isDefaultBillingAddress)?.packstationNumber
      : account?.addresses?.find((address) => address.country === router.locale.split('-')[1])?.packstationNumber ?? '',
  });

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!modal.current) return;
      if (!modalOpen || modal.current.contains(target) || trigger.current.contains(target)) return;
      setModalOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      setModalOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const changeStep = (stepIndex: number) => {
    if (currentStepIndex > stepIndex) {
      setCurrentStepIndex(stepIndex);
    }
  };

  const toggleBillingAddressOption = () => {
    setBillingIsSameAsShipping(!billingIsSameAsShipping);
  };

  const toggleShippingAddressOption = () => {
    setShippingIsSameAsBilling(!shippingIsSameAsBilling);
  };

  const generateStepTag = (index: number) => (
    <div
      className={`mx-auto flex h-12 w-12 items-center rounded-full text-lg  text-white ${
        index == currentStepIndex ? 'bg-[#33B8A1]' : 'border-2 border-transparent bg-[#242424]'
      }`}
    >
      <span className="w-full text-center font-bold text-white">{index + 1}</span>
    </div>
  );

  const goToTopOfPage = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const gotToNextStep = () => {
    if (currentStepIndex == 0) {
      updateCartData();
    }

    setCurrentStepIndex(currentStepIndex + 1);
    goToTopOfPage();
  };

  const gotToPrevStep = () => {
    setCurrentStepIndex(currentStepIndex - 1);
    goToTopOfPage();
  };

  const updateData = setData;

  const updateCartData = () => {
    if (dataIsValid) {
      // const updatedData = mapToCartStructure(data, billingIsSameAsShipping);
      const updatedData = mapToCartStructure(data, shippingIsSameAsBilling);

      setIsLoading(true);
      updateCart(updatedData).finally(() => {
        setIsLoading(false);
      });
    }
  };

  const updatecurrentShippingMethod = (shippingMethod: ShippingMethod) => {
    if (shippingMethod?.shippingMethodId) {
      setCurrentShippingMethod(shippingMethod);
      setShippingMethod(shippingMethod.shippingMethodId);
    }
  };

  const submitButtonLabel = [
    formatMessage({ id: 'goToOverview', defaultMessage: 'Go to overview' }),
    formatMessage({ id: 'ContinueAndPay', defaultMessage: 'Continue and pay' }),
  ];

  const submitButtonTestId = ['to-overview', 'to-payment'];

  useEffect(() => {
    if (cartList?.lineItems?.length === 0) {
      router.push('/');
    }
  }, []);

  useEffect(() => {
    const removeSpecial = async () => {
      if (loggedIn) {
        setCartItems(cartList?.lineItems);
      } else {
        if (
          cartList?.lineItems !== undefined &&
          cartList?.lineItems?.filter((item) => item.variant.attributes.loggedInOnly).length > 0
        ) {
          const loggedInProducts = cartList.lineItems.filter((item) => item.variant.attributes.loggedInOnly);
          for (const product of loggedInProducts) {
            await removeItem(product.lineItemId);
          }
        }
      }
    };
    removeSpecial();
  }, [loggedIn]);

  const availableAddresses = useMemo(() => {
    return account?.addresses
      .filter((address) => regionToZone[address.country] === regionToZone[router.locale.split('-')[1]])
      .reduce((accumulator, current) => {
        if (
          !accumulator.find(
            (item) =>
              item.firstName === current.firstName &&
              item.lastName === current.lastName &&
              item.streetName === current.streetName &&
              item.postalCode === current.postalCode &&
              item.city === current.city &&
              item.country === current.country,
          )
        ) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
  }, [account?.addresses]);

  const steps = [
    {
      name: formatMessage({ id: 'address', defaultMessage: 'Address' }),
      component: (
        <AddressComponent
          data={data}
          updateData={updateData}
          shippingIsSameAsBilling={shippingIsSameAsBilling}
          toggleBillingAddressOption={toggleBillingAddressOption}
          toggleShippingAddressOption={toggleShippingAddressOption}
          shippingMethodSelector={
            <ShippingMethods
              shippingMethods={cartList?.availableShippingMethods}
              currentShippingMethod={currentShippingMethod}
              onSelectShippingMethod={updatecurrentShippingMethod}
            />
          }
          disabledBtn={!dataIsValid}
          availableAddresses={availableAddresses}
          addressSelector={
            <div className="container mx-auto py-2 px-6">
              <button
                ref={trigger}
                onClick={() => setModalOpen(true)}
                disabled={
                  account?.addresses.filter(
                    (address) => regionToZone[address.country] === regionToZone[router.locale.split('-')[1]],
                  ).length === 0
                }
                className="disabled: my-4 cursor-pointer rounded bg-primary py-2 px-4 text-white hover:bg-[#33B8A1] disabled:cursor-auto disabled:bg-[#EDEDED] disabled:text-[#808080]"
              >
                {formatCheckoutMessage({ id: 'availableAddresses', defaultMessage: 'Available Addresses' })}
              </button>
              <div
                className={`z-70 fixed top-0 left-0 flex h-full min-h-screen w-full items-center justify-center bg-black bg-opacity-80 px-4 py-5 ${
                  modalOpen ? 'block' : 'hidden'
                }`}
              >
                <div
                  ref={modal}
                  onFocus={() => setModalOpen(true)}
                  onBlur={() => setModalOpen(false)}
                  className="w-full max-w-[570px] rounded-[20px] bg-white py-12 px-8 text-center md:py-[60px] md:px-[70px]"
                >
                  <h3 className="my-6 text-base font-bold text-[#242424]">
                    {formatCheckoutMessage({ id: 'availableAddresses', defaultMessage: 'Available Addresses' })}
                  </h3>
                  <div className="flex flex-col flex-wrap justify-center gap-6">
                    {availableAddresses?.map((address: Address, idx) => (
                      <div
                        className="flex w-full cursor-pointer flex-col rounded border-2 border-[#E7E7E7] px-2 py-4 text-[#808080] hover:border-primary hover:bg-primary hover:text-white"
                        key={idx}
                        onClick={() => {
                          setData({
                            firstName: address.firstName ?? 'firstName',
                            lastName: address.lastName ?? 'lastname',
                            phone: address.phone ?? '',
                            email: account?.email ?? '',
                            shippingFirstName: address.firstName ?? '',
                            shippingLastName: address.lastName ?? '',
                            shippingStreetName: address.streetName ?? '',
                            shippingAdditionalAddressInfo: address.additionalAddressInfo ?? '',
                            shippingCity: address.city ?? '',
                            shippingPostalCode: address.postalCode ?? '',
                            shippingCountry: address.country ?? '',
                            billingFirstName: address.firstName ?? '',
                            billingLastName: address.lastName ?? '',
                            billingStreetName: address.streetName ?? '',
                            billingCity: address.city ?? '',
                            billingPostalCode: address.postalCode ?? '',
                            billingAdditionalAddressInfo: address.additionalAddressInfo,
                            billingCountry: address.country ?? '',
                            billingPackstation: address.packstationNumber ?? '',
                            billingAddressId: address.addressId ?? '',
                          });
                          setModalOpen(false);
                        }}
                      >
                        <span>{`${address.streetName}`}</span>
                        <span>{`${address.postalCode} ${address.city}`}</span>
                        <span>{`${address.country}`}</span>
                      </div>
                    ))}
                    <div className="flex flex-wrap">
                      <button
                        onClick={() => setModalOpen(false)}
                        className="disabled: my-4 cursor-pointer rounded bg-primary py-2 px-4 text-white hover:bg-[#33B8A1] disabled:cursor-auto disabled:bg-[#EDEDED] disabled:text-[#808080]"
                      >
                        {formatCommonMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        >
          {!loggedIn && (
            <label className="mb-2 flex items-center px-4 text-sm md:px-6">
              <CustomCheckbox
                labelTitle={formatCheckoutMessage({
                  id: 'saveForFuture',
                  defaultMessage: 'Create an account and save my address for future use',
                })}
                id="save-address"
                checked={saveAddress}
                onChange={() => setSaveAddress(!saveAddress)}
              />
            </label>
          )}

          {!loggedIn && saveAddress && (
            <Register
              newUserEmail={data.email ?? ''}
              showInCheckout={true}
              saveAddress={saveAddress}
              newBillingAddress={{
                firstName: data.billingFirstName,
                lastName: data.billingLastName,
                streetName: data.billingStreetName,
                additionalAddressInfo: data.billingAdditionalAddressInfo,
                postalCode: data.billingPostalCode,
                city: data.billingCity,
                country: data.billingCountry,
                isDefaultBillingAddress: true,
                isDefaultShippingAddress: false,
              }}
              newShippingAddress={{
                firstName: data.shippingFirstName,
                lastName: data.shippingLastName,
                streetName: data.shippingStreetName,
                additionalAddressInfo: data.shippingAdditionalAddressInfo,
                postalCode: data.shippingPostalCode,
                city: data.shippingCity,
                country: data.shippingCountry,
                isDefaultBillingAddress: false,
                isDefaultShippingAddress: true,
              }}
            />
          )}
        </AddressComponent>
      ),
    },
    {
      name: formatMessage({ id: 'overview', defaultMessage: 'Overview' }),
      component: (
        // <Overview
        //   shippingMethods={cartList?.availableShippingMethods}
        //   currentShippingMethod={currentShippingMethod}
        //   onSelectShippingMethod={updatecurrentShippingMethod}
        // />
        <ShippingMethods
          shippingMethods={cartList?.availableShippingMethods}
          currentShippingMethod={currentShippingMethod}
          onSelectShippingMethod={updatecurrentShippingMethod}
        />
      ),
    },
    {
      name: formatMessage({ id: 'payment', defaultMessage: 'Payment' }),

      component:
        loggedIn && commercetoolsCheckout?.includes(router.locale) ? (
          <DynamicUserCheckout />
        ) : !loggedIn && commercetoolsCheckout?.includes(router.locale) ? (
          <DynamicGuestCheckout />
        ) : (
          <Checkout />
        ),
    },
  ];

  useEffect(() => {
    setDataIsValid(requiredDataIsValid(data, shippingIsSameAsBilling));
  }, [data, shippingIsSameAsBilling]);

  useEffect(() => {
    setDisableSubmitButton(!dataIsValid);
  }, [dataIsValid]);

  useEffect(() => {
    if (data.shippingCountry !== '') {
      updateCartData();
    }
  }, [data.shippingCountry, dataIsValid]);

  // useEffect(() => {
  //   const defaultData = mapToFormStructure(cartList);
  //   if (defaultData && requiredDataIsValid(defaultData, billingIsSameAsShipping)) {
  //     updateData(defaultData);
  //   }
  //   if (defaultData && requiredDataIsValid(defaultData, shippingIsSameAsBilling)) {
  //     updateData(defaultData);
  //   }
  // }, [cartList]);

  useEffect(() => {
    if (!currentShippingMethod && cartList?.availableShippingMethods) {
      if (cartList?.shippingInfo) {
        const currentShippingMethod = cartList.availableShippingMethods.find(
          ({ shippingMethodId }) => shippingMethodId == cartList.shippingInfo.shippingMethodId,
        );
        setCurrentShippingMethod(currentShippingMethod);
      } else {
        setCurrentShippingMethod(cartList?.availableShippingMethods?.[0]);
      }
    }
  }, [cartList?.availableShippingMethods]);

  return (
    <FullPageWidthWrapper className="bg-[#F4F6FB]">
      <div className="mx-auto max-w-[1280px] bg-[#F4F6FB] px-4 sm:px-10 lg:px-4">
        <div>
          <div className="mx-auto py-6">
            {!commercetoolsCheckout?.includes(router.locale) ? (
              <div className="relative flex justify-between py-6 px-5 md:px-12" id="ProgressStepper">
                <div className="absolute top-0 left-0 flex h-full w-full items-center justify-between py-6 px-12 ">
                  <div className="top-2/4 h-1 w-full bg-[#E7E7E7]"></div>
                </div>

                {steps.map(({ name }, index) => (
                  <button key={index} className="relative rounded bg-[#F4F6FB] p-2" onClick={() => changeStep(index)}>
                    {generateStepTag(index)}
                    <div className="text-center text-xs 2xl:text-base">{name}</div>
                  </button>
                ))}
              </div>
            ) : (
              <h3 className="mb-12 text-heading-3 font-bold text-black">Checkout</h3>
            )}
          </div>
        </div>
        <div className="mt-8 w-full lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-16" ref={containerRef}>
          {!commercetoolsCheckout?.includes(router.locale) ? steps[currentStepIndex].component : steps[2].component}
          <CheckoutSummary
            cart={cartList}
            submitButtonLabel={submitButtonLabel[currentStepIndex]}
            submitButtonTestId={submitButtonTestId[currentStepIndex]}
            disableSubmitButton={
              (currentStepIndex > 0 && cartData?.shippingInfo === undefined) ||
              currentShippingMethod === undefined ||
              isLoading ||
              disableSubmitButton
            }
            showDiscountsForm={currentStepIndex < 2}
            showSubmitButton={currentStepIndex < 2}
            onSubmit={gotToNextStep}
            termsLink={termsLink}
            cancellationLink={cancellationLink}
            privacyLink={privacyLink}
          />
        </div>
      </div>
    </FullPageWidthWrapper>
  );
};

export default AdyenCheckout;
