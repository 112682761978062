import { MouseEvent, ReactElement, useMemo } from 'react';
import { GiftIcon } from '@heroicons/react/outline';
import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { useTranslation } from 'next-i18next';
import DiscountForm from 'components/commercetools-ui/discount-form';
import Price from 'components/commercetools-ui/price';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';
import { StringHelpers } from 'helpers/stringHelpers';
import Image from 'frontastic/lib/image';
import { CustomImage } from 'lib/customImage';

interface Props {
  readonly cart: Cart;
  readonly onSubmit?: (e: MouseEvent) => void;
  readonly submitButtonLabel?: string;
  readonly submitButtonTestId?: string;
  readonly disableSubmitButton?: boolean;
  readonly showSubmitButton?: boolean;
  readonly showDiscountsForm?: boolean;

  termsLink?: Reference;
  cancellationLink?: Reference;
  privacyLink?: Reference;
}

const CheckoutSummary = ({
  cart,
  onSubmit,
  showSubmitButton = true,
  showDiscountsForm = true,
  submitButtonLabel,
  submitButtonTestId,
  disableSubmitButton,
  termsLink,
  cancellationLink,
  privacyLink,
}: Props) => {
  //i18n messages
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { t } = useTranslation(['cart']);

  const submitButtonClassName = `${disableSubmitButton ? 'bg-[#808080] cursor-auto' : 'hover:bg-[#33B8A1]'} ${
    !showDiscountsForm ? 'mt-7' : ''
  } w-full rounded-sm border border-transparent py-3 px-4 text-base shadow-sm font-medium text-white bg-primary focus:outline-none`;

  const interpolatedComponents = [
    <ReferenceLink key={0} className="cursor-pointer font-medium text-primary hover:underline" target={termsLink} />,
    <ReferenceLink
      key={1}
      className="cursor-pointer font-medium text-primary hover:underline"
      target={cancellationLink}
    />,
    <ReferenceLink key={2} className="cursor-pointer font-medium text-primary hover:underline" target={privacyLink} />,
  ];

  const totalTaxes = cart?.taxed?.taxPortions?.reduce((a, b) => a + b.amount.centAmount, 0);

  const productPrice = cart?.lineItems?.reduce((a, b: LineItem) => {
    if (b.discountedPrice) {
      return a + b.discountedPrice.centAmount * b.count;
    } else {
      return a + b.price.centAmount * b.count;
    }
  }, 0);

  const discountPrice = cart?.lineItems?.reduce((a, b) => {
    const dp =
      a +
      b.count *
        b.discounts.reduce((x, y) => {
          return x + y.discountedAmount.centAmount;
        }, 0);
    return productPrice - cart.sum.centAmount + (cart?.shippingInfo?.price.centAmount ?? 0);
  }, 0);

  const remainingPrice =
    discountPrice > 0 ? cart?.sum?.centAmount - productPrice + discountPrice : cart?.sum?.centAmount - productPrice;

  const lineItemsNumber = useMemo(() => {
    const itemsNumber = cart?.lineItems?.reduce(function (acc, lineItem) {
      return acc + lineItem.count;
    }, 0);
    return itemsNumber;
  }, []);

  return (
    <section aria-labelledby="summary-heading" className="sm:col-span-8 lg:col-span-4">
      <DiscountForm cart={cart} inCheckout />
      <div className="shadow-card mb-10 overflow-hidden rounded-[4px] bg-white p-8">
        <div className="mb-2 border-b border-[#e7e7e7] pb-5">
          <h3 className="mb-2 text-lg font-semibold text-[#242424]">
            {formatCartMessage({ id: 'myCart', defaultMessage: 'My Shopping Cart' })}
          </h3>
          <p className="text-sm font-medium text-[#242424]">
            {t('wishlist:wishlist.itemsNumber', { itemsNumber: lineItemsNumber })}
          </p>
        </div>

        <div className="border-b border-[#e7e7e7] pb-3">
          {cart?.lineItems?.map((lineItem) => (
            <CartItem
              key={lineItem.lineItemId}
              img={lineItem?.variant?.images[0]}
              name={`${lineItem?.count}x ${lineItem?.name}`}
              color={StringHelpers.capitaliseFirstLetter(lineItem?.variant?.attributes?.color?.label ?? '')}
              size={lineItem?.variant?.attributes?.commonSize?.key.toUpperCase() ?? ''}
              price={<Price price={lineItem?.price || {}} className="text-base font-semibold text-[#242424]" />}
              discountedPrice={
                lineItem?.discounts.length > 0 ? (
                  <Price
                    price={
                      {
                        centAmount: lineItem?.price.centAmount - lineItem?.discounts[0].discountedAmount.centAmount,
                        currencyCode: lineItem?.price.currencyCode,
                        fractionDigits: lineItem?.price.fractionDigits,
                      } || {}
                    }
                    className="text-base font-semibold text-[#242424]"
                  />
                ) : null
              }
            />
          ))}
        </div>

        <div className="border-b border-[#e7e7e7] py-7">
          <div className="mb-3 flex items-center justify-between">
            <div className="px-1">
              <p className="text-base font-medium text-[#242424]">
                {formatCartMessage({ id: 'subtotal', defaultMessage: 'Subtotal' })}
              </p>
            </div>
            <Price
              price={{
                centAmount: productPrice,
                currencyCode: cart?.sum?.currencyCode,
              }}
              className="px-1 text-base font-semibold text-[#242424]"
            />
          </div>

          {cart?.shippingInfo && (
            <div>
              <div className="mb-3 flex items-center justify-between">
                <div className="px-1">
                  <p className="text-base font-medium text-[#242424]">
                    {formatCartMessage({ id: 'shipping.estimate', defaultMessage: 'Shipping estimate' })}
                  </p>
                </div>
                <Price
                  price={cart?.shippingInfo?.price || {}}
                  className="px-1 text-base font-semibold text-[#242424]"
                />
              </div>

              {cart?.shippingInfo?.price.centAmount !== remainingPrice && (
                <div className="mb-3 flex items-center justify-between">
                  <div className="px-1">
                    <p className="text-base font-medium text-[#242424]">
                      {formatCartMessage({ id: 'shipping.discount', defaultMessage: 'Shipping discount' })}
                    </p>
                  </div>
                  <Price
                    price={{
                      centAmount: -remainingPrice,
                      currencyCode: cart?.sum?.currencyCode,
                    }}
                    className="px-1 text-base font-semibold text-[#242424]"
                  />
                </div>
              )}
            </div>
          )}

          <div className="mb-3 flex items-center justify-between">
            <div className="px-1">
              <p
                className={`text-base font-medium  ${
                  cart?.discountCodes?.length > 0 ? 'text-[#33B8A1]' : 'text-[#242424]'
                }`}
              >
                {formatCartMessage({ id: 'codesApplied', defaultMessage: 'Codes Applied' })}
              </p>
            </div>
            <span
              className={`px-1 text-base font-semibold ${
                cart?.discountCodes?.length > 0 ? 'text-[#33B8A1]' : 'text-[#242424]'
              }`}
            >
              {cart?.discountCodes?.length > 0 ? (
                cart.discountCodes.map((code, idx) => <p key={idx}>{code.code}</p>)
              ) : (
                <p>-</p>
              )}
            </span>
          </div>

          {cart?.lineItems?.filter((item) => item.isGift).length > 0 && (
            <div className="mb-3 flex items-center justify-between">
              <div className="px-1">
                <p className="text-base font-medium text-[#242424]">
                  {formatCartMessage({ id: 'gift', defaultMessage: 'Gift Added' })}
                </p>
              </div>
              <div className="inline-flex px-1 text-primary">
                <GiftIcon className="h-5 w-5" aria-hidden="true" />
              </div>
            </div>
          )}

          <div className="mb-3 flex items-center justify-between">
            <div className="px-1">
              <p className="text-base font-medium text-[#242424]">
                {formatCartMessage({ id: 'discounts', defaultMessage: 'Discounts' })}
              </p>
            </div>
            <Price
              price={
                {
                  fractionDigits: 2,
                  centAmount: discountPrice === 0 || discountPrice === undefined ? 0 : -discountPrice,
                  currencyCode: cart?.sum?.currencyCode,
                } || {}
              }
              className="px-1 text-base font-semibold text-[#242424]"
            />
          </div>
        </div>

        <div className="-mx-1 mb-3 flex items-center justify-between pt-5">
          <div className="px-1">
            <p className="text-base font-medium text-[#242424]">
              {formatCartMessage({ id: 'orderTotal', defaultMessage: 'Order total' })}
            </p>
          </div>
          <Price price={cart?.sum || {}} className="px-1 text-base font-semibold text-[#242424]" />
        </div>

        {cart?.taxed && (
          <div className="text-xs text-[#242424]">
            (
            {formatCartMessage({
              id: 'includedVat',
              defaultMessage: 'Tax included',
              values: { amount: CurrencyHelpers.formatForCurrency(totalTaxes || {}) },
            })}
            )
          </div>
        )}

        {showSubmitButton && (
          <div className="mt-6 w-full rounded-b bg-white">
            <button
              type="submit"
              disabled={disableSubmitButton}
              onClick={onSubmit}
              className={`${submitButtonClassName} rounded-[4px]`}
              data-testid={submitButtonTestId}
            >
              {submitButtonLabel || formatCartMessage({ id: 'checkout.go', defaultMessage: 'Go to checkout' })}
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default CheckoutSummary;

const CartItem = ({
  img,
  name,
  price,
  discountedPrice,
  color,
  size,
}: {
  img: string;
  name: string;
  price: ReactElement;
  discountedPrice?: ReactElement;
  color: string;
  size: string;
}) => {
  return (
    <div className="-mx-1 flex items-start justify-between py-4">
      <div className="flex items-center">
        <div className="mr-4 h-[50px] min-w-[50px] overflow-hidden rounded-[4px] border border-neutral-200">
          {/* <Image src={img} alt={img} className="h-auto w-full rounded-[2px] border border-neutral-200 object-contain" /> */}
          <CustomImage imgSrc={img} width={50} height={50} />
        </div>
        <div className="mr-1">
          <p className="text-base font-medium text-[#242424]">{name}</p>
          <p className="truncate text-sm font-medium text-[#808080]">{color}</p>
          <p className="truncate text-sm font-medium text-[#808080]">{size}</p>
        </div>
      </div>
      <div className="flex flex-col items-end">
        {discountedPrice && <span className="text-base font-semibold text-[#242424]">{discountedPrice}</span>}
        <span className={`text-base font-semibold text-[#242424] ${discountedPrice ? 'line-through' : ''}`}>
          {price}
        </span>
      </div>
    </div>
  );
};
