import React, { useState } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import Redirect from 'helpers/redirect';
import { Reference, ReferenceLink } from 'helpers/reference';
import { useAccount } from 'frontastic';
import { PartialAddress } from 'frontastic/actions/account';
import { NextFrontasticImage } from 'frontastic/lib/image';
import { useRouter } from 'next/router';

export interface RegisterProps {
  logo?: NextFrontasticImage;
  loginLink?: Reference;
  newUserEmail?: string;
  showInCheckout?: boolean;
  newBillingAddress?: PartialAddress;
  newShippingAddress?: PartialAddress;
  saveAddress?: boolean;
}

const Register: React.FC<RegisterProps> = ({
  loginLink,
  newUserEmail,
  showInCheckout,
  newBillingAddress,
  newShippingAddress,
  saveAddress = false,
}) => {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatSuccessMessage } = useFormat({ name: 'success' });
  const { formatMessage } = useFormat({ name: 'common' });
  const router = useRouter();

  //account actions
  const { register, loggedIn } = useAccount();

  //register data
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: newUserEmail ? newUserEmail : '',
    password: '',
    confirmPassword: '',
  });

  //error
  const [error, setError] = useState('');

  //success
  const [success, setSuccess] = useState('');

  //processing...
  const [loading, setLoading] = useState(false);

  //account creation error
  const [creationError, setCreationError] = useState<boolean>(false);

  //handle text input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, email: newUserEmail ? newUserEmail : data.email, [e.target.name]: e.target.value });
  };

  //data validation
  const validate = () => {
    //validation schema
    const passwordsMatch = data.password === data.confirmPassword;

    //UI error messages
    if (!passwordsMatch) {
      setCreationError(false);
      setError(formatErrorMessage({ id: 'password.noMatch', defaultMessage: "Passwords don't match" }));
    }
    //return a boolean representing the data validity
    return passwordsMatch;
  };

  //form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //validate data
    if (!validate()) return;
    //processing starts
    setLoading(true);
    //try registering the user with given credentials
    try {
      const response = await register(
        !showInCheckout
          ? {
              email: newUserEmail ? newUserEmail : data.email,
              firstName: data.firstName,
              lastName: data.lastName,
              password: data.password,
            }
          : showInCheckout && saveAddress
          ? {
              email: newUserEmail ? newUserEmail : data.email,
              password: data.password,
              firstName: data.firstName ?? newBillingAddress?.firstName ?? '',
              lastName: data.lastName ?? newBillingAddress?.lastName ?? '',
              billingAddress: newBillingAddress,
              shippingAddress: newShippingAddress,
            }
          : {
              email: newUserEmail ? newUserEmail : data.email,
              password: data.password,
              firstName: data.firstName ?? newBillingAddress?.firstName ?? '',
              lastName: data.lastName ?? newBillingAddress?.lastName ?? '',
            },
      );

      if (!response.accountId) {
        setCreationError(true);
        setError(
          // formatErrorMessage({ id: 'account.create.fail', defaultMessage: "Sorry. We couldn't create your account.." }),
          formatErrorMessage({
            id: 'accountNotCreated',
            defaultMessage: 'Your account could not be created, maybe your email is already registered? ',
          }),
        );
        setSuccess('');
      } else {
        setCreationError(false);
        setError('');
        // setSuccess(
        //   formatSuccessMessage({
        //     id: 'account.created',
        //     defaultMessage: 'A verification email was sent to {email} ✓',
        //     values: { email: newUserEmail ? newUserEmail : data.email },
        //   }),
        // );
      }
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
      setSuccess('');
    }
    //processing ends
    setLoading(false);
  };

  if (loggedIn && router.asPath === '/register?checkout') return <Redirect target="/cart" />;
  if (loggedIn && router.asPath !== '/register?checkout') return <Redirect target="/" />;

  return (
    <div className="flex min-h-full flex-col justify-center py-12 ">
      <div>
        <div className="mx-auto w-full max-w-[500px] rounded border border-gray-300 bg-white px-6 pb-32 pt-16 dark:bg-primary-200 lg:px-12">
          <form className="space-y-7" onSubmit={handleSubmit}>
            <div className="py-6 text-center">
              <h2 className="text-3xl font-extrabold text-neutral-700">
                {formatAccountMessage({ id: 'becomeMember', defaultMessage: 'Become a member' })}
              </h2>
              <h3 className="text-md mt-6 text-gray-700">
                {formatAccountMessage({
                  id: 'offers.doNotMiss',
                  defaultMessage: 'Don’t miss out on deals, offers, discounts and bonus vouchers.',
                })}
              </h3>
            </div>
            {success && <p className="text-sm text-[#34D399]">{success}</p>}
            {error && (
              <div className="justiy-start flex flex-col items-start gap-2">
                <p className="text-sm text-[#F87171]">{error}</p>
                {creationError && (
                  <p className="text-center text-sm text-[#808080]">
                    <ReferenceLink
                      target={{
                        link: '/login',
                        target: '/login',
                        openInNewWindow: false,
                        type: 'link',
                      }}
                      className="font-medium text-primary underline hover:text-[#33B8A1]"
                    >
                      {formatAccountMessage({ id: 'account.login.here', defaultMessage: 'Login here' })}
                    </ReferenceLink>
                  </p>
                )}
              </div>
            )}
            {!showInCheckout && (
              <>
                <div>
                  <label htmlFor="firstName" className="mb-2 block text-base font-medium text-[#808080]">
                    {formatMessage({ id: 'firstName', defaultMessage: 'First Name' })}
                  </label>
                  <div className="mt-2">
                    <input
                      id="firstName"
                      name="firstName"
                      type="firstName"
                      autoComplete="firstName"
                      placeholder={formatAccountMessage({
                        id: 'firstName.enter',
                        defaultMessage: 'Enter your first name',
                      })}
                      required
                      className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                      onChange={handleChange}
                      value={data.firstName}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="lastName" className="mb-2 block text-base font-medium text-[#808080]">
                    {formatMessage({ id: 'lastName', defaultMessage: 'Last Name' })}
                  </label>
                  <div className="mt-2">
                    <input
                      id="lastName"
                      name="lastName"
                      type="lastName"
                      autoComplete="lastName"
                      placeholder={formatAccountMessage({
                        id: 'lastName.enter',
                        defaultMessage: 'Enter your last name',
                      })}
                      required
                      className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                      onChange={handleChange}
                      value={data.lastName}
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="email" className="mb-2 block text-base font-medium text-[#808080]">
                    {formatMessage({ id: 'email', defaultMessage: 'Email' })}
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      placeholder={formatAccountMessage({ id: 'email.enter', defaultMessage: 'Enter your email' })}
                      required
                      className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                      onChange={handleChange}
                      value={newUserEmail ? newUserEmail : data.email}
                    />
                  </div>
                </div>
              </>
            )}
            <div>
              <label htmlFor="password" className="mb-2 block text-base font-medium text-[#808080]">
                {formatAccountMessage({ id: 'password', defaultMessage: 'Password' })}
              </label>
              <div className="relative mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                  required
                  className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="mb-2 block text-base font-medium text-[#808080]">
                {formatAccountMessage({ id: 'password.confirm', defaultMessage: 'Confirm Password' })}
              </label>
              <div className="relative mt-1">
                <input
                  id="confirm-password"
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                  placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                  required
                  className="w-full appearance-none rounded border border-gray-300 p-4 leading-tight text-gray-700 focus:border-primary focus:outline-none focus:ring-primary"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white transition-colors duration-200 ease-out hover:bg-[#33B8A1] focus:outline-none focus:ring-2 focus:ring-[#33B8A1] focus:ring-offset-2 disabled:bg-[#EDEDED]"
                disabled={loading}
              >
                {formatAccountMessage({ id: 'register', defaultMessage: 'Register' })}
              </button>
              {!showInCheckout && (
                <p className="mt-4 text-center text-sm text-neutral-600">
                  {formatAccountMessage({ id: 'account.doNotHave', defaultMessage: "Don't have an account?" })}{' '}
                  <ReferenceLink target={loginLink} className="font-medium text-primary underline hover:text-[#33B8A1]">
                    {formatAccountMessage({ id: 'account.login.here', defaultMessage: 'Login here' })}
                  </ReferenceLink>
                </p>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
