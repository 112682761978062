import { Order } from '@Types/cart/Order';
import { FullPageWidthWrapper } from 'components/tailgrids-ui/full-bleed';
import { MediaItemWithMods, useAccount } from 'frontastic';
import { getThankYouOrder } from 'frontastic/actions/cart';
import Image from 'frontastic/lib/image';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAnonymousStore } from 'helpers/stores/anonymousStore';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

type Props = {
  title: string;
  image: MediaItemWithMods;
  isCentered?: boolean;
  reference?: any;
  buttonText?: string;
};

export const ThankYouPromo = ({ title, image, isCentered, reference, buttonText }: Props) => {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { anonymousId } = useAnonymousStore();
  const router = useRouter();
  const { loggedIn } = useAccount();
  const [order, setOrder] = useState<Partial<Order>>();

  useEffect(() => {
    const getOrder = async () => {
      const order = await getThankYouOrder(router?.query?.orderId as string, anonymousId);
      setOrder(order);
    };
    getOrder();
  }, [loggedIn]);

  if (!order?.orderId) return <></>;

  const discountCode = '23456XY';
  const discountPercentage = '10%';
  const validDays = '7';

  return (
    <FullPageWidthWrapper>
      <section className="relative my-12 mx-auto h-[420px] max-w-[1280px] rounded px-4 sm:px-10 md:h-[246px] lg:px-4">
        <Image
          src={image?.media?.file}
          layout="fill"
          className="h-full w-full rounded object-cover object-center"
          alt="Banner Image"
        />
        <div
          className={`absolute top-1/2 left-1/2 flex h-full w-full flex-col items-start justify-center p-10`}
          style={{
            transform: 'translate(-50%, -50%)',
          }}
        >
          <div
            className={`flex w-full flex-col justify-center gap-5 p-2 ${
              isCentered ? 'items-center text-center' : 'items-start text-left'
            }`}
          >
            <h3 className="text-heading-4 font-bold text-white sm:text-heading-3">{title}</h3>
            <div className="flex w-full flex-col justify-center gap-2">
              <h5
                className={`text-base font-semibold text-white sm:text-[20px] ${
                  isCentered && 'max-w-[526px] text-center'
                }`}
              >
                {formatCheckoutMessage({
                  id: 'specialDiscount',
                  values: { discountCode: discountCode, discountPercentage: discountPercentage },
                })}
              </h5>
              <p className="text-sm font-normal text-white">
                {formatCheckoutMessage({
                  id: 'codeValidity',
                  values: { validDays: validDays },
                })}
              </p>
            </div>
            <Link href={reference.link}>
              <button className="inline-flex items-center justify-center rounded bg-primary py-[10px] px-7 text-center text-base font-semibold text-white hover:bg-opacity-90">
                {buttonText}
              </button>
            </Link>
          </div>
        </div>
      </section>
    </FullPageWidthWrapper>
  );
};

export default ThankYouPromo;
