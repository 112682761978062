import React from 'react';
import { UpsellProducts } from 'features/typesense/components';

const SingleProduct = ({ product }: { product?: string }) => {
  return (
    <UpsellProducts
      title=""
      product_results={[
        {
          type: 'product-list',
          products: [
            {
              sku: product?.split('"; ')?.[0]?.replace('SKU: "', '') ?? '',
              productId: product?.split('"; ')?.[1]?.replace('ID: "', '') ?? '',
            },
          ],
        },
      ]}
    />
  );
};

export default SingleProduct;
