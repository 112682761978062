import React from 'react';
import parse from 'html-react-parser';
import { Media } from '../CZ11Cta/CZ11Cta';
import Image from 'frontastic/lib/image';
import { strapiConfig } from 'helpers/strapiConfig';
import { ReferenceLink } from 'helpers/reference';

type CZ16FeaturesProps = {
  title: string;
  featuresList: FeaturesItem[];
};

export type FeaturesItem = {
  title: string;
  icon: Media;
  text: string;
  imageTitle?: string;
  imageAlt?: string;
  linkUrl?: string;
  targetBlank: boolean;
};

const CZ16Features = ({ title, featuresList }: CZ16FeaturesProps) => {
  return (
    <section className="container mx-auto max-w-[1280px] py-10 px-4 font-urbanist sm:px-10 lg:px-4">
      <h2 className="mb-16 max-w-[620px] text-4xl font-semibold leading-[1.2] text-[#242424]">{title}</h2>
      <div className="flex flex-wrap">
        {featuresList?.map((item, idx) => (
          <FeatureCard
            key={idx}
            title={item.title}
            iconSrc={item.icon.data.attributes.url}
            text={item.text}
            imageAlt={item.imageAlt}
            linkUrl={item.linkUrl}
            targetBlank={item.targetBlank}
            showLine={idx !== featuresList.length - 1}
          />
        ))}
      </div>
    </section>
  );
};

export default CZ16Features;

const FeatureCard = ({
  title,
  iconSrc,
  text,
  imageAlt,
  linkUrl,
  targetBlank,
  showLine = true,
}: {
  title: string;
  iconSrc: string;
  text: string;
  imageAlt?: string;
  linkUrl: string;
  targetBlank?: boolean;
  showLine: boolean;
}) => {
  return (
    <div className="w-full px-4 md:w-1/2 xl:w-1/4">
      <div className="relative mx-auto mb-10 max-w-[370px] text-center">
        {showLine && (
          <div className="absolute top-1/2 -right-20 hidden h-1 w-16 -translate-y-1/2 border-t-2 border-dashed border-primary xl:block 2xl:w-[75px]"></div>
        )}
        <div className="relative mx-auto mb-8 flex h-[80px] w-[80px] items-center justify-center rounded bg-primary text-white">
          {iconSrc && (
            <div className="h-9 w-9">
              <Image
                src={`${strapiConfig.url}${iconSrc}`}
                layout="fill"
                className="h-full w-full object-cover"
                alt={imageAlt ?? ''}
              />
            </div>
          )}
        </div>
        {linkUrl != '' ? (
          <ReferenceLink
            target={{
              link: linkUrl,
              target: linkUrl,
              type: 'link',
              openInNewWindow: targetBlank,
            }}
          >
            <h3 className="mb-2 cursor-pointer text-2xl font-semibold text-[#102B33] hover:text-primary">{title}</h3>{' '}
          </ReferenceLink>
        ) : (
          <h3 className="mb-2 text-2xl font-semibold text-[#102B33]">{title}</h3>
        )}

        <div className="text-base text-[#808080]">{parse(text)}</div>
      </div>
    </div>
  );
};
