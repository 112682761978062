import ProductList from 'components/commercetools-ui/products/product-list';
import { useAccount } from 'frontastic/provider';

function ProductListTastic({ data }) {
  const { loggedIn } = useAccount();
  if (!data?.data?.dataSource) return <></>;

  const { items, facets, category, previousCursor, nextCursor, total, totalItems } = data.data.dataSource;
  const { perPage } = data;

  return (
    <ProductList
      products={
        loggedIn
          ? items
          : items.filter(
              (item) =>
                !item.variants[0].attributes.loggedInOnly &&
                item?.categories?.filter((element) => element.loggedInOnly).length === 0,
            )
      }
      totalProducts={total}
      facets={facets}
      category={category}
      previousCursor={previousCursor}
      nextCursor={nextCursor}
    />
  );
}

export default ProductListTastic;
