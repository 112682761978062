import React, { useState, useEffect } from 'react';
import { DataTestId } from '@Types/playwright';
import ReactSlider from 'react-slider';
import Price from 'components/commercetools-ui/price';
import { useFormat } from 'helpers/hooks/useFormat';
import { Collapse } from './Collapse';

const Thumb = (props) => {
  const { className, ...rest } = props;
  return (
    <div
      className={`${className} top-0 bottom-0 h-5 w-5 cursor-grab rounded-full border border-cyan-900 bg-white leading-5 focus:border-2 focus:outline-none dark:bg-gray-700`}
      {...rest}
    ></div>
  );
};

const Track = (props) => {
  const { className, ...rest } = props;
  const additionalClass = className?.includes('track-1') ? 'bg-cyan-900' : 'bg-slate-300';
  return <div className={`${className} ${additionalClass} top-[9px] bottom-[9px] h-1 rounded-full`} {...rest}></div>;
};

type PriceRangeProps = {
  attribute: string;
  className?: string;
  start?: [number, number];
  canRefine?: boolean;
  refine: (rangeValue: [number, number]) => void;
  min: number;
  max: number;
  open?: boolean;
} & DataTestId;

export const PriceRange = (props: PriceRangeProps) => {
  const { open, attribute, min, max, canRefine, refine, start } = props;
  const [value, setValue] = useState<[number, number]>([min, max]);
  const { formatMessage } = useFormat({ name: 'product' });

  useEffect(() => {
    const first: number =
      start[0] == Number.POSITIVE_INFINITY || start[0] == Number.NEGATIVE_INFINITY || start[0] == undefined
        ? min
        : start[0];
    const second: number =
      start[1] == Number.POSITIVE_INFINITY || start[1] == Number.NEGATIVE_INFINITY || start[1] == undefined
        ? max
        : start[1];
    setValue([first, second]);
  }, [start, min, max]);

  const submitChanges = () => {
    if (canRefine) {
      refine([value[0], value[1]]);
    }
  };

  return (
    <Collapse title={formatMessage({ id: attribute, defaultMessage: attribute })} open={open}>
      <div className="relative mt-2 w-full" data-testid={props['data-testid']}>
        <div className="flex flex-row items-end justify-between">
          <div className="flex flex-col items-start justify-end gap-1">
            <div className="text-sm font-medium leading-none text-zinc-500">Min</div>
            <Price
              price={{
                centAmount: value[0],
                currencyCode: 'EUR',
                fractionDigits: 2,
              }}
              className="mb-2 inline-flex items-center justify-center gap-2.5 rounded border border-neutral-200 bg-slate-100 px-2 py-0.5"
            >
              200.00
            </Price>
          </div>
          <div className="flex flex-col items-start justify-end gap-1">
            <div className="text-sm font-medium leading-none text-zinc-500">Max</div>
            <Price
              price={{
                centAmount: value[1],
                currencyCode: 'EUR',
                fractionDigits: 2,
              }}
              className="mb-2 inline-flex items-center justify-center gap-2.5 rounded border border-neutral-200 bg-slate-100 px-2 py-0.5"
            >
              1300.00
            </Price>
          </div>
        </div>
        <ReactSlider
          className="relative my-1 mx-0 h-5 w-full"
          value={value}
          min={min}
          max={max}
          renderTrack={Track}
          renderThumb={Thumb}
          onChange={(newValue) => {
            setValue([(newValue as number[])[0], (newValue as number[])[1]]);
          }}
          onAfterChange={() => {
            submitChanges();
          }}
        />
      </div>
    </Collapse>
  );
};
