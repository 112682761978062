import React, { useEffect, useRef } from 'react';
import { DataTestId } from '@Types/playwright';

type SearchInputProps = Partial<React.ComponentProps<'input'>> & DataTestId;

export const SearchInput = (props: SearchInputProps) => {
  const { className = '', ...rest } = props;

  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    //upon mounting, we initially focus the input
    ref.current.focus();
  }, []);

  return (
    <div className={'relative mt-1 box-border flex h-10 grow bg-white text-sm leading-5 ' + className}>
      <svg
        className="absolute left-4 top-1/2 -mt-2 h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#5a5e9a"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
      </svg>
      <input
        type="text"
        name="search"
        id="search"
        className="block w-full rounded-md border-gray-300 pl-10 shadow-sm focus:border-cyan-900 focus:ring-cyan-900 sm:text-sm"
        ref={ref}
        {...rest}
      />
    </div>
  );
};
