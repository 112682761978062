import React, { useEffect, useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { Cart } from '@Types/cart/Cart';
import { Discount } from '@Types/cart/Discount';
import toast from 'react-hot-toast';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic/provider';

export interface Props {
  className?: string;
  cart: Cart;
  inCheckout?: boolean;
}

const DiscountForm: React.FC<Props> = ({ className, cart, inCheckout = false }) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const [code, setCode] = useState('');
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const { redeemDiscountCode, removeDiscountCode } = useCart();

  useEffect(() => {
    setDiscounts(cart?.discountCodes);
  }, [cart?.discountCodes]);

  const onApplyDiscount = async () => {
    try {
      const res = await redeemDiscountCode(code);
      toast.success(formatCartMessage({ id: 'codeApplied', defaultMessage: 'Code applied!' }), {
        style: {
          border: '1px solid #34D399',
          padding: '16px',
          color: '#242424',
        },
        iconTheme: {
          primary: '#34D399',
          secondary: '#FFFFFF',
        },
      });
    } catch (e) {
      toast.error(formatCartMessage({ id: 'codeNotValid', defaultMessage: 'Code is not valid' }), {
        style: {
          border: '1px solid #F87171',
          padding: '16px',
          color: '#B45454',
        },
        iconTheme: {
          primary: '#F87171',
          secondary: '#FFFFFF',
        },
      });
    }
    setCode('');
  };

  const handleRemove = (discount) => {
    removeDiscountCode(discount);
  };

  return (
    <div className={`${className}`}>
      <div className={`mb-4 rounded-md bg-white ${inCheckout ? 'px-7 py-7' : 'px-0 pb-7 lg:pl-4'}`}>
        <div className="w-full">
          <p className="text-lg font-semibold text-[#242424] transition">
            {formatCartMessage({ id: 'cart.discount', defaultMessage: 'Discounts' })}
          </p>
          <p className="mt-2 text-sm font-medium text-[#808080] transition">
            {formatCartMessage({ id: 'cart.enter.code', defaultMessage: 'Enter code to get discount instantly' })}
          </p>
        </div>
        <div className="mt-2">
          <div className="flex w-full justify-between gap-3">
            <div className="w-1/2">
              <input
                className="border-1 w-full appearance-none rounded-[4px] border-[#E7E7E7] py-3 px-4 text-left leading-tight text-[#808080] placeholder:text-[#808080] focus:border-[#808080]"
                type="text"
                value={code}
                placeholder={formatCartMessage({
                  id: 'cart.discount.code',
                  defaultMessage: 'Code',
                })}
                disabled={discounts?.length > 0}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
            <button
              type="button"
              onClick={onApplyDiscount}
              disabled={code === '' ? true : false}
              className="min-w-24 border-1 w-1/2 cursor-pointer content-center rounded-[4px] bg-[#808080] py-2 px-8 text-sm font-bold text-white hover:bg-primary hover:bg-opacity-80 hover:text-white disabled:cursor-not-allowed disabled:border-none"
            >
              {formatCartMessage({
                id: 'cart.apply',
                defaultMessage: 'Apply',
              })}
            </button>
          </div>

          <div className={`flex flex-wrap gap-3 ${discounts?.length === 0 ? 'mt-0' : 'mt-7'}`}>
            {discounts?.map((discount) => (
              <div key={discount.discountId} className="flex w-fit justify-between rounded bg-gray-400">
                <label className="px-4 py-1 text-white">{discount.code}</label>
                <button type="button" onClick={() => handleRemove(discount)} className="py-1 pr-3">
                  <XIcon className="h-6 w-5 text-white" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountForm;
