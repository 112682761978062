import React from 'react';
import { DataTestId } from '@Types/playwright';
import { useFormat } from 'helpers/hooks/useFormat';

type SingleCheckBoxProps = {
  color?: string;
  selected?: boolean;
  label?: string;
  value?: string;
  filterLabel?: string;
  onChange?: (value: string) => void;
  className?: string;
} & DataTestId;

export const SingleCheckBox = (props: SingleCheckBoxProps) => {
  const { label, value, onChange, selected, filterLabel, color, className, ...rest } = props;
  const { formatMessage } = useFormat({ name: 'product' });
  const message = filterLabel + '.' + label;

  return (
    <div
      className={'inline-flex w-full items-start justify-start gap-4 ' + className}
      onClick={() => onChange(value)}
      {...rest}
    >
      <div className="relative mt-1 h-5 w-5">
        {selected && (
          <svg
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute inset-0 z-20 m-auto h-1.75 w-2.5"
          >
            <path
              d="M9.52812 0.493598C9.42368 0.384487 9.25956 0.384487 9.15512 0.493598L3.70927 6.0115C3.66451 6.05827 3.60482 6.05827 3.56006 6.0115L0.859516 3.26814C0.755075 3.15903 0.590953 3.15903 0.486512 3.26814C0.382071 3.37725 0.382071 3.54871 0.486512 3.65782L3.18706 6.40119C3.30642 6.52589 3.47054 6.58824 3.61974 6.58824C3.78387 6.58824 3.93307 6.52589 4.05243 6.40119L9.49829 0.88328C9.61765 0.774169 9.61765 0.602709 9.52812 0.493598Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.8326 0.218909L9.84256 0.231051C10.0602 0.496322 10.0634 0.913281 9.7816 1.17982L4.34363 6.68973C4.14781 6.89302 3.89365 7 3.61974 7C3.36597 7 3.09529 6.89675 2.89568 6.68954L0.192865 3.94388C-0.0643075 3.67521 -0.0642787 3.25078 0.192894 2.98211C0.457006 2.70619 0.885613 2.70535 1.15072 2.9796L3.63494 5.50321L8.86418 0.204786C9.12931 -0.069187 9.55772 -0.0682588 9.82174 0.20757L9.8326 0.218909ZM0.859516 3.26814C0.755075 3.15903 0.590953 3.15903 0.486512 3.26814C0.382071 3.37725 0.382071 3.54871 0.486512 3.65782L3.18706 6.40119C3.30642 6.52589 3.47054 6.58824 3.61974 6.58824C3.78387 6.58824 3.93307 6.52589 4.05243 6.40119L9.49829 0.88328C9.61765 0.774169 9.61765 0.602709 9.52812 0.493598C9.42368 0.384487 9.25956 0.384487 9.15512 0.493598L3.70927 6.0115C3.66451 6.05827 3.60482 6.05827 3.56006 6.0115L0.859516 3.26814Z"
              fill="white"
            />
          </svg>
        )}
        {selected && color && <div className="absolute inset-0 z-10 bg-black opacity-25"></div>}
        <input
          checked={selected}
          type="checkbox"
          className={
            'absolute inset-0 inline-flex h-full w-full cursor-pointer items-center justify-center rounded-sm text-white focus:outline-none focus:ring-0'
          }
          style={{
            backgroundColor: color ? color : selected ? 'rgb(22 78 99)' : 'white',
            backgroundImage: color ?? 'none',
            border: '1px solid rgb(0, 0, 0, 0.1)',
          }}
          onChange={() => onChange(label)}
          onClick={() => onChange(label)}
        />
      </div>
      <div className="cursor-pointer select-none text-base font-medium text-neutral-800">
        {formatMessage({ id: message, defaultMessage: label })}
      </div>
    </div>
  );
};
