import React from 'react';

type FilterContainerProps = {
  filters?: React.ReactNode | React.ReactNode[];
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
};

export const FilterList = (props: FilterContainerProps) => {
  const { filters, className = '', children } = props;

  const filtersArray = Array.isArray(filters) ? filters : [filters];
  const childrenArray = Array.isArray(children) ? children : [children];
  const elementsArray = filtersArray.concat(childrenArray).filter((element) => element !== undefined);

  return (
    <div className={`mt-0 flex-col gap-4 pb-4 lg:mt-4 lg:pb-0 ${className}`}>
      {elementsArray.map((element, index) => (
        <div key={index} className="rounded border border-neutral-200 p-7">
          {element}
        </div>
      ))}
    </div>
  );
};
